define('services/caccounts/role',[
    'settings',
    'jquery',
    'underscore',
    'module',
    'backbone',
    'entities/ns',
    'entities/caccounts/role'
], function (
    Settings,
    $,
    _,
    module,
    Backbone,
    app
) {
    'use strict';

    module.exports = {
        getNamespace: function () {
            return {
                model: app.Role,
                collection: app.RoleCollection
            };
        },
        getName: function () {
            return 'role';
        },
        getUrl: function () {
            return 'rest/v2/roles/';
        },
        findAll: function () {
            var url = Settings.url('rest/v2/roles/'),
                defer = $.Deferred();
            $.ajax({
                method: 'GET',
                url: url,
                contentType: 'application/json',
                success: _.bind(function (json) {
                    var roles = new app.RoleCollection();
                    _.each(json, function (role) {
                        var privileges = new app.PrivilegeCollection();
                        _.each(role.privileges, function (privilege) {
                            privileges.add(new app.Privilege({code: privilege.code, name: privilege.name}));
                        });
                        roles.add(new app.Role({code: role.code, privileges: privileges}));
                    });
                    defer.resolve(roles);
                }, this),
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        },

        findDepends: function (privileges) {
            var url = Settings.url('rest/v2/roles/depends'),
                defer = $.Deferred();
            if (_.isEmpty(privileges)) {
                defer.resolve([]);
            } else {
                $.ajax({
                    method: 'POST',
                    url: url,
                    data: JSON.stringify(privileges),
                    contentType: 'application/json',
                    success: function (rolePrivileges) {
                        defer.resolve(rolePrivileges);
                    },
                    error: function (response) {
                        defer.reject(response);
                    }
                });
            }
            return defer.promise();
        }
    };
});

