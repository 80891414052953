define('runPcrContentTargetListView',[
    'module',
    'underscore',
    'jquery',
    'backbone.marionette',
    'jqGridController',
    'wellUtils',
    'colorUtils',
    'pcrWellResultController',
    'moment',
    'jqGridFormatter'
], function (
    module,
    _,
    $,
    Marionette,
    JqGridController,
    WellUtils,
    ColorUtils,
    PcrWellResultController,
    moment,
    JqGridFormatter
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="js-grid-region" style="flex-grow: 1; width: 100%; height: 100%;"></div>'),
        regions: {
            gridRegion: '.js-grid-region'
        },

        className: 'content d-f',
        attributes: {
            style: 'flex-grow: 1; width: 100%; height: 100%;'
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 20,
                currentPage: 1
            };
        },

        _getAssays: function (data) {
            var ret = _.chain(data)
                .filter(function (item) {
                    return item.assay;
                })
                .map(function (item) {
                    return item.assay + ':' + item.assay;
                })
                .uniq()
                .value();
            ret.unshift(':');
            return ret.join(';');
        },
        _getMbAna: function (data) {
            var ret = _.chain(data)
                .filter(function (item) {
                    return item.mbAna;
                })
                .map(function (item) {
                    return item.mbAna + ':' + item.mbAna;
                })
                .uniq()
                .value();
            ret.unshift(':');
            return ret.join(';');
        },
        _getResults: function (data) {
            var ret = _.chain(data)
                .filter(function (item) {
                    return item.result;
                })
                .map(function (item) {
                    return item.result + ':' + item.result;
                })
                .uniq()
                .value();
            ret.unshift(':');
            return ret.join(';');
        },
        _getErrors: function (data) {
            var ret = _.chain(data)
                .filter(function (item) {
                    return item.codeErr;
                })
                .map(function (item) {
                    return item.codeErr + ':' + item.codeErr;
                })
                .uniq()
                .value();
            ret.unshift(':');
            return ret.join(';');
        },

        gridOptions: function (data) {
            var options = {
                colModel: [{
                    label: '',
                    name: 'secId',
                    key: true,
                    hidden: true
                }, {
                    label: _.i18n('well.pos'),
                    name: 'pos',
                    classes: 'well-list-pos-label',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: true,
                    sortable: true,
                    index: 'pos',
                    searchoptions: {
                        sopt: ['cn'],
                        clearSearch: false
                    },
                    fixed: true,
                    width: 40
                }, {
                    label: _.i18n('well.smpId'),
                    name: 'smpId',
                    classes: 'well-list-label displayPopover',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: true,
                    sortable: true,
                    index: 'smpId',
                    searchoptions: {
                        sopt: ['cn']
                    },
                    fixed: true,
                    width: 180
                }, {
                    label: _.i18n('well.refAssay'),
                    name: 'assay',
                    classes: 'well-list-label',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: true,
                    sortable: true,
                    index: 'assay',
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {sopt: ['eq'], value: this._getAssays(data.data), clearSearch: false},
                    fixed: true,
                    width: 120
                }, {
                    label: _.i18n('well.target'),
                    name: 'mbAna',
                    classes: 'well-list-label',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: true,
                    sortable: true,
                    index: 'mbAna',
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {sopt: ['eq'], value: this._getMbAna(data.data), clearSearch: false},
                    fixed: true,
                    width: 120
                }, {
                    label: _.i18n('result'),
                    name: 'pos',
                    classes: 'well-list-label',
                    formatter: _.bind(this.resultFormatter, this),
                    search: false,
                    fixed: true,
                    width: 50
                }, {
                    label: _.i18n('result'),
                    name: 'result',
                    classes: 'well-list-label',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: true,
                    sortable: true,
                    index: 'result',
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {sopt: ['eq'], value: this._getResults(data.data), clearSearch: false},
                    fixed: true,
                    width: 50
                }, {
                    label: _.i18n('wellresult.num'),
                    name: 'cts',
                    classes: 'well-list-label',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: false,
                    fixed: true,
                    width: 50
                }, {
                    label: _.i18n('target.quantification'),
                    name: 'quantifications',
                    classes: 'well-list-label',
                    formatter: JqGridFormatter.defaultFormatter,
                    search: false,
                    sortable: false,
                    fixed: true,
                    width: 60
                }, {
                    label: _.i18n('well.valSt'),
                    name: 'valSt',
                    classes: 'well-list-label',
                    formatter: _.bind(this.validationFormater, this),
                    search: false,
                    sortable: true,
                    fixed: true,
                    width: 60
                }, {
                    label: _.i18n('well.resCodeErr'),
                    name: 'codeErr',
                    classes: 'well-list-label',
                    formatter: this.defaultFormatter,
                    search: true,
                    sortable: true,
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {sopt: ['eq'], value: this._getErrors(data.data), clearSearch: false},
                    fixed: true,
                    width: 60
                }, {
                    label: _.i18n('well.resComment'),
                    name: 'comment',
                    classes: 'well-list-label',
                    formatter: this.defaultFormatter,
                    search: true,
                    sortable: true,
                    fixed: true
                }
                ],
                sortname: this.sortOn ? this.sortOn : 'row'
            };
            return options;
        },

        onRender: function () {
            this.results = [];
            this.model.get('wells').each(_.bind(function (well) {
                well.getDisplay('TARGET_LIST').each(_.bind(function (result) {
                    var json = {
                        secId: result.get('secId'),
                        pos: well.get('pos'),
                        smpId: well.get('smpId') ? well.get('smpId').get('name') : '',
                        assay: well.get('refAssay') ? well.get('refAssay').get('code') : '',
                        pcrWellSecId: well.get('secId'),
                        mbAna: result.get('refAssayResult') ? result.get('refAssayResult').get('target').get('code') : '',
                        result: result.get('result') ? result.get('result').get('code') : '',
                        cts: result.get('cts'),
                        quantifications: result.get('quantificationFormatted'),
                        valSt: result.get('valSt'),
                        codeErr: result.get('codeErr'),
                        comment: result.get('comment'),
                        isOvar: result.isOvar()
                    };
                    this.results.push(json);
                }, this));
            }, this));
            var data = {
                datatype: 'local',
                data: this.results
            };

            var view = JqGridController.show({
                data: data,
                pager: true,
                filtersName: 'wellTableListFilters',
                gridOptions: _.bind(this.gridOptions, this),
                paginationObject: this.paginationObject
            }, this.getRegion('gridRegion'));
            this.listenTo(view, 'click', _.bind(this.onClick, this));
            this.listenTo(view, 'loadComplete', _.bind(this.onLoadComplete, this));
        },

        onLoadComplete: function (jqGridView, datas) {
            this.setWellListPopover(jqGridView, datas);
        },

        onClick: function (obj) {
            var results = obj.gridView.getCheckedRowsList();
            if (results.length > 0) {
                results = _.pluck(results, 'secId');
            }
            var PcrWellResultService = require('services/caccounts/pcrwellresults');
            var pcrWellResultModel = PcrWellResultService.getModel({secId: parseInt(obj.rowId, 10)});
            var wellModel = pcrWellResultModel.get('pcrWell');
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                PcrWellController.showDetails({
                    model: wellModel,
                    cSelection: results,
                    callBackOnClose: _.bind(function () {
                        obj.gridView.trigger('reloadGrid');
                    }, this)
                });
            }, this));
        },


        resultFormatter: function (cellValue, call, object) {
            var model = this.model.get('wells').findWhere({secId: object.pcrWellSecId});
            var html;
            var result = model.getDisplay('TARGET_LIST').findWhere({secId: object.secId});

            html = '<div class="row m-b-0">';
            html += '<div class="col-xs-12 p-r-0" style="display: flex;">';
            if (result) {
                html += PcrWellResultController.generateContent(result, 'TargetList');
            }
            html += '</div>';
            html += '</div>';
            return html;
        },

        setWellListPopover: function (jqGridView, datas) {
            _.each(datas, _.bind(function (data) {
                var result = _.findWhere(this.results, {secId: data.secId});
                if (!result) {
                    return;
                }
                var popovers = jqGridView.$el.find('tr#' + data.secId + ' .displayPopover');
                if (popovers.length < 1) {
                    return;
                }
                var well = this.model.get('wells').get(data.pcrWellSecId);
                if (!well) {
                    return;
                }
                var params = {
                    model: well
                };

                params.targets = popovers;

                // if ((well.get('wellRes') && well.get('wellRes').get('result') && well.get('wellRes').get('result').get('code')) &&
                //     (well.get('wellRes') && well.get('wellRes').get('quantificationFormatted'))) {
                //     params.targets.push($('.ovrerallResultPopover-' + well.get('pos')));
                // }
                var placement = 'right';
                var index = _.indexOf(datas, data);
                if (index < 3) {
                    placement = 'bottom';
                } else if (index > datas.length - 4) {
                    placement = 'top';
                }
                params.placement = placement;
                WellUtils.showPcrWellPopover(params);
            }, this));
        },

        validationFormater: function (cellValue, call, object) {
            var valSt = cellValue;
            if (!object.isOvar && valSt !== null && (valSt <= 2 || valSt === 4 || valSt === 3)) {
                var exp = '';
                if (valSt === 2) {
                    exp = 'E';
                }
                var color = '';

                if (valSt <= 2) {
                    color = '#0000FF';
                } else if (valSt === 4) {
                    color = '#00FF00';
                } else if (valSt === 3) {
                    color = '#FF0000';
                }

                return '<div class="row m-b-0">' +
                    '<div class="col-xs-1">' +
                    '<div class="well-square-icon-green">' +
                    '<div class="well-circle-icon p-t-1" style="position:absolute; background-color: ' + color + '">' +
                    '<span style="color:#FFFFFF;font-size:10px;font-weight:400;margin-left:3px;margin-top: -2px;' +
                    'position:absolute;">' + exp + '</span>' +
                    '</div>' +
                    '</div>' +
                    '</div>' +
                    '</div>';
            } else {
                return '';
            }
        }
    });
});

