// Kick off the application.
define('app',[
    'backbone.marionette',
    'settings',
    'jquery',
    'bootbox',
    'underscore',
    'backbone',
    'browserUtils',
    'module',
    'logger',
    'lodash',
    'polyglot',
    'cookies',
    'translation',
    'services/global/token',
    'flash',
    'outdatedBrowser',
    'autogrow',
    'extendBackbone',
    'extendUnderscore'
], function (
    Marionette,
    Settings,
    $,
    Bootbox,
    _,
    Backbone,
    BrowserUtils,
    module,
    Logger,
    lodash,
    Polyglot,
    Cookies,
    Translation,
    TokenService,
    Flash
) {
    'use strict';

    var MobiolinkApp = Marionette.Application.extend({
        region: '#main-region',

        checkNewVersion: function () {
            var lastcommit = Settings.get('lastcommit');
            var environment = Settings.get('environment');
            if (environment === ' ') {
                // developpment mode
                return;
            }
            $.get('lastcommit').success(_.bind(function (data) {
                if (lastcommit !== data.replace('\n', '').replace('\r', '')) {
                    Bootbox.confirm({
                        message: _.i18n('version.new'),
                        buttons: {
                            confirm: {
                                label: _.i18n('version.refreshApp')
                            },
                            cancel: {
                                label: _.i18n('version.ignoreRefresh')
                            }
                        },
                        callback: function (result) {
                            if (result) {
                                window.location.reload();
                            }
                        }
                    });
                }
            }, this));

            setTimeout(this.checkNewVersion, 1800000);
        },

        navigate: function (route, options) {
            options = options || {};

            Backbone.history.navigate(route, options);
        },

        getCurrentRoute: function () {
            return Backbone.history.fragment;
        },
        clearAuthCookieValues: function () {
            Settings.set('currentCAccount', null);
            Settings.set('currentUserModel', null);
            Settings.set('loginTo', null);
        },

        startSubModule: function (moduleName, args) {
            var currentModule = moduleName ? moduleName : null;

            if (currentModule && currentModule !== '') {
                require([moduleName], function (module) {
                    currentModule = module;
                    if (this.currentModule === currentModule) {
                        return;
                    }

                    if (this.currentModule) {
                        this.currentModule.stop();
                    }

                    this.currentModule = currentModule;
                    if (currentModule) {
                        currentModule.start(args);
                    }
                });
            }
        },

        onBeforeStart: function () {
            $(document).ajaxSend(_.bind(function (event, jqXHR) {
                jqXHR.setRequestHeader('Authorization', 'Bearer ' + this.keycloak.token);
            }, this));

            $.ajaxSetup({
                ajaxError: _.bind(function (event, request, settings) {
                    switch (request.status) {
                        case 204:
                            Bootbox.alert(_.i18n('method.noContent') + '<br/><br/>' + request.responseText);
                            break;
                        //User doesn't have the correct roles
                        case 403:
                            Settings.set('lastUrlPageCookie', null);
                            this.navigate('unauthorized');
                            break;
                        //The session has expired
                        case 401:
                            Settings.set('lastUrlPageCookie', this.getCurrentRoute());
                            this.clearAuthCookieValues();
                            this.keycloak.login();
                            break;
                        case 405:
                            var parsedUrl = new URL(settings.url);
                            var path = parsedUrl.pathname;
                            var cleanPath = path.startsWith('/') ? path.slice(1) : path;
                            Bootbox.dialog({
                                title: _.i18n('method.notAllowed'),
                                message:
                                    '<div class="mdi mdi-shield-lock-outline"></div>' +
                                    '<h2>Error-ID</h2>' +
                                    '<div class="error-id">' + settings.type + ' : ' + cleanPath + '</div>' +
                                    '<div class="information">' +
                                    '<label>Account :</label><div>' + Settings.get('currentCAccount').code + '</div>' +
                                    '<label>User :</label><div>' + Settings.get('currentUserModel').toString() + ' (login : ' + Settings.get('currentUserModel').get('logonId') + ')</div>' +
                                    '<label>Date :</label><div>' + new Date().toLocaleString() + '</div>' +
                                    '</div>',
                                className: 'not-allowed-dialog'
                            });
                            break;
                        case 500:
                            Bootbox.alert(_.i18n('internalServerError') + '<br/><br/>' + request.responseText);
                            break;
                        case 503:
                            window.location.href = Settings.serverUrl + 'server-unavailable.html';
                            break;
                        default:
                            Logger.log(arguments);
                    }
                }, this)
            });

            Settings.set('lang', Settings.get('lang') || BrowserUtils.getLanguage());
            //Manage the out of date browser
            $(document).ready(function () {
                outdatedBrowser({
                    bgColor: '#f25648',
                    color: '#ffffff',
                    lowerThan: 'transform',
                    languagePath: '../outdatedbrowser/lang/' + Settings.get('lang') + '.html'
                });
            });

            //load translations
            var RegionContainer = Marionette.View.extend({
                el: 'body',
                regions: {
                    main: '#main-region'
                }
            });
            this.regions = new RegionContainer();
        },

        onStart: function () {
            this.on('forClipboard', this.onForClipboard);
            this.on('clipboard', this.onClipboard);
            require(['load'], _.bind(function (Load) {
                var view = new Load({translate: true});
                this.regions.getRegion('main').show(view);
            }, this));
        },

        onForClipboard: function (element) {
            // attach event click to element to execute the method "onClipboard" with the value content of the element in attribute "data-copy"
            element.on('click', _.bind(function (e) {
                e.preventDefault();
                this.onClipboard($(e.target).data('copy'));
            }, this));
        },

        onClipboard: function (value) {
            if (!navigator.clipboard || !document.hasFocus()) {
                // Méthode alternative pour copier le texte (ancienne méthode)
                var $temp = $('<input>');
                $('body').append($temp);
                $temp.val(value).select();
                document.execCommand('copy');
                $temp.remove();
                Flash.success(_.i18n('copiedToClipboardFallback'));
            } else {
                // API Clipboard (modern)
                navigator.clipboard.writeText(value).then(function () {
                    Flash.success(_.i18n('copiedToClipboard'));
                }, function (err) {
                    Flash.error(_.i18n('clipboardWriteFailed'));
                    console.error('Clipboard write error: ', err);
                });
            }
        },
        downloadFile: function (url) {
            // Récupérer le token Bearer depuis le localStorage ou via un autre mécanisme (par exemple, dans un objet global)
            var token = this.keycloak.token;  // Remplacez par la méthode de récupération du token appropriée

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + token
                }
            })
                .then(function (response) {
                    var contentDisposition = response.headers.get('Content-Disposition');
                    var fileName = contentDisposition ? contentDisposition.split('filename=')[1] : 'run.pdf';

                    return response.blob().then(function (blob) {
                        return {blob: blob, fileName: fileName};
                    });
                })
                .then(function (obj) {
                    var urlBlob = URL.createObjectURL(obj.blob);

                    var link = document.createElement('a');
                    link.href = urlBlob;
                    link.download = obj.fileName || 'run.pdf';
                    link.target = '_blank';
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                });
        }
    });

    module.exports = new MobiolinkApp();
});

