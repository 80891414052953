define('services/caccounts/assayConfigurationResult',[
    'entities/ns',
    'settings',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/assayConfigurationResult'
], function (
    app,
    Settings,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.AssayConfigurationResult,
                collection: app.AssayConfigurationResultCollection
            };
        },
        getName: function () {
            return 'assayResult';
        },
        getUrl: function () {
            return 'rest/v2/assayConfigurationResults/';
        },
        getDynamicJson: function () {
            return 'AssayConfigurationResultJson';
        },
        getJqGridColumn: function () {
            return new Error('can\'t get grid column');
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': []};
        },
        getAutocompleteParam: function (config) {
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.callbackAdd = undefined;
            configuration.callbackEdit = undefined;
            if (config.paramUrl) {
                config.paramUrl.sidx = 'refMbAna.code';
            }
            configuration.urlPath = (config.url ? config.url : this.getCollectionUrl());
            configuration.values = config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'refMbAna.code'
            };
            return configuration;
        },

        getJqGridSidx: function () {
            return 'sequence';
        }
    }, Dynamic);
});

