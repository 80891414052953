
define('template!runEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="row detailed-info-form-container edit-run-popup" style="min-height: 150px;">\n    <div class="error-label">' +
((__t = ( _.i18n('common.serverError') )) == null ? '' : __t) +
'\n        <div class="error-msg"></div>\n    </div>\n    <div class="form-horizontal">\n        <div class="form-inputs-container">\n            <div class="col-xs-12">\n                <div class="col-xs-12">\n                    <div class="row edit-run-popup-header">\n                        <div class="col-w-all col-w-100 d-b p-l-15 p-r-15">\n                            <div class="col-w-all col-w-100 d-f">\n\n                                <div class="col-w-all col-w-29 "> <!--style="text-align: left;"-->\n                                    <!--mainHeaderBoxShadow-->\n                                    <!--border: solid 1px black; border-radius: 5px; background-color: inherit;-->\n                                    <div class="col-xs-12 p-l-0 p-r-0"\n                                         style="height: 74px;">\n                                        <div class="col-xs-12"\n                                             style="justify-content: end; display: flex; margin-top: 4px;">\n                                            ' +
((__t = ( wellsSizePlateIcon )) == null ? '' : __t) +
'\n                                        </div>\n                                        <div class="col-xs-12" data-field-name="refRespUser"\n                                             style="justify-content: end; display: flex; margin-top: 8px;">\n                                            <div class="col-xs-7 p-l-0 p-r-0 t-a-r dataLabelContainer">\n                                                <div class="form-group">\n                                                    <label class="control-label dataLabel noWrapHidden">' +
((__t = (
                                                        _.i18n('user') )) == null ? '' : __t) +
'</label>\n                                                </div>\n                                            </div>\n                                            <div class="col-xs-5 p-r-0 p-l-0">\n                                                <!--border: solid 1px #333;-->\n                                                <div class="js-select-respUser-region"\n                                                     style="background-color: white; position: relative;"></div>\n                                            </div>\n                                        </div>\n                                    </div>\n                                </div>\n\n                                <div class="col-w-all col-w-1"></div>\n\n                                <div class="col-w-all col-w-40 noWrapHidden" style="text-align: left;">\n                                    <div class="col-xs-12 p-l-0 p-r-0 mainHeaderBoxShadow"\n                                         style="border: solid 1px black; border-radius: 5px; background-color: inherit;">\n                                        <div class="col-xs-12 p-l-0 p-r-0">\n                                            <!-- runName Line --------------------------------------------------->\n                                            <div class="col-xs-10 p-l-5 p-r-5 noWrapHidden pop-mainHeaderRunBox">\n                                                <input class="noWrapHidden form-control floating-label js-info-input edit-run-popup-header-runName-input"\n                                                       style="border: solid 1px #333 !important;"\n                                                       type="text"\n                                                       id="runName-popupField-to-clipboard"\n                                                       data-field-name="name"\n                                                       placeholder="' +
((__t = ( _.i18n('common.empty.placeholder'))) == null ? '' : __t) +
'"\n                                                       title="' +
((__t = ( name )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'"\n                                                       data-content=""\n                                                       data-placement="top" autocomplete="off">\n                                            </div>\n                                            <div class="col-xs-2 p-l-0 p-r-0" style="display: flex; margin-top: 10px;">\n                                                <div class="buttonCircle edit-run-popup-header-action-button"\n                                                     style="margin-right: 2px;">\n                                                    <span class="hidden-print js-copyParameters mdi-content-content-copy\n                                                    headerRunActionButtonIcon edit-run-popup-header-action-copy-to-clipboard"\n                                                          data-copy="' +
((__t = ( name )) == null ? '' : __t) +
'">\n                                                    </span>\n                                                </div>\n                                                <div class="buttonCircle edit-run-popup-header-action-button">\n                                                     <span class="clickable hidden-print js-edit-runName mdi mdi-pencil headerRunActionButtonIcon\n                                                            edit-run-popup-header-action-edit-runName"\n                                                           title="' +
((__t = ( _.i18n('edit.run.name') )) == null ? '' : __t) +
'">\n                                                    </span>\n                                                </div>\n                                            </div>\n                                        </div>\n                                        <!-- run list line --------------------------------------------------->\n                                        <div class="col-xs-12 p-l-0 p-r-0" style="height: 38px;">\n                                            <div class="col-xs-10 p-l-5 p-r-5 noWrapHidden">\n                                                <div class="edit-run-popup-header-runName-list">\n                                                    ' +
((__t = ( _.map(prepRuns, function (run) {
                                                    return run.code;
                                                    }).join(', ') )) == null ? '' : __t) +
'\n                                                </div>\n                                            </div>\n                                            <div class="col-xs-2 p-l-0 p-r-5" style="height: 17px; margin-top: 8px;">\n                                                ' +
((__t = ( valStatusIcon )) == null ? '' : __t) +
'\n                                            </div>\n                                        </div>\n                                    </div>\n                                </div>\n\n                                <div class="col-w-all col-w-1"></div>\n\n                                <div class="col-w-all col-w-29 "> <!--style="text-align: left;"-->\n                                    <!--mainHeaderBoxShadow-->\n                                    <!--border: solid 1px black; border-radius: 5px; background-color: inherit;-->\n                                    <div class="col-xs-12 p-l-0 p-r-0" style="height: 74px;">\n                                        <div class="col-xs-12 t-a-l" style="margin-top: 20px;">\n                                            ' +
((__t = ( dpgs )) == null ? '' : __t) +
'\n                                        </div>\n                                        <div class="col-xs-12 t-a-l" style="margin-top: 2px;">\n                                            ' +
((__t = ( creatDateToDisplay )) == null ? '' : __t) +
'\n                                        </div>\n                                    </div>\n                                </div>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n\n                <div class="col-xs-12 ">\n                    <hr class="m-b-10 m-t-10">\n                    <div class="row col-w-all col-w-100 d-f" style="height: 18px;">\n                        <div class="col-w-all col-w-15 p-l-15 p-r-15 noWrapHidden"\n                             data-field-name="refCyclerPublic"\n                             style="text-align: left;">\n                            <div class="form-group m-b-0">\n                                <label class="edit-run-popup-header-label">' +
((__t = ( _.i18n('run.refCyclerPublic')
                                    )) == null ? '' : __t) +
'</label>\n                            </div>\n                        </div>\n                        <div class="col-w-all col-w-2"></div>\n                        <div class="col-w-all col-w-15 p-l-15 p-r-15 noWrapHidden"\n                             data-field-name="refCycler"\n                             style="text-align: left;">\n                            <div class="form-group m-b-0">\n                                <label class="edit-run-popup-header-label">' +
((__t = ( _.i18n('run.refCycler')
                                    )) == null ? '' : __t) +
'</label>\n                            </div>\n                        </div>\n                        <div class="col-w-all col-w-2"></div>\n                        <div class="col-w-all col-w-15 p-l-15 p-r-15 noWrapHidden"\n                             data-field-name="refLmbCycler"\n                             style="text-align: left;">\n                            <div class="form-group m-b-0">\n                                <label class="edit-run-popup-header-label">' +
((__t = ( _.i18n('run.refLmbCycler')
                                    )) == null ? '' : __t) +
'</label>\n                            </div>\n                        </div>\n                        <div class="col-w-all col-w-2"></div>\n                        <div class="col-w-all col-w-15 p-l-15 p-r-15 noWrapHidden"\n                             data-field-name="refKitProt"\n                             style="text-align: left;">\n                            <div class="form-group m-b-0">\n                                <label class="edit-run-popup-header-label">' +
((__t = ( _.i18n('kit.protocol') )) == null ? '' : __t) +
'</label>\n                            </div>\n                        </div>\n                        <div class="col-w-all col-w-2"></div>\n                        <div class="col-w-all col-w-40 p-l-15 p-r-15 noWrapHidden"\n                             data-field-name="plateIdPC"\n                             style="text-align: left;">\n                            <div class="form-group m-b-0">\n                                <label class="edit-run-popup-header-label">' +
((__t = ( _.i18n('run.plateIds') )) == null ? '' : __t) +
'</label>\n                            </div>\n                        </div>\n                    </div>\n                    <div class="row col-w-all col-w-100 d-f">\n                        <div class="col-w-all col-w-15"\n                             data-field-name="refCyclerPublic">\n                            ';
 if(!cyclerPublicToDisplay){;
__p += '\n                            <div class="js-select-cyclerPublic-region"></div>\n                            ';
 } else {;
__p += '\n                            <div style="font-size: 18px;">' +
((__t = ( cyclerPublicToDisplay )) == null ? '' : __t) +
'</div>\n                            ';
 } ;
__p += '\n                        </div>\n                        <div class="col-w-all col-w-2"></div>\n                        <div class="col-w-all col-w-15" data-field-name="refCycler">\n                            ';
 if(!cyclerToDisplay){;
__p += '\n                            <div class="js-select-cycler-region"></div>\n                            ';
 } else {;
__p += '\n                            <div style="font-size: 18px;">' +
((__t = ( cyclerToDisplay )) == null ? '' : __t) +
'</div>\n                            ';
 } ;
__p += '\n                        </div>\n                        <div class="col-w-all col-w-2"></div>\n                        <div class="col-w-all col-w-15" data-field-name="refLmbCycler">\n                            <div class="js-select-lmbcycler-region"></div>\n                        </div>\n                        <div class="col-w-all col-w-2"></div>\n                        <div class="col-w-all col-w-15"\n                             data-field-name="refKitProt">\n                            ';
 if(_.isEmpty(assays)){;
__p += '\n                            <div class="js-select-kitprot-region"></div>\n                            ';
 } else {;
__p += '\n                            <div style="font-size: 18px;">' +
((__t = ( kitProtToDisplay )) == null ? '' : __t) +
'</div>\n                            ';
 } ;
__p += '\n                        </div>\n                        <div class="col-w-all col-w-2"></div>\n                        <div class="col-w-all col-w-40">\n                            <input type="text" class="form-control floating-label js-info-input js-code"\n                                   data-field-name="plateIdPC"\n                                   placeholder="' +
((__t = ( _.i18n('common.empty.placeholder'))) == null ? '' : __t) +
'"\n                                   title="' +
((__t = ( plateIdPC )) == null ? '' : __t) +
'" value="' +
((__t = ( plateIdPC )) == null ? '' : __t) +
'"\n                                   data-content=""\n                                   data-placement="top" autocomplete="off">\n                        </div>\n                    </div>\n                    <hr class="m-b-15 m-t-10">\n                    <div class="row">\n                        <div class="col-w-all f-l d-f col-w-50 p-r-15">\n                            <fieldset class="roundedFieldset" style="width: 100%;">\n                                <legend class="leftPositionedLegend">' +
((__t = ( _.i18n('assays') )) == null ? '' : __t) +
'</legend>\n                                <div class="col-w-all f-l d-f col-w-100">\n                                    <div style="overflow-x: hidden; margin-left: 10px; overflow-y: auto; width: inherit;">\n                                        <div style="display: contents;">\n                                            ';
 _.each(assays, function (assay) { ;
__p += '\n                                            <div class="col-xs-12">\n                                                <div class="col-w-all f-l d-f col-w-70 js-textToCopy">' +
((__t = ( assay.code
                                                    )) == null ? '' : __t) +
'\n                                                </div>\n                                                <div class="col-w-all f-l d-f col-w-15">\n													<span class="mdi mdi-content-copy js-copyParameters clickable"\n                                                          data-copy="' +
((__t = ( assay.code )) == null ? '' : __t) +
'"\n                                                          style="font-size: 16px;"></span>\n                                                </div>\n                                            </div>\n                                            ';
 }) ;
__p += '\n                                        </div>\n                                    </div>\n                                </div>\n                            </fieldset>\n                        </div>\n                        <div class="col-w-all f-l d-f col-w-50 p-r-15">\n                            <fieldset class="roundedFieldset" style="width: 100%;">\n                                <legend class="leftPositionedLegend">' +
((__t = ( _.i18n('runs.prepruns') )) == null ? '' : __t) +
'</legend>\n                                <div class="col-w-all f-l d-f col-w-100">\n                                    <div style="overflow-x: hidden; margin-left: 10px; overflow-y: auto; width: inherit;">\n                                        <div style="display: contents;">\n                                            ';
 _.each(prepRuns, function (run) { ;
__p += '\n                                            <div class="col-xs-12">\n                                                <div class="col-w-all f-l d-f col-w-70 js-textToCopy">' +
((__t = ( run.name
                                                    )) == null ? '' : __t) +
'\n                                                </div>\n                                                <div class="col-w-all f-l d-f col-w-15">\n													<span class="mdi mdi-content-copy js-copyParameters clickable"\n                                                          data-copy="' +
((__t = ( run.name )) == null ? '' : __t) +
'"\n                                                          style="font-size: 16px;"></span>\n                                                </div>\n                                            </div>\n                                            ';
 }) ;
__p += '\n                                        </div>\n                                    </div>\n                                </div>\n                            </fieldset>\n                        </div>\n                    </div>\n                    <hr class="m-b-10 m-t-10">\n                    <div class="row">\n                        <div class="col-xs-12 commentOrDescriptionContainer" data-field-name="comment"\n                             style="margin-top:0;">\n                            <div class="col-w-all f-l d-f col-w-6">\n								<span class="mdi mdi-message-reply-text commentOrDescriptionIcon">\n								</span>\n                            </div>\n                            <div class="col-w-all f-l d-f col-w-94 commentOrDescriptionInputDiv">\n                                    <textarea class="form-control floating-label js-info-input inputBackground"\n                                              data-field-name="comment"\n                                              rows="2"\n                                              style="resize: vertical;"\n                                              placeholder="' +
((__t = ( _.i18n('common.empty.placeholder'))) == null ? '' : __t) +
'"\n                                              wrap="on">' +
((__t = ( comment )) == null ? '' : __t) +
'</textarea>\n                            </div>\n                        </div>\n                    </div>\n                </div>\n                <hr class="m-b-10 m-t-10">\n\n            </div>\n            <!-- cancel/confirm row -->\n            <div class="cancelConfirmRow">\n                <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n                <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

