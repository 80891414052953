define('entities/caccounts/prepwells',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/prepruns',
    'entities/caccounts/samples',
    'entities/caccounts/kitlotpcr',
    'entities/caccounts/pcrwells'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.PrepWell = Backbone.RelationalModel.extend({
        service: 'services/caccounts/prepwells',
        fetch: function () {
            this.url = Settings.url('rest/v2/wells/prep/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'pcrWells',
            relatedModel: 'PcrWell',
            collectionType: 'PcrWellCollection'
        }, {
            type: Backbone.HasMany,
            key: 'pcrRuns',
            relatedModel: 'PcrRun',
            collectionType: 'PcrRunCollection'
        }, {
            type: Backbone.HasMany,
            key: 'wellsPooled',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasMany,
            key: 'wellsPool',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection'
        }, {
            type: Backbone.HasOne,
            key: 'smpId',
            relatedModel: 'Sample'
        }, {
            type: Backbone.HasMany,
            key: 'referencedWells',
            relatedModel: 'PrepWell',
            collectionType: 'PrepWellCollection',
            includeInJSON: 'secId'
        }, {
            type: Backbone.HasOne,
            key: 'wellReference',
            relatedModel: 'PrepWell',
            includeInJSON: 'secId'
        }, {
            type: Backbone.HasMany,
            key: 'pcrKitLots',
            relatedModel: 'KitLotPcr',
            collectionType: 'KitLotPcrCollection'
        }, {
            type: Backbone.HasMany,
            key: 'pcrKitLotsForValidation',
            relatedModel: 'KitLotPcr',
            collectionType: 'KitLotPcrCollection'
        }, {
            type: Backbone.HasOne,
            key: 'run',
            relatedModel: 'PrepRun'
        }],

        getAssayStatusFilterIcons: function () {
            return require('wellUtils').getPrepWellAssaysFilterIcons();
        },

        getWellAssaysWaitingList: function () {
            var assays = [];
            _.each(this.get('assayStatus'), _.bind(function (item) {
                if (item.status === 'WAITING') {
                    assays.push(item.assay.code);
                }
            }, this));
            return assays;
        },
        getWellAssaysRunningList: function () {
            var assays = [];
            _.each(this.get('assayStatus'), _.bind(function (item) {
                if (item.status === 'RUNNING') {
                    assays.push(item.assay.code);
                }
            }, this));
            return assays;
        },
        getWellAssaysDoneList: function () {
            var assays = [];
            _.each(this.get('assayStatus'), _.bind(function (item) {
                if (item.status === 'DONE') {
                    assays.push(item.assay.code);
                }
            }, this));
            return assays;
        },

        getAssayStatusCount: function () {
            var assaysWaiting = 0;
            var assaysRunning = 0;
            var assaysDone = 0;

            _.each(this.get('assayStatus'), _.bind(function (item) {
                if (item.status === 'WAITING') {
                    assaysWaiting++;
                } else if (item.status === 'RUNNING') {
                    assaysRunning++;
                } else if (item.status === 'DONE') {
                    assaysDone++;
                }
            }, this));

            return {
                waiting: assaysWaiting,
                running: assaysRunning,
                done: assaysDone
            };
        },

        postUrl: function (runSecId, routingGroup) {
            var params = {
                'routingGroupSecId': routingGroup ? routingGroup.get('secId') : null
            };

            return Settings.url('rest/v2/runs/prep/' + runSecId + '/wells', params);
        },

        saveDetail: function () {
            var url = Settings.url('rest/v2/wells/prep/' + this.get('secId') + '/detail'),
                defer = $.Deferred();

            var tags = this.get('tags');
            if (tags && (!Array.isArray(tags)) && tags !== '' && tags !== undefined) {
                tags = this.get('tags').split(/[,;\s]/);
            }

            var body = {
                tags: tags
            };

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(body),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        saveHeader: function (request) {
            var url = Settings.url('rest/v2/wells/prep/' + this.get('secId') + '/header'),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'PUT',
                data: JSON.stringify(request),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.get('run').fetch()
                        .done(_.bind(function () {
                            this.fetch().done(function () {
                                defer.resolve();
                            });
                        }, this));
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },

        saveSmpid: function () {
            var url = Settings.url('rest/v2/wells/prep/' + this.get('secId') + '/sampleId'),
                defer = $.Deferred();

            var body = JSON.stringify({sampleId: this.get('smpId').get('name')});
            $.ajax({
                url: url,
                type: 'PUT',
                data: body,
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: _.bind(function (err) {
                    this.fetch().done(function () {
                        defer.reject(err);
                    });
                }, this)
            });
            return defer.promise();
        },

        save: function (routingGroup) {
            this.url = this.postUrl(this.get('run').get('secId'), routingGroup);
            return Backbone.Model.prototype.save.call(this);
        },

        canChangeSmpId: function () {
            return this.getWellAssaysRunningList().length === 0 && this.getWellAssaysDoneList().length === 0;
        },
        findDissolveds: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/prep/' + this.get('secId') + '/dissolved');

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        changeValidationLot: function (param) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/prep/' + this.get('secId') + '/test/lot');

            $.ajax({
                url: url,
                type: 'POST',
                data: JSON.stringify(param),
                contentType: 'application/json',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        findPcrKitLotForValidation: function () {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/prep/' + this.get('secId') + '/test/lot');

            $.ajax({
                url: url,
                type: 'GET',
                success: function (resp) {
                    defer.resolve(resp);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        setTest: function (test) {
            var defer = $.Deferred(),
                url = Settings.url('rest/v2/wells/prep/' + this.get('secId') + '/test', {test: test});

            $.ajax({
                url: url,
                type: 'GET',
                success: _.bind(function () {
                    this.fetch().done(function () {
                        defer.resolve();
                    });
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        },
        loadProperties: function () {
            var defer = $.Deferred();
            $.ajax({
                url: Settings.url('rest/v2/properties/findByWell/prep/' + this.get('secId')),
                type: 'GET',
                contentType: 'application/json',
                success: _.bind(function (response) {
                    defer.resolve(response);
                }, this),
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.PrepWell.role = RolesMixin.WELL_PREP;

    app.PrepWellCollection = Backbone.Collection.extend({
        fetch: function (options) {
            var defer = $.Deferred();
            if (options && options.params && options.params.method === 'POST') {
                // This is a POST request
                this.url = Settings.url('rest/v2/wells/prep/');
                $.ajax({
                    url: this.url,
                    type: 'POST',
                    data: JSON.stringify(this.map('secId')),
                    contentType: 'application/json',
                    success: _.bind(function (response) {
                        this.reset(response);
                        this.each(function (model) {
                            model.get('results').fetch(model.id);
                        });
                        defer.resolve();
                    }, this),
                    error: function (err) {
                        console.error(err);
                        defer.reject(err);
                    }
                });
                return defer.promise();
            }
            if (options && options.run) {
                this.url = Settings.url('rest/v2/wells/prep/from/' + options.run.get('secId'), options.params);
            } else if (options && options.prepRunSecId) {
                this.url = Settings.url('rest/v2/wells/prep/from/' + options.prepRunSecId, options.params);
            } else if (options && options.params) {
                this.url = Settings.url('rest/v2/wells/prep/', options.params);
            } else {
                var params = {
                    details: true
                };
                this.url = Settings.url('rest/v2/wells/prep/', params);
                $.ajax({
                    url: this.url,
                    type: 'POST',
                    contentType: 'application/json',
                    data: JSON.stringify(this.map('secId')),
                    success: _.bind(function (response) {
                        this.reset(response);
                        defer.resolve();
                    }, this),
                    error: function (err) {
                        defer.reject(err);
                    }
                });
                return defer.promise();
            }
            return Backbone.Model.prototype.fetch.call(this);
        },
        model: app.PrepWell,

        comparator: function (m1, m2) {
            if (!m1 || !m1.get('pos')) {
                return -1;
            }
            if (!m2 || !m2.get('pos')) {
                return 1;
            }

            if (m1.get('run') !== m2.get('run') && m1.get('run') instanceof app.PrepRun && m2.get('run') instanceof app.PrepRun) {
                if (m1.get('run').get('period') && m2.get('run').get('period')) {
                    return String(m1.get('run').get('period')).localeCompare(String(m2.get('run').get('period')));
                }
                if (m1.get('run').get('created') && m2.get('run').get('created')) {
                    return String(m1.get('run').get('created')).localeCompare(String(m2.get('run').get('created')));
                }
            }

            var labSetting = m1.get('run') && m1.get('run').get && m1.get('run').get('settings') ? m1.get('run').get('settings')['LAB-SETTINGS'] : 'C';
            if (labSetting === 'R') {
                return m1.get('pos').localeCompare(m2.get('pos'));
            }
            return (m1.get('pos').substring(1) + m1.get('pos').charCodeAt(0)).localeCompare(m2.get('pos').substring(1) + m2.get('pos').charCodeAt(0));
        }

    });
});

