define('dynamicCreateEditComponent',[
    'module',
    'app',
    'formView',
    'template!dynamicCreateEditComponent',
    'underscore',
    'jquery',
    'settings',
    'backbone.marionette',
    'backbone',
    'bootbox',
    'dynamicCreateEditInteger',
    'dynamicCreateEditFloat',
    'dynamicCreateEditText',
    'dynamicCreateEditTextarea',
    'dynamicCreateEditLabel',
    'dynamicCreateEditTypeParam',
    'dynamicCreateEditSelect',
    'dynamicCreateEditDate',
    'dynamicCreateEditBoolean',
    'dynamicCreateEditEnum',
    'dynamicCreateEditReference',
    'dynamicCreateEditAutocomplete',
    'dynamicCreateEditCodeList',
    'dynamicCreateEditFile',
    'dynamicCreateEditLink',
    'dynamicCreateEditPopup',
    'dynamicCreateEditButton',
    'dynamicCreateEditCustomListToString',
    'dynamicCreateEditCustomListToEntity',
    'dynamicCreateEditCustomListToList',
    'dynamicCreateEditCustomCyclerCustomIO',
    'dynamicCreateEditCustomMbAnaClassifiedValue',
    'dynamicCreateEditCustomAssayConfigurationResultFromAssay',
    'dynamicCreateEditCustomSampleList',
    'dynamicCreateEditCustomLisConfiguration',
    'dynamicCreateEditCustomColor',
    'dynamicCreateEditCustomRoutingActionState',
    'dynamicExtractionMethodExtractor',
    'dynamicCreateEditTags',
    'arrayInputView'
], function (
    module,
    App,
    FormView,
    tpl,
    _,
    $,
    Settings,
    Marionette,
    Backbone,
    bootbox,
    DynamicCreateEditInteger,
    DynamicCreateEditFloat,
    DynamicCreateEditText,
    DynamicCreateEditTextarea,
    DynamicCreateEditLabel,
    DynamicCreateEditTypeParam,
    DynamicCreateEditSelect,
    DynamicCreateEditDate,
    DynamicCreateEditBoolean,
    DynamicCreateEditEnum,
    DynamicCreateEditReference,
    DynamicCreateEditAutocomplete,
    DynamicCreateEditCodeList,
    DynamicCreateEditFile,
    DynamicCreateEditLink,
    DynamicCreateEditPopup,
    DynamicCreateEditButton,
    DynamicCreateEditCustomListToString,
    DynamicCreateEditCustomListToEntity,
    DynamicCreateEditCustomListToList,
    DynamicCreateEditCustomCyclerCustomIO,
    DynamicCreateEditCustomMbAnaClassifiedValue,
    DynamicCreateEditCustomAssayConfigurationResultFromAssay,
    DynamicCreateEditCustomSampleList,
    DynamicCreateEditCustomLisConfiguration,
    DynamicCreateEditCustomColor,
    DynamicCreateEditCustomRoutingActionState,
    DynamicExtractionMethodExtractor,
    DynamicCreateEditTags,
    ArrayListView
) {
    'use strict';

    module.exports = FormView.extend({
        template: tpl,

        regions: {
            componentParent: '.js-region-component-parent'
        },

        className: 'dynamic-region',

        attributes: function () {
            return {
                'data-field-name': this.options.fieldName
            };
        },

        serializeData: function () {
            var templateData = {};
            templateData.label = this.options.fieldName === 'codeName' ? 'common.code.name' : this.options.service.getName() + '.' + this.options.fieldName + '.name';
            templateData.dataFieldName = this.options.fieldName;
            templateData.displayClass = this.options.dynamicConfigurationField && this.options.dynamicConfigurationField.display && this.options.dynamicConfigurationField.display['class'] ? this.options.dynamicConfigurationField.display['class'] : 'col-xs-8';
            templateData.extraCount = this.options.dynamicConfigurationField && this.options.dynamicConfigurationField.extra ? (_.isArray(this.options.dynamicConfigurationField.extra) ? this.options.dynamicConfigurationField.extra.length : 1) : 0;
            return templateData;
        },

        onRender: function () {
            var region = this.addRegion('js-region-component', '.js-region-component');
            var dynamicConfigurationField = this.options.dynamicConfigurationField;
            var fieldName = this.options.fieldName;
            this.listenTo(this.model, 'change:' + this.options.fieldName, _.bind(this.render, this));
            if (dynamicConfigurationField.dependsOn) {
                this.listenTo(dynamicConfigurationField.dependsOn.model, 'change:' + dynamicConfigurationField.dependsOn.field, _.bind(this._displayRegion, this, dynamicConfigurationField, region, fieldName));
            }

            this._displayRegion(dynamicConfigurationField, region, fieldName);
            if (dynamicConfigurationField.extra) {
                var dataFieldName = this.options.fieldName;
                if (_.isArray(dynamicConfigurationField.extra)) {
                    _.each(dynamicConfigurationField.extra, _.bind(function (extra, index) {
                        var displayClass = extra.display && extra.display['class'] ? extra.display['class'] : '';
                        var region = this.addRegion('js-region-component-' + index, '.js-region-component-' + index);
                        region.$el.addClass(displayClass);
                        region.$el.attr(dataFieldName, dataFieldName);
                        this._displayRegion(extra, region, fieldName);
                    }, this));
                } else {
                    var displayClassExtra = dynamicConfigurationField.extra.display && dynamicConfigurationField.extra.display['class'] ? dynamicConfigurationField.extra.display['class'] : '';
                    var regionExtra = this.addRegion('js-region-component-0', '.js-region-component-0');
                    regionExtra.$el.addClass(displayClassExtra);
                    regionExtra.$el.attr(dataFieldName, dataFieldName);
                    this._displayRegion(dynamicConfigurationField.extra, regionExtra, fieldName);
                }
            }
        },

        _displayRegion: function (dynamicConfigurationField, region, fieldName) {
            var defer = $.Deferred();
            var view = null;
            switch (dynamicConfigurationField.type) {
                case 'INTEGER':
                    view = new DynamicCreateEditInteger({
                        'field': fieldName,
                        'value': this.model.get(fieldName),
                        'display': dynamicConfigurationField.display,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'FLOAT':
                    view = new DynamicCreateEditFloat({
                        'field': fieldName,
                        'value': this.model.get(fieldName),
                        'display': dynamicConfigurationField.display,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'LABEL':
                    view = new DynamicCreateEditLabel({
                        'field': fieldName,
                        'model': this.model,
                        'readOnly': dynamicConfigurationField.display && dynamicConfigurationField.display.readOnly || false,
                        'display': dynamicConfigurationField.display,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'STRING':
                    view = new DynamicCreateEditText({
                        'field': fieldName,
                        'model': this.model,
                        'readOnly': dynamicConfigurationField.display && dynamicConfigurationField.display.readOnly || false,
                        'display': dynamicConfigurationField.display,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'TEXTAREA':
                    view = new DynamicCreateEditTextarea({
                        'field': fieldName,
                        'model': this.model,
                        'readOnly': dynamicConfigurationField.display && dynamicConfigurationField.display.readOnly || false,
                        'display': dynamicConfigurationField.display,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'TYPEPARAM':
                    var CodeListService = require('services/admin/codelist');
                    CodeListService.checkExists(dynamicConfigurationField.codeListCode).done(_.bind(function (exists) {
                        if (!exists) {
                            bootbox.alert('CodeList "' + dynamicConfigurationField.codeListCode + '" does not exist');
                        }
                        view = new DynamicCreateEditTypeParam({
                            'fieldName': fieldName,
                            model: this.model,
                            getAutocompleteParam: CodeListService.getAutocompleteParamForCode({codeListCode: dynamicConfigurationField.codeListCode}),
                            codeListCode: dynamicConfigurationField.codeListCode,
                            'display': dynamicConfigurationField.display,
                            service: this.options.service
                        });
                        defer.resolve(view);
                    }, this));
                    break;
                case 'SELECT':
                    view = new DynamicCreateEditSelect({
                        'field': fieldName,
                        'value': this.model.get(fieldName),
                        'values': this.options.service.getValues(fieldName),
                        'display': dynamicConfigurationField.display,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'TAGS':
                    view = new DynamicCreateEditTags({
                        'field': fieldName,
                        'model': this.model,
                        'display': dynamicConfigurationField.display,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'ENUM':
                    view = new DynamicCreateEditEnum({
                        'field': fieldName,
                        'value': this.model.get(fieldName),
                        'values': dynamicConfigurationField.values,
                        'width': dynamicConfigurationField.width ? dynamicConfigurationField.width : '150px',
                        'display': dynamicConfigurationField.display,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'REFERENCE':
                    view = new DynamicCreateEditReference({
                        'field': fieldName,
                        'model': this.model,
                        'getAutocompleteParam': dynamicConfigurationField.config ? dynamicConfigurationField.config.getAutocompleteParam : {},
                        'context': dynamicConfigurationField.service,
                        'display': dynamicConfigurationField.display,
                        service: dynamicConfigurationField.service
                    });
                    defer.resolve(view);
                    break;
                case 'AUTOCOMPLETE_SYNC':
                    dynamicConfigurationField.config.getAutocompleteParam(undefined, undefined, this.model).done(_.bind(function (config) {
                        view = new DynamicCreateEditAutocomplete({
                            'field': fieldName,
                            'model': this.model,
                            'getAutocompleteParam': config,
                            'display': dynamicConfigurationField.display
                        });
                        defer.resolve(view);
                    }, this));
                    break;
                case 'BOOLEAN':
                    view = new DynamicCreateEditBoolean({
                        'field': fieldName,
                        'value': this.model.get(fieldName),
                        'display': dynamicConfigurationField.display,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'DATE':
                    view = new DynamicCreateEditDate({
                        'field': fieldName,
                        model: this.model,
                        'value': this.model.get(fieldName),
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'ARRAYLIST':
                    if (dynamicConfigurationField.config) {
                        view = dynamicConfigurationField.config(this.options.service);
                    } else {
                        view = new ArrayListView({
                            model: this.model,
                            field: new Backbone.Model({field: fieldName, param: dynamicConfigurationField}),
                            readOnly: dynamicConfigurationField ? dynamicConfigurationField.readOnly : false,
                            disableRender: true,
                            service: this.options.service
                        });
                    }
                    defer.resolve(view);
                    break;
                case 'CODELIST':
                    require('services/admin/codelist').checkExists(dynamicConfigurationField.code).done(_.bind(function (exists) {
                        if (!exists) {
                            bootbox.alert('CodeList ' + dynamicConfigurationField.code + ' does not exist');
                        }
                        view = new DynamicCreateEditCodeList({
                            'fieldName': dynamicConfigurationField.field,
                            'model': this.model,
                            config: {
                                'modelProperty': dynamicConfigurationField.field,
                                'code': dynamicConfigurationField.code,
                                showAll: dynamicConfigurationField.showAll
                            },
                            'display': dynamicConfigurationField.display,
                            service: this.options.service
                        });
                        defer.resolve(view);
                    }, this));
                    break;
                case 'EXTRACTIONMETHOD_EXTRACTOR':
                    view = new DynamicExtractionMethodExtractor({
                        'model': this.model,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'LIST2STRING':
                    view = new DynamicCreateEditCustomListToString({
                        'field': fieldName,
                        'values': this.model.get(fieldName),
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'LIST2ENTITY':
                    if (!(this.model.get(fieldName) instanceof Backbone.Collection)) {
                        alert('Error: the field ' + fieldName + ' is not a collection');
                        view = null;
                    } else {
                        var collection = this.model.get(fieldName);
                        if (dynamicConfigurationField && dynamicConfigurationField.convert) {
                            collection = dynamicConfigurationField.convert(collection);
                        }
                        view = new DynamicCreateEditCustomListToEntity({
                            'field': fieldName,
                            'collection': collection,
                            service: this.options.service
                        });
                    }
                    defer.resolve(view);
                    break;
                case 'LISTTOLIST':
                    view = new DynamicCreateEditCustomListToList({
                        'field': fieldName,
                        'model': this.model,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'BUTTON':
                    view = new DynamicCreateEditButton({
                        'field': fieldName,
                        'model': this.model,
                        service: this.options.service,
                        'events': dynamicConfigurationField.events,
                        'display': dynamicConfigurationField.display
                    });
                    defer.resolve(view);
                    break;
                case 'CYCLERCUSTOMIO':
                    view = new DynamicCreateEditCustomCyclerCustomIO({
                        'field': fieldName,
                        'fieldCustom': dynamicConfigurationField.field,
                        'model': this.model,
                        'url': dynamicConfigurationField.url,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'ASSAYCONFIGURATIONRESULT_FROMASSAY':
                    view = new DynamicCreateEditCustomAssayConfigurationResultFromAssay({
                        'field': fieldName,
                        'fieldCustom': dynamicConfigurationField.field,
                        'model': this.model,
                        'url': dynamicConfigurationField.url,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'SAMPLELIST':
                    view = new DynamicCreateEditCustomSampleList({
                        'field': fieldName,
                        'fieldCustom': dynamicConfigurationField.field,
                        'model': this.model,
                        'url': dynamicConfigurationField.url,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'LISCONFIGURATION':
                    view = new DynamicCreateEditCustomLisConfiguration({
                        'field': fieldName,
                        'fieldCustom': dynamicConfigurationField.field,
                        'model': this.model,
                        'url': dynamicConfigurationField.url,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'COLOR':
                    view = new DynamicCreateEditCustomColor({
                        'field': fieldName,
                        'model': this.model,
                        'display': dynamicConfigurationField.display,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'ROUTINGACTION_STATE':
                    view = new DynamicCreateEditCustomRoutingActionState({
                        'field': fieldName,
                        'fieldCustom': dynamicConfigurationField.field,
                        'model': this.model.get(fieldName),
                        'url': dynamicConfigurationField.url,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'FILE':
                    view = new DynamicCreateEditFile({
                        'field': fieldName,
                        'model': this.model,
                        'display': dynamicConfigurationField.display,
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'LINK':
                    view = new DynamicCreateEditLink({
                        'field': fieldName,
                        'model': this.model.get(fieldName),
                        'display': dynamicConfigurationField.display,
                        'entity': this.model.getRelation(fieldName),
                        service: this.options.service
                    });
                    defer.resolve(view);
                    break;
                case 'POPUP':
                    view = new DynamicCreateEditPopup({
                        'field': fieldName,
                        'model': this.model,
                        'display': dynamicConfigurationField.display,
                        service: this.options.service,
                        events: dynamicConfigurationField.events
                    });
                    defer.resolve(view);
                    break;
            }
            defer.promise().done(_.bind(function () {
                if (dynamicConfigurationField && dynamicConfigurationField.field && dynamicConfigurationField.field.param && dynamicConfigurationField.field.param.onChange) {
                    view.onChange = _.bind(function (p1, p2) {
                        dynamicConfigurationField.field.param.onChange(p1, p2, this.model);
                    }, this);
                }
                region.show(view);
            }, this));
        }
    });
});

