
define('template!dynamicCreateEditCustomLisConfiguration', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<div class="col-w-30 p-l-0 p-r-2" data-field-name="action">\n	<select data-field-name="action" class="js-select-action form-control js-info-input" >\n		<option value=""></option>\n		';
 _.each(actions, function(action) { ;
__p += '\n		<option value="' +
((__t = ( action )) == null ? '' : __t) +
'"\n		' +
((__t = ( model.action === action ? 'selected': '' )) == null ? '' : __t) +
'>' +
((__t = ( action )) == null ? '' : __t) +
'</option>\n		';
 }) ;
__p += '\n	</select>\n</div>\n<div class="col-w-30 p-l-0 p-r-2 js-select-dataConnection-region"></div>';

}
return __p
};});

