define('load',[
    'module',
    'backbone.marionette',
    'template!load',
    'underscore',
    'translation',
    'services/global/token',
    'settings',
    'backbone',
    'app',
    'jquery'
], function (
    module,
    Marionette,
    Tpl,
    _,
    Translation,
    TokenService,
    Settings,
    Backbone,
    App,
    $
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,

        ui: {
            translation: '.progress-bar-translation',
            settings: '.progress-bar-setting',
            progressTranslation: '.progress-bar-translation .progress-bar',
            progressSettings: '.progress-bar-setting .progress-bar',
            progressTranslationText: '.progress-bar-translation h3',
            progressSettingsText: '.progress-bar-setting h3',
            progressSettingsDetails: '.progress-bar-setting .details'
        },

        className: 'application-load',

        serializeData: function () {
            var templateData = {};
            templateData.folder = '';
            if (Settings.get('folder')) {
                templateData.folder = Settings.get('folder');
                if (!templateData.folder.endsWith('/')) {
                    templateData.folder += '/';
                }
            }
            return templateData;
        },

        onRender: function () {
            if (this.options.translate) {
                this.ui.translation.show();
                setTimeout(_.bind(this.loadTranslate, this), 10);
            } else if (this.options.services) {
                this.ui.settings.show();
                setTimeout(_.bind(this.loadService, this), 10);
            }
        },

        loadTranslate: function () {
            this.ui.settings.show();
            this.ui.progressTranslation.attr('aria-valuenow', 0);
            this.ui.progressTranslation.css('width', '0');
            Translation.fetchTranslations().done(_.bind(function () {
                this.ui.progressTranslation.attr('aria-valuenow', 100);
                this.ui.progressTranslation.css('width', '100%');
                this.ui.progressTranslation.text('100%');
                TokenService.findBy().done(_.bind(function (model) {
                    require(['router', 'loadServices'], _.bind(function (router, loadServices) {
                        setTimeout(_.bind(function () {
                            var services = loadServices.getServices();
                            // remove all service doesn't contain the function "findAll"
                            services = _.filter(services, function (service) {
                                return service.findAll;
                            });
                            // remove all service have the name with "run" or "well" or "attachment"
                            services = _.filter(services, function (service) {
                                try {
                                    var name = service.getName();
                                    if (name.toLowerCase().match(/file|connectorhl7tracing|caccount|platesize|lisrecord|run|well/) ||
                                        name.toLowerCase().match(/attachment|order|sample$/) ||
                                        name.toLowerCase().match(/arrayelement|codelistelement|assayreagent|assayreagenttargetpcrkitlot/) ||
                                        name.toLowerCase().match(/displaymodeconfiguration|extractionreagent|kitprotconfiguration|pcrreagent|dynamic|textslave/) ||
                                        name.toLowerCase().match(/routingactionroutinggroup|routingactionstate|notification/) ||
                                        name.toLowerCase().match(/dataconnectionall/) ||
                                        name.toLowerCase().match(/assayresultversion|colorcompensationmatrice|curve|extractionmethodreagent|tracs/)) {
                                        return false;
                                    }
                                } catch (e) {
                                    var extra;
                                    if (service.getNamespace) {
                                        extra = service.getNamespace().model.prototype.service;
                                    }
                                    console.log('Error name for ' + extra);
                                    return false;
                                }
                                try {
                                    service.getUrl();
                                } catch (e) {
                                    console.log('Error URL for ' + service.getName());
                                    return false;
                                }
                                try {
                                    service.getNamespace();
                                } catch (e) {
                                    console.log('Error namespace for ' + service.getName());
                                    return false;
                                }
                                try {
                                    var namespace = service.getNamespace();
                                    if (!namespace) {
                                        throw new Error('namespace is not defined for ' + service.getName());
                                    }
                                    if (!namespace.model) {
                                        throw new Error('model is not defined for ' + service.getName());
                                    }
                                    if (!namespace.collection) {
                                        throw new Error('collection is not defined for ' + service.getName());
                                    }
                                } catch (e) {
                                    console.log(e);
                                    return false;
                                }

                                if (!service.findAll) {
                                    throw new Error('findAll is not defined for ' + service.getName());
                                }
                                return true;
                            });

                            console.log('Services loaded : ' + services.length);
                            console.log('Services : ' + _.map(services, function (service) {
                                return service.getName();
                            }));
                            _.each(services, function (service) {
                                var prototype = service.getNamespace().model.prototype.save;
                                service.getNamespace().model.prototype.save = function (attributes, options) {
                                    var defer = $.Deferred();
                                    prototype.call(this, attributes, options).done(function () {
                                        service.invalidateCache().done(function () {
                                            defer.resolve();
                                        });
                                    })
                                        .fail(function () {
                                            defer.reject();
                                        });
                                    return defer.promise();
                                };
                            });

                            App.services = services;

                            Settings.set('currentCAccount', model.caccount);
                            Settings.set('currentUserModel', require('services/caccounts/userpids').getModel(model.userpid));
                            Settings.set('loginTo', model.loginTo);
                            if (!model.caccount) {
                                this.navigate('select/caccount', {trigger: true});
                            } else {
                                this.loadService();
                            }
                        }, this), 1000);
                    }, this));
                }, this));
            }, this));
        },

        loadService: function () {
            if (!this.originalWarn) {
                this.originalWarn = console.warn;
            }

            // Redéfinir console.warn pour intercepter les messages
            console.warn = _.bind(function (args) {
                this.originalWarn(args);
                // if args contains "WARNING : Missing translation" then push model
                if (args.startsWith('WARNING: Missing translation for key:')) {
                    var key = args.replaceAll('WARNING: Missing translation for key:', '').replaceAll('"', '').substring(4).trim();
                    var user = Settings.get('currentUserModel');
                    if (user && user.get('superUser')) {
                        user.get('notifications').push({
                            id: 'missing_translation#' + key,
                            context: 'translation',
                            message: args,
                            action: function (jqGrid) {
                                require(['translationsTableController'], _.bind(function (Controller) {
                                    Controller.getLanguages().done(_.bind(function (languages) {
                                        var values = languages
                                            .chain()
                                            .filter(function (language) {
                                                return language.get('checked');
                                            })
                                            .map(function (language) {
                                                return {locale: language.get('abbreviation'), value: ''};
                                            }).value();
                                        var service = require('services/global/text');
                                        var model = service.getModel({code: key, values: values});
                                        Controller.showDetails({
                                            model: model, languages:
                                            languages,
                                            callBackOnClose: function () {
                                                user.get('notifications').remove(user.get('notifications').get('missing_translation#' + key));
                                                jqGrid.reloadGrid();
                                            }
                                        });
                                    }, this));
                                }, this));
                            }
                        });
                    }
                }
            }, this);
            this.ui.progressSettings.attr('aria-valuenow', 0);
            this.ui.progressSettings.css('width', '0');
            // clear backbone store
            _.each(Backbone.Relational.store._collections, function (collection) {
                collection.reset();
            });
            var services = App.services;
            // call all findAll function in services and define a progress bar
            var progress = 0;
            var step = 100 / (services.length - 1);
            var i = 0;
            this.ui.progressSettingsDetails.append('Loading data...<br/>');
            _.each(services, _.bind(function (service) {
                service.findAll()
                    .done(_.bind(function (items) {
                        console.log('Cache loaded for ' + service.getName() + ' with ' + items.length + ' items');
                        this.ui.progressSettingsDetails.append(service.getName() + ' : loaded (' + items.length + ')<br/>');
                    }, this))
                    .fail(_.bind(function () {
                        this.ui.progressSettingsDetails.append('<span style="color: red;">' + service.getName() + ' : ERROR<br/></span>');
                    }, this))
                    .always(_.bind(function () {
                        progress = step * i++;
                        this.ui.progressSettings.attr('aria-valuenow', progress);
                        this.ui.progressSettings.css('width', progress + '%');
                        this.ui.progressSettings.text(Math.floor(progress) + '%');
                        if (i === services.length) {
                            this.ui.progressSettingsDetails.append('Data loaded');
                            this.ui.progressSettingsDetails.scrollTop(this.ui.progressSettingsDetails[0].scrollHeight);
                            this.navigate();
                        } else {
                            // Scroll automatique vers le bas
                            this.ui.progressSettingsDetails.scrollTop(this.ui.progressSettingsDetails[0].scrollHeight);
                        }
                    }, this));
            }, this));
        },

        navigate: function () {
            if (!Backbone.History.started) {
                Backbone.history.start();
            } else {
                App.regions.getRegion('main').empty();
                App.navigate('', {trigger: true});
            }
        }
    });
});
