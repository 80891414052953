define('runPcrValidationView',[
    'module',
    'backbone.marionette',
    'backbone',
    'underscore',
    'template!runPcrValidationView',
    'runPcrValidationPlateView',
    'runPcrValidationMainView',
    'emptyView'
], function (
    module,
    Marionette,
    Backbone,
    _,
    Tpl,
    ValidationPlateView,
    ValidationMainView,
    EmptyView
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: Tpl,
        className: 'validation',
        regions: {
            plate: '.plate-region',
            main: '.main-region'
        },
        initialize: function () {
            this.model = new Backbone.Model({run: this.options.model, resultsByAssay: new Backbone.Collection()});

            this.model.get('run').get('wells').chain()
                .filter(function (well) {
                    return well.get('assayVersion');
                })
                .map(function (well) {
                    return well.get('assayVersion');
                })
                .filter(function (assayVersion) {
                    return !assayVersion.getDisplay('VALIDATION').isEmpty();
                })
                .map(function (assayVersion) {
                    return new Backbone.Model({
                        assayVersion: assayVersion,
                        results: new Backbone.Collection(assayVersion.getDisplay('VALIDATION').map(function (result) {
                            return result.get('assayConfigurationResult');
                        }))
                    });
                })
                .uniq(function(assayResult) {
                    return assayResult.get('assayVersion').id;
                })
                .each(_.bind(function (assayResult) {
                    this.model.get('resultsByAssay').push(assayResult);
                }, this));
            this.model.set('assayResultVersion', this.model.get('resultsByAssay').first().get('results').first());
            this.model.set('wellSelected', require('services/caccounts/pcrwells').getCollection(this.model.get('run').get('wells')
                .chain()
                .filter(_.bind(function (well) {
                    return well.getDisplay('VALIDATION').some(_.bind(function (result) {
                        return result.get('refAssayResult') === this.model.get('assayResultVersion');
                    }, this));
                }, this))
                .value()));
            this.model.get('wellSelected').run = this.model.get('run');

            this.model.findResult = _.bind(function () {
                return require('services/caccounts/pcrwellresults').getCollection(this.model.get('wellSelected').map(_.bind(function (well) {
                    return well.findResult(this.model.get('assayResultVersion'));
                }, this)));
            }, this);
        },
        serializeData: function () {
            return {};
        },
        onRender: function () {
            this.showChildView('plate', new ValidationPlateView({model: this.model}));
            this.showChildView('main', new EmptyView());
            this.showChildView('main', new ValidationMainView({model: this.model}));
        }
    });
});
