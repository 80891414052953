define('jqGridController',[
    'module',
    'underscore',
    'jqgridView'
], function (
    module,
    _,
    JqGridView) {
    'use strict';

    module.exports = {
        show: function (options, region) {
            var view = new JqGridView(options);
            view.loadData(null, options)
                .done(function (data) {
                    view.data.data = data;
                    region.show(view);
                });
            return view;
        },
        showPopup: function (options, showOptions) {
            var view = new JqGridView(options);
            view.loadData(null, options)
                .done(function (data) {
                    view.data.data = data;
                    view.show(showOptions);
                });
            return view;
        }
    };
});
