define('entities/global/text',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/global/textSlave'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.Text = Backbone.RelationalModel.extend({
        service: 'services/global/text',
        fetch: function () {
            this.url = Settings.url('rest/public/texts/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'app': 'dashboard',
            'values': []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'values',
            relatedModel: 'TextSlave',
            collectionType: 'TextSlaveCollection'
        }],

        postUrl: function () {
            return Settings.url('rest/public/texts/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'TextJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Text',

        importable: true
    });
    
    app.TextCollection = Backbone.Collection.extend({
        model: app.Text
    });

    app.Text.role = RolesMixin.SUPER_USER;
});

