/**
 * Created by Matteo on 19/07/2015.
 */
define('router',[
    'require',
    'module',
    'marionetteAppRouter',
    'underscore',
    'app',
    'thankYouMainRouter',
    'selectCaccountMainRouter',
    'superAdminMainRouter',
    'pcrSetupRouter',
    'wellRouter',
    'caccountsMainRouter',
    'routingRouter',
    'runsMainRouter',
    'prepRunsMainRouter',
    'adminMainRouter'
], function (
    require,
    module,
    AppRouter,
    _,
    App
) {
    'use strict';

    var Controller = {
        showUnauthorized: function (message) {
            require(['unauthorizedShowController'], function (ShowController) {
                ShowController.showUnauthorized(message);
            });
        },

        showIndex: function () {
            require(
                ['contentShowController', 'indexController'],
                function (ContentShowController, IndexController) {
                    ContentShowController.showContent().done(function () {
                        IndexController.show();
                    }).fail(function () {
                        alert('Error loading the content');
                        throw new Error('Error loading the content');
                    });
                }
            );
        },
        showSwitchCAccount: function () {
            require(['load'], _.bind(function (Load) {
                App.regions.getRegion('main').empty();
                var view = new Load({services: true});
                App.regions.getRegion('main').show(view);
            }, this));
        }
    };

    var Router = AppRouter.extend({
        appRoutes: {
            '': 'showIndex',
            'switchCAccount': 'showSwitchCAccount',
            'unauthorized': 'showUnauthorized'
        },
        controller: Controller
    });

    module.exports = new Router();
});

