define('runPcrContentWellListView',[
    'module',
    'underscore',
    'jquery',
    'backbone.marionette',
    'jqGridController',
    'wellUtils',
    'colorUtils',
    'pcrWellResultController',
    'jqGridFormatter'
], function (
    module,
    _,
    $,
    Marionette,
    JqGridController,
    WellUtils,
    ColorUtils,
    PcrWellResultController,
    jqGridFormatter
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="js-grid-region" style="flex-grow: 1; width: 100%; height: 100%;"></div>'),
        regions: {
            gridRegion: '.js-grid-region'
        },

        className: 'content d-f',
        attributes: {
            style: 'flex-grow: 1; width: 100%; height: 100%;'
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 20,
                currentPage: 1
            };
        },

        onRender: function () {
            var data = this.getListViewData(this.model.get('wells'));

            var view = JqGridController.show({
                data: data,
                pager: true,
                filtersName: 'wellTableListFilters',
                gridOptions: _.bind(this.gridOptions, this),
                paginationObject: this.paginationObject
            }, this.getRegion('gridRegion'));
            this.listenTo(view, 'click', _.bind(this.onClick, this));
            this.listenTo(view, 'loadComplete', _.bind(this.onLoadComplete, this));
        },

        onLoadComplete: function (jqGridView, datas) {
            this.setWellListPopover(jqGridView, datas);
        },

        onClick: function (obj) {
            var results = obj.gridView.getCheckedRowsList();
            if (results.length > 0) {
                results = _.pluck(results, 'secId');
            }
            var PcrWellService = require('services/caccounts/pcrwells');
            var wellModel = PcrWellService.getModel({secId: parseInt(obj.rowId, 10)});
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                PcrWellController.showDetails({
                    model: wellModel,
                    cSelection: results,
                    callBackOnClose: _.bind(function () {
                        obj.gridView.trigger('reloadGrid');
                    }, this)
                });
            }, this));
        },

        _getAssays: function (data) {
            var ret = _.chain(data)
                .filter(function (item) {
                    return item.refAssay;
                })
                .map(function (item) {
                    return item.refAssay.code + ':' + item.refAssay.code;
                })
                .uniq()
                .value();
            ret.unshift(':');
            return ret.join(';');
        },

        _getSmpType: function (data) {
            var ret = _.chain(data)
                .filter(function (item) {
                    return item.smpType;
                })
                .map(function (item) {
                    return item.smpType + ':' + item.smpType;
                })
                .uniq()
                .value();
            ret.unshift(':');
            return ret.join(';');
        },

        gridOptions: function (data) {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: _.i18n('well.sample.comment'),
                        name: 'wellComment',
                        formatter: _.bind(this.wellCommentFormatter, this),
                        search: true,
                        sortable: true,
                        index: 'wellComment',
                        searchoptions: {
                            clearSearch: false
                        },
                        fixed: true,
                        width: 150
                    }, {
                        label: jqGridFormatter.getWarningLabelIcon(),
                        name: 'codeErr',
                        formatter: _.bind(this.warningFormater, this),
                        search: true,
                        sortable: true,
                        index: 'codeErr',
                        stype: 'select',
                        searchrules: {select: true},
                        searchoptions: {sopt: ['eq'], value: this.warningSearch, clearSearch: false},
                        fixed: true,
                        width: 30
                    }, {
                        label: jqGridFormatter.getRepeatLabelIcon(),
                        name: 'repeatStatus',
                        formatter: _.bind(this.repeatStatusFormater, this),
                        search: true,
                        sortable: true,
                        index: 'repeatStatus',
                        stype: 'select',
                        searchrules: {select: true},
                        searchoptions: {sopt: ['eq'], value: this.availableRepeatStatus, clearSearch: false},
                        fixed: true,
                        width: 30
                    }, {
                        label: jqGridFormatter.getSameAssayLabelIcon(),
                        name: 'existOtherWellSameAssay',
                        formatter: _.bind(this.sameOtherAssayFormater, this),
                        search: true,
                        sortable: true,
                        index: 'existOtherWellSameAssay',
                        stype: 'select',
                        searchrules: {select: true},
                        searchoptions: {sopt: ['eq'], value: ':;true:X', clearSearch: false},
                        fixed: true,
                        width: 40
                    }, {
                        label: jqGridFormatter.getOtherAssayLabelIcon(),
                        name: 'existOtherWellOtherAssay',
                        formatter: _.bind(this.sameOtherAssayFormater, this),
                        search: true,
                        sortable: true,
                        index: 'existOtherWellOtherAssay',
                        stype: 'select',
                        searchrules: {select: true},
                        searchoptions: {sopt: ['eq'], value: ':;true:X', clearSearch: false},
                        fixed: true,
                        width: 40
                    }, {
                        label: jqGridFormatter.getSmpTypeLabelIcon(),
                        name: 'smpType',
                        formatter: _.bind(this.smptypeFormater, this),
                        search: true,
                        sortable: true,
                        index: 'smpType',
                        stype: 'select',
                        searchrules: {select: true},
                        searchoptions: {sopt: ['eq'], value: this._getSmpType(data.data), clearSearch: false},
                        fixed: true,
                        width: 30
                    }, {
                        label: _.i18n('well.pos'),
                        name: 'pos',
                        formatter: jqGridFormatter.wellPosFormatter,
                        classes: 'css-grid-cell-accentuatuion-03',
                        sortable: true,
                        title: false,
                        index: 'pos',
                        fixed: true,
                        width: 50,
                        search: true,
                        searchoptions: {
                            sopt: ['cn'],
                            clearSearch: false
                        }
                    }, {
                        label: _.i18n('well.smpId'),
                        name: 'smpId.code',
                        formatter: _.bind(this.wellSmpidFormatter, this),
                        classes: 'css-grid-cell-accentuatuion-02 displayPopover',
                        search: true,
                        sortable: true,
                        title: false,
                        index: 'smpId.code',
                        searchoptions: {
                            sopt: ['cn'],
                            clearSearch: false
                        },
                        fixed: true,
                        width: 130
                    }, {
                        label: _.i18n('common.empty.placeholder'),
                        name: 'smpId.code',
                        sortable: false,
                        search: false,
                        classes: 'ignore-row-click css-no-leftBorder',
                        formatter: jqGridFormatter.copyToClipboardFormatter,
                        fixed: true,
                        width: 30
                    }, {
                        label: _.i18n('well.refAssay'),
                        name: 'refAssay.code',
                        formatter: jqGridFormatter.wellAssayFormatter,
                        classes: 'css-run-listView-accentuation',
                        search: true,
                        sortable: true,
                        title: false,
                        index: 'refAssay.code',
                        stype: 'select',
                        searchrules: {select: true},
                        searchoptions: {sopt: ['eq'], value: this._getAssays(data.data), clearSearch: false},
                        fixed: true,
                        width: 150
                    }, {
                        label: _.i18n('well.result'),
                        name: 'wellResSearchValue',
                        classes: 'well-list-label-wellRes',
                        formatter: _.bind(this.wellResultFormatter, this),
                        search: true,
                        sortable: true,
                        title: false,
                        searchoptions: {sopt: ['cn'], clearSearch: false},
                        index: 'wellResSearchValue',
                        fixed: true,
                        width: 110
                    }
                ],
                sortname: this.sortOn ? this.sortOn : 'row'
            };

            var max = this.model.get('assayVersions').chain().max(function (assayVersion) {
                return assayVersion.getDisplay('WELL_LIST').length;
            }).value().getDisplay('WELL_LIST').length;

            var width = this.model.get('refKitProt').get('displayMode').findParameterForLocation('WellList').getConfig('WellList').width;
            for (var i = 0; i < max; i++) {
                options.colModel.push({
                    label: i % 10 === 0 ? '0' : i % 10,
                    name: 'target_' + i,
                    formatter: _.bind(this.resultFormatter, this),
                    search: false,
                    sortable: false,
                    sequence: i,
                    fixed: true,
                    width: width
                });
            }
            return options;
        },

        wellCommentFormatter: function (cellValue, options, object) {
            var comment = object.smpId ? (object.smpId.lisComment ? object.smpId.lisComment : '') +
                ' ' + (object.smpId.comment ? object.smpId.comment : '') : '';
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '">' + comment + '</span>';
        },

        warningFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12" style="padding-left: 17px;">';
            if (cellValue === 'warning') {
                start += '<span class="iconify" data-icon="fluent:warning-24-filled" ' +
                    'style="font-size: 17px; position: absolute; top: -7px; color: #ff5200;"></span>';
            } else if (cellValue === 'expert') { //object.valWst === 3.1
                start += '<span class="mdi-social-school" ' +
                    'style="font-size: 17px; position: absolute; top: -7px; color: #0014FF;"></span>';
            }
            start += '</div>';
            end = '</div>';
            return start + end;
        },

        repeatStatusFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12" style="right: 5px;">';
            if (cellValue === 'PT') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="controlSpan" style="position: absolute; left: 25px; top: -7px; color: white; font-size: 11px;">c</span>';
            } else if (cellValue === 'XT') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px; left: 21px;"></span>';
                start += '<span class="p-l-0 p-r-0" style="font-size:11px; position: absolute;' +
                    'left: 40px; bottom: -8px;">' +
                    '</span>';
            } else if (cellValue === 'XC') {
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px;"></span>';
                start += '<span class="iconify" data-icon="dashicons-controls-play" ' +
                    'data-rotate="180deg" style="font-size: 22px; position: absolute; top: -10px; left: 21px;"></span>';
                start += '<span class="controlSpan" style="position: absolute; left: 28px; top: -7px; color: white; font-size: 11px;">c</span>';
                start += '<span class="p-l-0 p-r-0" style="font-size:11px; position: absolute;' +
                    'left: 40px; bottom: -8px;">' +
                    '</span>';
            }
            start += '</div>';
            end = '</div>';
            return start + end;
        },

        sameOtherAssayFormater: function (cellValue, call, object) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12 p-l-15" style="display: flex;">';
            if (object.existOtherWellOtherAssay) {
                start += '<span class="triangle-other-Assay-wellView" ' +
                    'style="' +
                    'border-left: 6px solid transparent; border-right: 6px solid transparent; border-top: 15px solid ' +
                    this.model.otherAssayColor + '; ' +
                    '"></span>';
            }
            if (object.existOtherWellSameAssay) {
                start += '<span class="triangle-same-Assay-wellView" ' +
                    'style="' +
                    'border-left: 6px solid transparent; border-right: 6px solid transparent; border-bottom: 15px solid ' +
                    this.model.sameAssayColor + '; ' +
                    '"></span>';
            }
            start += '</div>';
            end = '</div>';
            return start + end;
        },

        smptypeFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12 p-l-15">';

            if (cellValue === 'NC') {
                start += '<span class="mdi-content-remove-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="mdi-content-add-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'OC') {
                start += '<span class="mdi mdi-compass" style="font-size: 17px;"></span>';
            } else if (cellValue === 'D') {
                start += '<span class="mdi mdi-alpha-d-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'C') {
                start += '<span class="iconify" data-icon="ant-design:copyright-circle-filled" style="font-size: 17px;"></span>';
            } else if (cellValue === 'RC') {
                start += '<span class="iconify" data-icon="bxs:registered" style="font-size: 17px;"></span>';
            } else if (cellValue === 'P') {
                start += '<span class="iconify" data-icon="mdi:google-circles-communities" style="font-size: 17px;"></span>';
            }

            start += '</div>';
            end = '</div>';
            return start + end;
        },

        wellSmpidFormatter: function (cellValue, options) {
            if (cellValue === null || cellValue === undefined) {
                cellValue = '';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="font-weight: 600;">' + cellValue + '</span>';
        },

        wellResultFormatter: function (cellValue, call, object) {
            var model = this.model.get('wells').findWhere({secId: object.secId});
            var html;
            var result = model.getDisplay('OVAR').first();

            html = '<div class="row m-b-0">';
            html += '<div class="col-xs-12 p-r-0" style="display: flex;">';
            if (result) {
                html += PcrWellResultController.generateContent(result, 'WellList');
            }
            html += '</div>';
            html += '</div>';
            return html;
        },

        resultFormatter: function (cellValue, call, object) {
            var model = this.model.get('wells').findWhere({secId: object.secId});
            var html;
            var result = model.getDisplay('WELL_LIST').at(call.colModel.sequence);
            html = '';
            if (result) {
                html += PcrWellResultController.generateContent(result, 'WellList');
            }
            return html;
        },

        getListViewData: function (filteredWells) {
            var wellListViewData = this.getWellListViewData(filteredWells);
            this.availableRepeatStatus = wellListViewData.repeatStatusList;
            this.smpTypeList = wellListViewData.smpTypeList;
            this.warningSearch = wellListViewData.warningSearch;
            var data = {
                datatype: 'local',
                data: wellListViewData.wellsForData
            };
            return data;
        },
        getWellListViewData: function (wells) {
            var availableRepeatStatus = [];
            var availableSmpTypes = [];
            var wellsForData = [];
            var warningFound = {empty: 0, warning: 0, expert: 0};

            wells
                .chain()
                .filter(function (well) {
                    return well.get('smpType') !== 'E' && well.get('smpId') && well.get('smpId').get('code') && well.get('refAssay');
                })
                .each(function (well) {
                    var counter = 0;
                    if (well.get('repeatStatus')) {
                        availableRepeatStatus.push(well.get('repeatStatus'));
                    }
                    if (well.get('smpType')) {
                        availableSmpTypes.push(well.get('smpType'));
                    }
                    var model = {
                        secId: well.get('secId'),
                        wellRes: null,
                        pos: well.get('pos'),
                        codeErr: well.get('codeErr'),
                        repeatStatus: well.get('repeatStatus'),
                        existOtherWellSameAssay: well.get('existOtherWellSameAssay'),
                        existOtherWellOtherAssay: well.get('existOtherWellOtherAssay'),
                        smpType: well.get('smpType')
                    };
                    model.wellRes = well.getDisplay('OVAR').first();

                    model.wellResSearchValue = model.wellRes;

                    if (well.get('smpId')) {
                        model.smpId = {
                            secId: well.get('smpId').get('secId'),
                            name: well.get('smpId').get('name'),
                            code: well.get('smpId').get('code'),
                            lisComment: well.get('smpId').get('lisComment'),
                            comment: well.get('smpId').get('comment')
                        };
                    }
                    if (well.get('refAssay')) {
                        model.refAssay = {
                            secId: well.get('refAssay').get('secId'),
                            code: well.get('refAssay').get('code'),
                            name: well.get('refAssay').get('name')
                        };
                    }

                    var wellResCode = model.wellRes && model.wellRes.get('result') ? model.wellRes.get('result').get('code') : '';

                    var wellResQuantification = model.wellRes && model.wellRes.get('quantificationFormatted') ?
                        model.wellRes.get('quantificationFormatted') : '';

                    model.wellResSearchValue = wellResCode + wellResQuantification;

                    well.getDisplay('WELL_LIST').each(function (target) {
                        if (target.get('codeErr')) {
                            model.codeErr = 'warning';
                            warningFound.warning++;
                            counter++;
                        }
                    });

                    if (well.get('codeErr') !== 'warning' && well.get('valWst') === 3.1) {
                        model.codeErr = 'expert';
                        warningFound.expert++;
                        counter++;
                    }

                    if (counter === 0) {
                        warningFound.empty++;
                        model.codeErr = 'empty';
                    }

                    wellsForData.push(model);
                });

            availableSmpTypes = _.uniq(availableSmpTypes);
            availableRepeatStatus = _.uniq(availableRepeatStatus);

            return {
                repeatStatusList: this.getGridSearchField(availableRepeatStatus),
                smpTypeList: this.getGridSearchField(availableSmpTypes),
                warningSearch: this.getGridWarningSearchField(warningFound),
                wellsForData: wellsForData
            };
        },
        getGridSearchField: function (list) {
            var retVal = ':' + _.i18n('common.any') + ';'; //': ;';
            _.each(list, function (l) {
                retVal += l + ':' + l + ';';
            });
            retVal = retVal.substring(0, retVal.length - 1);
            return retVal;
        },

        getGridWarningSearchField: function (object) {
            var retVal = ':' + _.i18n('common.any') + ';'; //': ;';
            if (object.empty > 0) {
                retVal += 'empty:' + _.i18n('empty') + ';';
            }
            if (object.expert > 0) {
                retVal += 'expert:' + _.i18n('expert') + ';';
            }
            if (object.warning > 0) {
                retVal += 'warning:' + _.i18n('warning') + ';';
            }
            retVal = retVal.substring(0, retVal.length - 1);
            return retVal;
        },

        setWellListPopover: function (jqGridView, datas) {
            _.each(datas, _.bind(function (data) {
                var well = this.model.get('wells').get(data.secId);
                if (!well) {
                    return;
                }
                var popovers = jqGridView.$el.find('tr#' + data.secId + ' .displayPopover');
                if (popovers.length < 1) {
                    return;
                }

                var params = {
                    model: well
                };

                params.targets = popovers;

                // if ((well.get('wellRes') && well.get('wellRes').get('result') && well.get('wellRes').get('result').get('code')) &&
                //     (well.get('wellRes') && well.get('wellRes').get('quantificationFormatted'))) {
                //     params.targets.push($('.ovrerallResultPopover-' + well.get('pos')));
                // }
                var placement = 'right';
                var index = _.indexOf(datas, data);
                if (index < 3) {
                    placement = 'bottom';
                } else if (index > datas.length - 4) {
                    placement = 'top';
                }
                params.placement = placement;
                WellUtils.showPcrWellPopover(params);
            }, this));
        }
    });
});

