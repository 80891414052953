define('adminTrashController',[
    'module',
    'underscore',
    'app',
    'adminTrashView'
], function (
    module,
    _,
    App,
    View
) {
    'use strict';

    module.exports = {
        showTrashList: function () {
            App.getView().getRegion('content').show(new View());
        }
    };
});
