define('services/global/dataConnectionAll',[
    'entities/ns',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/global/dataConnectionAll'
], function (
    app,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.DataConnectionAll,
                collection: app.DataConnectionAllCollection
            };
        },
        getName: function () {
            return 'dataconnectionall';
        },
        getUrl: function () {
            return 'rest/global/dataConnections/';
        },
        getDynamicJson: function () {
            return 'DataConnectionAllJson';
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('common.id'),
                name: 'caccountId',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'caccountId',
                searchoptions: {size: 10},
                width: 10
            }, {
                label: _.i18n('caccount'),
                name: 'caccountCode',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'caccountCode',
                searchoptions: {size: 10},
                width: 20
            }, {
                label: _.i18n('common.code'),
                name: 'code',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: true,
                index: 'code',
                searchoptions: {size: 10},
                width: 40
            }, {
                label: _.i18n('dataConnection.configuration'),
                name: 'configuration',
                formatter: viewDynamic.typeParamFormatter,
                sortable: false,
                search: true,
                index: 'configuration'
            });
            return columns;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['name']};
        },
        getCreateEditFieldCustom: function () {
            var custom = Dynamic.getCreateEditFieldCustom.call(this);
            custom.push(
                {'field': 'configuration', 'param': {'type': 'TYPEPARAM', codeListCode: 'DC-CONFIGURATION'}}
            );
            return custom;
        }
    }, Dynamic);
});

