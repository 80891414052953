define('entities/caccounts/lisConfiguration',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/dataConnection'
], function (
    app,
    Backbone,
    Settings,
    $,
    RolesMixin
) {
    'use strict';

    app.LisConfiguration = Backbone.RelationalModel.extend({
        service: 'services/caccounts/lisConfiguration',
        defaults: {
            'action': '',
            'default': false,
            'dataConnection': null
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasOne,
            key: 'dataConnection',
            relatedModel: app.DataConnection,
            reverseRelation: {
                key: 'dataConnection',
                includeInJSON: 'secId'
            }
        }],

        jsonObjectName: 'LISConfigurationJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'LisConfiguration',
        findTypeByAction: function (action) {
            var url = Settings.url('rest/v2/lisConfiguration/typeByAction', {action: action}),
                defer = $.Deferred();

            $.ajax({
                url: url,
                type: 'GET',
                contentType: 'application/json',
                success: function (response) {
                    defer.resolve(response);
                },
                error: function (err) {
                    defer.reject(err);
                }
            });
            return defer.promise();
        }
    });

    app.LisConfiguration.role = RolesMixin.LIS;

    app.LisConfigurationCollection = Backbone.Collection.extend({
        model: app.LisConfiguration
    });

});

