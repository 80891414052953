define('services/caccounts/platesize',[
    'settings',
    'underscore',
    'module',
    'dynamic'
], function (
    Settings,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getUrl: function () {
            return 'rest/v2/cyclers/public/plateSizes/';
        },
        
        getNamespace: function () {
            return {
                model: null,
                collection: null
            };
        },
        
        getName: function () {
            return 'plateSize';
        },

        getAutocompleteParam: function (config) {
            config.canAddOrEdit = false;
            var configuration = Dynamic.getAutocompleteParam(config, this);
            configuration.urlPath = config.url ? config.url : this.getCollectionUrl();
            configuration.values = config.paramUrl ? config.paramUrl : {
                'sord': 'asc',
                'sidx': 'plateSize'
            };
            configuration.entity = null;
            configuration.service = null;
            configuration.canAddOrEdit = false;
            return configuration;
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['code']};
        }
    }, Dynamic);
});

