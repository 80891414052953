define('entities/caccounts/printers',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'underscore',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    $,
    _,
    RolesMixin
) {
    'use strict';

    app.Printer = Backbone.RelationalModel.extend({
        service: 'services/caccounts/printers',
        fetch: function () {
            this.url = Settings.url('rest/v2/printers/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': '',
            'name': '',
            'nameService': '',
            'description': ''
        },
        initialize: function () {
            this.set('tags', []);
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/v2/printers/');
        },

        save: function () {
            this.url = this.postUrl();
            if (this.get('nameService') instanceof Backbone.Model) {
                this.set({'nameService': this.get('nameService').get('code')}, {silent: true});
            }
            if (_.isObject(this.get('nameService'))) {
                this.set({'nameService': this.get('nameService').code}, {silent: true});
            }
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'PrinterJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Printer',

        importable: true,
        print: function (label) {
            var url = Settings.url('rest/v2/printers/' + this.get('secId') + '/print'),
                defer = $.Deferred();
            $.ajax({
                method: 'POST',
                url: url,
                contentType: 'application/json',
                data: label,
                success: function () {
                    defer.resolve();
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    });
    
    app.PrinterCollection = Backbone.Collection.extend({
        model: app.Printer
    });

    app.Printer.role = RolesMixin.PRINTER;
});

