define('jqgridView',[
    'module',
    'jquery',
    'dialogFormView',
    'underscore',
    'app',
    'settings',
    'bootbox',
    'customBootboxMessage',
    'itemPagesNameMixin',
    'jqGridFormatter',
    'fileExplorerController',
    'jqgridMbl'
], function (
    module,
    $,
    DialogFormView,
    _,
    App,
    Settings,
    Bootbox,
    CustomBootboxMessage,
    ItemPagesNameMixin,
    JqGridFormatter,
    FileExplorerController
) {
    'use strict';

    module.exports = DialogFormView.extend(_.extend({
        getTemplate: function () {
            var template = '<table class="jq-grid-list" id="<%= jqGridId %>" role="grid"></table>';
            if (this.options.pager) {
                template += '<div id="<%= jqGridPagerId %>" class="jq-grid-pager"></div>';
            }
            return _.template(template);
        },

        ui: function () {
            return {
                jqGrid: '#' + this.jqGridId.jqGrid,
                jqGridPager: '#' + this.jqGridId.jqGridPager
            };
        },

        attributes: {
            style: 'flex: 1; height: 100%;'
        },

        constructor: function () {
            this.jqGridId = {
                jqGrid: _.uniqueId('jq-grid-list'),
                jqGridPager: _.uniqueId('jq-grid-pager-list')
            };
            DialogFormView.prototype.constructor.apply(this, arguments);
        },

        serializeData: function () {
            return {
                jqGridId: this.jqGridId.jqGrid,
                jqGridPagerId: this.jqGridId.jqGridPager
            };
        },

        initialize: function (options) {
            this.isSuperAdmin = options && options.isSuperAdmin;
            if (this.onInitialize) {
                this.onInitialize();
            }
            this.data = _.clone(options.data);
            this.listenTo(this, 'reloadGrid', this.reloadGrid);
        },

        getGrid: function () {
            return this.ui.jqGrid;
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: this.data && this.data.rows ? this.data.rows : Settings.get('perPage'),
                currentPage: 1
            };
        },

        // Appliquer les filtres sauvegardés
        applySavedFilters: function () {
            var savedFilters = Settings.getFromMemory(this.findFilterName()) || {};
            if (!_.isEmpty(savedFilters) && savedFilters.filters) {
                this.ui.jqGrid.jqGrid('setGridParam', {postData: savedFilters}).trigger('reloadGrid');
                this.synchronizeToolbar(savedFilters.filters);
            }
        },

        synchronizeToolbar: function (filters) {
            if (!filters) {
                return;
            }
            // synchronize the toolbar with the filters
            var parsedFilters = JSON.parse(filters);
            parsedFilters.rules.forEach(_.bind(function (rule) {
                this.$el.find('#gs_' + rule.field).val(rule.data);
            }, this));
        },
        onRender: function () {
            this.pagination = this.options.paginationObject ? _.extend({}, this.options.paginationObject()) : _.extend({}, this.paginationObject());
            this.displayGrid();
        },

        findFilterName: function () {
            return typeof this.options.filtersName === 'function' ? this.options.filtersName() : this.options.filtersName;
        },

        gridInitOptions: function (data) {
            this.sidx = this.sidx || '';
            this.sord = this.sord || 'asc';
            var postData = Settings.getFromMemory(this.findFilterName()) || {};
            this.sidx = postData.sidx || this.sidx;
            this.sord = postData.sord || this.sord;
            this.pagination.currentPage = postData.page || 1;

            var rowList = [10, 20, 30, 40, 50];
            if (this.pagination.rows) {
                rowList.push(this.pagination.rows);
                rowList = _.sortBy(rowList, function (num) {
                    return num;
                });
            }

            var opt = data && data.data ? {datatype: 'local', data: data.data} : {datatype: 'json', url: data.url, mtype: data.mtype ? data.mtype : 'POST'};

            return _.extend({
                regional: Settings.get('lang'),
                colModel: [],
                viewrecords: true,
                height: '100%',
                autowidth: true,
                responsive: true,
                multiselect: this.options.selectable,
                rowNum: this.pagination.rows,
                rowList: rowList,
                page: this.pagination.currentPage,
                multiboxonly: true,
                multiselectWidth: 20,
                jsonReader: {
                    page: function (obj) {
                        return obj.length === 0 ? 1 : undefined;
                    },
                    total: _.bind(function () {
                        return Math.ceil(data.rowCount / this.pagination.rows);
                    }, this),
                    records: function () {
                        return data.rowCount;
                    }
                },
                sortname: this.sidx,
                sortorder: this.sord,
                postData: postData || {rows: this.pagination.rows, first: this.pagination.first},
                pager: this.ui.jqGridPager,
                isSuperAdmin: this.isSuperAdmin,
                search: true,
                gridComplete: _.bind(this.gridComplete, this),
                loadComplete: _.bind(this.loadComplete, this),
                beforeProcessing: _.bind(function (data) {
                    if (data.rows) {
                        data = data.rows;
                    }
                    if (this.options.service) {
                        var model = this.options.service.getNamespace().model;
                        data.forEach(function (item) {
                            var m = model.findOrCreate(item);
                            var json = m.toJSON();
                            _.keys(json).forEach(function (key) {
                                item[key] = json[key];
                            });
                        });
                    }
                }, this),
                beforeSelectRow: function (rowid, e) {
                    return !!$(e.target).is(':checkbox');
                },
                onSortCol: _.bind(this.onSortCol, this),
                onCellSelect: _.bind(this.onCellSelect, this)
            }, opt);
        },

        gridComplete: function () {
            this.attachPopover();
            this.trigger('gridComplete', this);
        },

        loadComplete: function (data) {
            if (data.rows) {
                data = data.rows;
            }
            var currentFilters = this.ui.jqGrid.jqGrid('getGridParam', 'postData');
            this.setFilter(currentFilters);
            this.trigger('loadComplete', this, data);
            this.data = data;
        },

        onCellSelect: function (rowId, iCol, cellContent, e) {
            if ($(e.target).is(':checkbox')) {
                return;
            }
            if (e.target.attributes['data-navigate'] && e.type === 'click') {
                FileExplorerController.show(rowId);
                return;
            }
            var $cellContent = $(cellContent);
            if ($cellContent.hasClass('cell-copyForClipboard')) {
                App.trigger('clipboard', $cellContent.attr('data-copy-value'));
                return;
            }
            var colModel = this.ui.jqGrid.jqGrid('getGridParam', 'colModel');
            if (colModel[iCol].onClick) {
                colModel[iCol].onClick(rowId, iCol, cellContent, e, this);
            } else {
                this.trigger('click', {gridView: this, rowId: rowId, colModel: colModel[iCol], cellContent: cellContent, event: e});
            }
        },

        onSortCol: function (sidx, columnIndex, sord) {
            var colModel = this.ui.jqGrid.jqGrid('getGridParam', 'colModel');
            var col = colModel[columnIndex];
            if (col.displayGroup && col.displayGroup.name) {
                // get first column
                var firstColumn = colModel[columnIndex + 1];
                // check if show or hide
                var columns, i;
                if (firstColumn.hidden) {
                    // show column from firstColumn to lastColumn
                    columns = [];
                    for (i = columnIndex + 1; i <= columnIndex + col.displayGroup.size; i++) {
                        columns.push(colModel[i].name);
                    }
                    this.ui.jqGrid.jqGrid('showCol', columns);
                } else {
                    // hide column from firstColumn to lastColumn
                    columns = [];
                    for (i = columnIndex + 1; i <= columnIndex + col.displayGroup.size; i++) {
                        columns.push(colModel[i].name);
                    }
                    this.ui.jqGrid.jqGrid('hideCol', columns);
                }
                return 'stop';
            } else {
                // keep doing the sort by prototype                        
                this.sidx = sidx;
                this.sord = sord;
                this.reloadGrid();
            }
        },

        findFilter: function () {
            var storedFilters = Settings.getFromMemory(this.findFilterName());
            if (!storedFilters) {
                storedFilters = {};
                Settings.setToMemory(this.findFilterName(), storedFilters);
            }
            return storedFilters;
        },

        displayGrid: function () {
            if (this.data.url) {
                var urlParams = {
                    rowCountUrl: this.data.rowCountUrl,
                    filters: this.findFilter()
                };

                this.$el.find('.jq-grid-table-page').append('<div class="alert-info-grid">' + _.i18n('jqgrid.emptyrecords') + '</div>');

                var CommonService = require('services/common');
                CommonService.getRowCount2(urlParams)
                    .done(_.bind(function (rowCount) {
                        this.gridOptionsObj = this.gridOptions(this.data);
                        this.displayData(rowCount);
                        this.$el.find('.alert-info-grid').hide();
                        this.triggerMethod('checkRowCount', rowCount);
                    }, this))
                    .fail(function (response) {
                        Bootbox.alert({title: response.statusText, message: response.responseText});
                    });
            } else {
                setTimeout(_.bind(function () {
                    this.gridOptionsObj = this.gridOptions(this.data);
                    this.displayData(1);
                }, this), 1);
            }
        },

        gridOptions: function (data) {
            var options = this.options.gridOptions(data);
            options = _.defaults(options, this.gridInitOptions(data));
            if ($(this.el).parents('.modal-dialog.ui-draggable').length > 0) {
                options.autowidth = false;
            }
            options.menubar = true;
            // disabled clear search for all columns if not specified
            _.each(options.colModel, function (colModel) {
                if (colModel.search && (!colModel.searchoptions || (colModel.searchoptions && !colModel.searchoptions.clearSearch))) {
                    if (!colModel.searchoptions) {
                        colModel.searchoptions = {};
                    }
                    colModel.searchoptions.clearSearch = false;
                }
            });
            return options;
        },

        displayData: function (rowCount) {
            this.pagination.rowCount = rowCount;
            this.colModel = this.gridOptionsObj.colModel;
            if (this.ui.jqGrid) {
                this.ui.jqGrid.jqGrid(this.gridOptionsObj);
            }
            if (this.options.extend) {
                _.each(this.options.extend, _.bind(function (ext) {
                    _.keys(ext).forEach(_.bind(function (key) {
                        if (this.ui.jqGrid) {
                            this.ui.jqGrid.jqGrid(key, ext[key]());
                        }
                    }, this));
                }, this));
            }

            var activeToolbar = _.any(this.colModel, function (colModel) {
                return colModel.search;
            });
            if (this.ui.jqGrid && activeToolbar) {
                this.ui.jqGrid.jqGrid('filterToolbar', {
                    searchOnEnter: false,
                    stringResult: false,
                    searchOperators: false,
                    beforeClear: this.gridOptionsObj.beforeClear,
                    afterClear: this.gridOptionsObj.afterClear,
                    defaultSearch: 'cn',
                    clearSearch: false
                });
                // Appliquer les filtres sauvegardés après l'affichage de la grille
                this.applySavedFilters();
            }
            if (this.ui.jqGrid && this.options.pager) {
                // We need to have a navigation bar in order to add custom buttons to it
                this.ui.jqGrid.navGrid('#' + this.ui.jqGridPager[0].id,
                    {
                        edit: false, add: false, del: false, search: false, refresh: true, view: false, position: 'left', cloneToTop: false,
                        afterRefresh: _.bind(this._afterRefresh, this)
                    });
            }
        },

        _afterRefresh: function () {
            this.reloadGrid();
        },

        setFilter: function (filters) {
            Settings.setToMemory(this.findFilterName(), filters);
        },

        attachPopover: function () {
            this.$el.find('[data-content]').popover({
                trigger: 'hover',
                placement: 'bottom',
                html: 'true',
                container: 'body'
            });
        },

        getCheckedRowsList: function () {
            var selectedRowId = this.ui.jqGrid.jqGrid('getGridParam', 'selarrrow');
            var results = [];
            if (_.isArray(selectedRowId)) {
                _.each(selectedRowId, _.bind(function (rowId) {
                    var currentCheckedRowData = this.ui.jqGrid.getRowData(rowId);
                    results.push(currentCheckedRowData);
                }, this));
            } else {
                var currentCheckedRowData = this.ui.jqGrid.getRowData(selectedRowId);
                results.push(currentCheckedRowData);
            }
            return results;
        },

        reloadGrid: function (newData) {
            var grid = this.ui.jqGrid;

            // Récupérer les filtres existants
            var gridParam = grid.jqGrid('getGridParam');
            var postData = gridParam.postData;
            gridParam.search = true;

            this.loadData(newData, this.options).done(_.bind(function (data) {
                if (data) {
                    grid.jqGrid('clearGridData');
                    grid.jqGrid('setGridParam', {data: data});
                }

                // Réappliquer les filtres existants
                grid.jqGrid('setGridParam', {postData: postData}, true);

                // Recharger la grille
                grid.trigger('reloadGrid', [{current: true}]);
                this.synchronizeToolbar(postData.filters);
            }, this));
        },
        loadData: function (newData, options) {
            var defer = $.Deferred();
            if (newData) {
                defer.resolve(newData);
            } else {
                if (_.isFunction(options.data.data)) {
                    var objectReturn = _.bind(options.data.data, options.data.context)();
                    // Si l'objet retourné est un Deferred
                    if (objectReturn && objectReturn.done) {
                        objectReturn.done(function (data) {
                            defer.resolve(data.toJSON());
                        });
                    } else {
                        defer.resolve(objectReturn.toJSON());
                    }
                } else if (options.data.data) {
                    defer.resolve(options.data.data);
                } else {
                    defer.resolve();
                }
            }
            return defer.promise();
        }
    }, JqGridFormatter));
});
