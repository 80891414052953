/**
 * Created by OBL on 7/01/2016.
 */
define('rolesMixin',['module'], function (module) {
    'use strict';
    module.exports = {
        ACCOUNT: 'ACCOUNT',
        ADMIN: 'ADMIN',
        ASSAY: 'ASSAY',
        ASSAYGROUP: 'ASSAYGROUP',
        ATTACHMENT: 'ATTACHMENT',
        AUTHORITYGROUP: 'AUTHORITYGROUP',
        AUTHORITYGROUPROLE: 'AUTHORITYGROUPROLE',
        BIOGROUP: 'BIOGROUP',
        COLORCOMPENSATION: 'COLORCOMPENSATION',
        CONNECTOR_HL7: 'CONNECTOR_HL7',
        CYCLER: 'CYCLER',
        DISPLAYMODE: 'DISPLAYMODE',
        DATACONNECTION: 'DATACONNECTION',
        EXTRACTIONMETHOD: 'EXTRACTIONMETHOD',
        EXTRACTOR: 'EXTRACTOR',
        FILEFORMAT: 'FILEFORMAT',
        FILE_EXPLORER: 'FILE_EXPLORER',
        KIT_EXTRACTION: 'KIT_EXTRACTION',
        KIT_EXTRACTION_LOT: 'KIT_EXTRACTION_LOT',
        KIT_PCR: 'KIT_PCR',
        KIT_PCR_LOT: 'KIT_PCR_LOT',
        LANGUAGE: 'LANGUAGE',
        LIS: 'LIS',
        LISANA: 'LISANA',
        LISBIOGROUP: 'LISBIOGROUP',
        LISSPECIE: 'LISSPECIE',
        LMBCYCLER: 'LMBCYCLER',
        LMBEXTRACTOR: 'LMBEXTRACTOR',
        LMBSAMPLEHANDLER: 'LMBSAMPLEHANDLER',
        MBANA: 'MBANA',
        MBANARES: 'MBANARES',
        MBANARESGR: 'MBANARESGR',
        ORDERS: 'ORDERS',
        PCR_PROTOCOL: 'PCR_PROTOCOL',
        PCR_SETUP: 'PCR_SETUP',
        PLATELAYOUTTEMPLATE: 'PLATELAYOUTTEMPLATE',
        PLATESIZE: 'PLATESIZE',
        PREPROCESS: 'PREPROCESS',
        PRINTER: 'PRINTER',
        REPORT: 'REPORT',
        ROUTING: 'ROUTING',
        ROUTINGACTION: 'ROUTINGACTION',
        ROUTINGGROUP: 'ROUTINGGROUP',
        RUN_PCR: 'RUN_PCR',
        RUN_PCR_TEMPLATE: 'RUN_PCR_TEMPLATE',
        RUN_PREP: 'RUN_PREP',
        RUN_PREP_TEMPLATE: 'RUN_PREP_TEMPLATE',
        SAMPLECLASS: 'SAMPLECLASS',
        SAMPLEHANDLER: 'SAMPLEHANDLER',
        SAMPLES: 'SAMPLES',
        SPO_OVAR: 'SPO_OVAR',
        SETTING: 'SETTING',
        SPECIE: 'SPECIE',
        STANDARDCURVE: 'STANDARDCURVE',
        SUPER_USER: 'SUPER_USER',
        SUPPLIER: 'SUPPLIER',
        TRASH: 'TRASH',
        USER: 'USER',
        USERCODELIST: 'USERCODELIST',
        USERGROUP: 'USERGROUP',
        WELL_PCR: 'WELL_PCR',
        WELL_PREP: 'WELL_PREP'
    };
});

