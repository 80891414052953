define('entities/caccounts/reports',[
    'entities/ns',
    'backbone',
    'settings',
    'jquery',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/reportProperties'
], function (
    app,
    Backbone,
    Settings,
    $,
    RolesMixin
) {
    'use strict';

    app.Report = Backbone.RelationalModel.extend({
        service: 'services/caccounts/reports',
        fetch: function () {
            this.url = Settings.url('rest/v2/reports/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'code': undefined,
            'name': undefined,
            'type': undefined,
            'fileName': undefined,
            'description': undefined,
            'properties': []
        },
        initialize: function () {
            this.set('tags', []);
            this.set('properties', []);
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'properties',
            relatedModel: 'ReportProperty',
            collectionType: 'ReportPropertyCollection',
            reverseRelation: {
                key: 'report'
            }
        }],

        postUrl: function () {
            return Settings.url('rest/v2/reports/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },

        jsonObjectName: 'ReportJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'Report',
        importable: false,
        creatable: false,
        print: function (label) {
            var url = Settings.url('rest/v2/reports/' + this.get('secId') + '/print'),
                defer = $.Deferred();
            $.ajax({
                method: 'POST',
                url: url,
                contentType: 'application/json',
                data: label,
                success: function () {
                    defer.resolve();
                },
                error: function (response) {
                    defer.reject(response);
                }
            });
            return defer.promise();
        }
    });
    
    app.ReportCollection = Backbone.Collection.extend({
        model: app.Report
    });
    
    app.Report.role = RolesMixin.REPORT;
});

