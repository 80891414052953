define('cacheAjaxPromise',[
    'module',
    'expireCache',
    'jquery'
], function (
    module,
    ExpireCache,
    $
) {
    'use strict';

    module.exports = {
        ajax: function (cacheNamespace, cacheKey, ajaxOptions, cacheDuration, callBackSuccess, callBackError) {
            var cache = ExpireCache.namespace(cacheNamespace);
            var cachedPromise = cache.get(cacheKey);

            if (cachedPromise) {
                // Si une promesse est en cache pour cette clé, retournez simplement cette promesse
                return cachedPromise;
            }

            var deferred = $.Deferred();

            // Mettez la promesse en cache pour cette clé
            cache.set(cacheKey, deferred, cacheDuration);

            // Créez une nouvelle promesse pour l'appel AJAX
            var ajaxPromise = $.ajax(ajaxOptions);
            ajaxPromise.success(function () {
                if (callBackSuccess) {
                    var ret = callBackSuccess.apply(null, arguments);
                    // check if ret is a promise
                    if (ret && ret.then) {
                        ret.then(function () {
                            deferred.resolve(arguments[0]);
                        });
                    } else {
                        deferred.resolve(ret);
                    }
                } else {
                    // Résolvez la promesse avec les données de l'appel AJAX
                    deferred.resolve(arguments[0]);
                }
            });
            ajaxPromise.error(function () {
                if (callBackError) {
                    var ret = callBackError.apply(null, arguments);
                    // check if ret is a promise
                    if (ret && ret.then) {
                        ret.then(function () {
                            deferred.reject(arguments);
                        });
                    } else {
                        deferred.reject(ret);
                    }
                } else {
                    // Rejetez la promesse avec les données de l'appel AJAX
                    deferred.reject(arguments[0]);
                }
            });

            // Retournez la promesse
            return deferred;
        }
    };
});

