define('propertiesController',[
    'module',
    'underscore',
    'bootbox',
    'jqGridController',
    'jqGridFormatter'
], function (
    module,
    _,
    Bootbox,
    JqGridController,
    JqGridFormatter
) {
    'use strict';

    module.exports = {
        showProperties: function (model) {
            if (!model.loadProperties) {
                throw new Error('Model does not have loadProperties method');
            }
            model.loadProperties().done(_.bind(function (datas) {
                if (_.isEmpty(datas)) {
                    Bootbox.alert(_.i18n('no.properties'));
                    return;
                }
                var properties = _.chain(datas)
                    .map(function (property) {
                        return {
                            name: property.name,
                            value: property.value
                        };
                    })
                    .sort(function (a, b) {
                        return a.name.localeCompare(b.name);
                    })
                    .value();
                var data = {
                    datatype: 'local',
                    data: properties
                };
                JqGridController.showPopup({
                    data: data,
                    pager: true,
                    filtersName: 'propertyFilters_' + require(model.service).getName() + '_' + model.id,
                    gridOptions: _.bind(function () {
                        var options = {
                            colModel: [{
                                key: true,
                                label: _.i18n('property.name'),
                                name: 'name',
                                formatter: JqGridFormatter.defaultFormatter,
                                search: true,
                                searchoptions: {sopt: ['cn'], clearSearch: false},
                                sortable: true,
                                index: 'name',
                                width: 390
                            }, {
                                label: _.i18n('property.value'),
                                name: 'value',
                                formatter: JqGridFormatter.defaultFormatter,
                                search: true,
                                searchoptions: {sopt: ['cn'], clearSearch: false},
                                sortable: true,
                                index: 'value',
                                width: 260
                            }
                            ],
                            sortname: 'name',
                            sortorder: 'asc'
                        };
                        return options;
                    }, this)
                }, {
                    title: _.i18n('properties')
                });
            }));
        }
    };
});

