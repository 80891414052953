
define('template!wellEditSmpidTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {

 if (well.smpType === 'NC') { ;
__p += '\n<span class="mdi mdi-minus-circle" style="position: absolute; font-size:18px; left: 0;"></span>\n';
 } else if(well.smpType === 'PC') { ;
__p += '\n<span class="mdi mdi-plus-circle" style="position: absolute; font-size:18px; left: 0;"></span>\n';
 } else if(well.smpType === 'C') { ;
__p += '\n<span class="mdi mdi-alpha-c-circle" style="position: absolute; font-size:18px; left: 0;"></span>\n';
 } else if(well.smpType === 'RC') { ;
__p += '\n<span class="mdi mdi-alpha-r-circle" style="position: absolute; font-size:18px; left: 0;"></span>\n';
 } else if(well.smpType === 'P') { ;
__p += '\n<span class="pooled-label" style="position: absolute; font-size:18px; left: 0;">P</span>\n';
 } else if(well.smpType === 'OC') { ;
__p += '\n<span class="mdi mdi-compass" style="position: absolute; font-size:18px; left: 0;"></span>\n';
 } else if(well.smpType === 'D') { ;
__p += '\n<span class="mdi mdi-alpha-d-circle" style="position: absolute; font-size:18px; left: 0;"></span>\n';
 } else if(well.smpType === 'C') { ;
__p += '\n<span class="mdi mdi-alpha-c-circle" style="position: absolute; font-size:18px; left: 0;"></span>\n';
 } ;
__p += '\n<div class=" p-l-0 p-r-0 col-xs-2 h-16"></div>\n';
 if (editMode && !well.refPrepWell && canChangeSmpid) { ;
__p += '\n<div class="col-xs-10 small-regular-compuzz p-l-2 p-r-0 h-16 smp-id-link">\n    <input type="text" ' +
((__t = ( !control ? 'tabindex="' + well.index + '"' : '' )) == null ? '' : __t) +
'\n    class="form-control js-info-input js-input-smpId ' +
((__t = ( control ?
    'js-info-input-control' : '' )) == null ? '' : __t) +
' ' +
((__t = ( well.smpType === 'U' || well.smpType === 'D' || well.smpType === 'C' ? 'js-well-unknow' : '' )) == null ? '' : __t) +
'"\n    style="height:17px; font-size: 13px; ' +
((__t = ( test ? 'color:white; background-color: black;' : 'color:black;' )) == null ? '' : __t) +
'"\n    value="' +
((__t = ( wellSmpIdToDisplay )) == null ? '' : __t) +
'"\n    position="' +
((__t = ( well.pos )) == null ? '' : __t) +
'" data-placement="top">\n</div>\n';
 } else { ;
__p += '\n<span class="col-xs-10 small-regular-compuzz p-l-5 p-r-0 h-16 smp-id-link"\n      style="white-space: nowrap; overflow: hidden; font-family: monospace;"><div\n        class="js-well-sample"></div></span>\n';
 } ;
__p += '\n<div class="well-result-icons p-l-0 p-r-0 col-xs-2 h-16" style="color: green;"></div>\n<span class="btn-assay col-xs-10 small-regular-compuzz p-l-2 p-r-0 h-16"\n      style="white-space: nowrap; overflow: hidden;"><span>' +
((__t = ( well.assay )) == null ? '' : __t) +
'</span></span>\n<div class="well-result-icons p-l-0 p-r-0 col-xs-2 h-16"></div>\n';

}
return __p
};});

