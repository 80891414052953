define('runContentGridItemView',[
    'module',
    'backbone',
    'backbone.marionette',
    'underscore'
], function (
    module,
    Backbone,
    Marionette,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="js-grid-item-region grid-item"></div>'),
        className: 'grid-item rounded',
        modelEvents: {
            'selected': 'selected'
        },
        ui: {
            item: '.js-grid-item-region'
        },
        regions: {
            item: '.js-grid-item-region'
        },
        attributes: {
            style: 'padding: 0 0 0 0'
        },
        triggers: {
            'click': 'item:click'
        },
        onRender: function () {
            this.view = new this.options.view({
                model: this.model
            });
            this.showChildView('item', this.view);
        },
        selected: function (isSelected) {
            this.ui.item.css('visibility', isSelected ? 'visible' : 'hidden');
        },
        isSelected: function () {
            return this.ui.item.css('visibility') === 'visible';
        }
    });
});

