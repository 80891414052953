
define('template!runPcrValidationMainView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="header">\n    <div class="left">\n        <div class="validation-menu-region"></div>\n        <div class="other-menu-region"></div>\n    </div>\n    <div class="middle header">\n        <button type="button" class="previous rounded clickable">\n            <span class="mdi mdi-chevron-left"> </span>\n        </button>\n        <div class="middle rounded-5 details">\n            <div class="d-f" style="gap: 10px;align-items: center;flex-direction: row;justify-content: space-between;">\n                <div class="select-assay-region" style="width: 100%"></div>\n                <span class="mdi mdi-flask-outline clickable"></span>\n            </div>\n            <div class="select-target-region"></div>\n        </div>\n        <button type="button" class="next rounded clickable">\n            <span class="mdi mdi-chevron-right"> </span>\n        </button>\n    </div>\n    <div class="right">\n        <div class="ovar-menu-region"></div>\n        <div class="result-menu-region"></div>\n        <div class="warning-menu-region"></div>\n        <div class="css-grayed-out-header clickable hidden-print mdi mdi-filter-remove js-reset-filters"\n             title="' +
((__t = ( _.i18n('reset.filters') )) == null ? '' : __t) +
'">\n        </div>\n    </div>\n</div>\n\n<div class="content-region"></div>';

}
return __p
};});

