define('entities/global/dataConnectionAll',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'jquery',
    'backboneRelational'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin,
    $
) {
    'use strict';

    app.DataConnectionAll = Backbone.RelationalModel.extend({
        service: 'services/global/dataConnectionAll',
        fetch: function () {
            return $.Deferred().resolve();
        },
        defaults: {
            'code': '',
            'name': '',
            'description': '',
            'configuration': null,
            'query': false,
            'export': false
        },
        idAttribute: 'secId',

        postUrl: function () {
            return Settings.url('rest/global/dataConnections/');
        },

        save: function () {
            throw new Error('Not implemented');
        },

        jsonObjectName: 'DataConnectionAllJson',
        getPDF: function () {
            return Settings.getDocUrl(this.jsonObjectName + '.pdf');
        },

        jsonObject: 'DataConnectionAll',

        importable: true
    });

    app.DataConnectionAll.role = RolesMixin.SUPER_USER;

    app.DataConnectionAllCollection = Backbone.Collection.extend({
        model: app.DataConnectionAll,
        url: Settings.url('rest/global/dataConnections/')
    });
});

