define('entities/caccounts/userCodelist',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'services/caccounts/userCodelistelement'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.UserCodeList = Backbone.RelationalModel.extend({
        service: 'services/caccounts/userCodelist',
        fetch: function () {
            this.url = Settings.url('rest/v2/userCodeLists/' + this.get('secId'));

            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            code: '',
            name: '',
            description: '',
            comment: null,
            tags: [],
            documents: '',
            codeListElements: []
        },
        idAttribute: 'secId',
        relations: [{
            type: Backbone.HasMany,
            key: 'codeListElements',
            relatedModel: 'UserCodeListElement',
            collectionType: 'UserCodeListElementCollection'
        }],
        postUrl: function () {
            return Settings.url('rest/v2/userCodeLists/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        }
    });
    
    app.UserCodeListCollection = Backbone.Collection.extend({
        model: app.UserCodeList
    });

    app.UserCodeList.role = RolesMixin.USERCODELIST;
});

