
define('template!adminTrashView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="header">\n    <div class="header-left">\n    </div>\n    <div class="header-center">\n        <h1>' +
((__t = ( _.i18n('menu.admin.trash') )) == null ? '' : __t) +
'</h1>\n    </div>\n    <div class="header-right">\n    </div>\n</div>\n<div class="trashRegion"></div>';

}
return __p
};});

