define('entities/caccounts/userpidNotification',[
    'entities/ns',
    'jquery',
    'backbone',
    'backboneRelational'
], function (
    app,
    $,
    Backbone
) {
    'use strict';

    app.UserpidNotification = Backbone.RelationalModel.extend({
        service: 'services/caccounts/userpidNotification',
        fetch: function () {
            return $.Deferred().resolve().promise();
        },
        defaults: {}
    });

    app.UserpidNotificationCollection = Backbone.Collection.extend({
        model: app.UserpidNotification
    });
});

