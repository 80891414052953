define('entities/global/userpids',[
    'entities/ns',
    'jquery',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational'
], function (
    app,
    $,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.UserPidGlobal = Backbone.RelationalModel.extend({
        service: 'services/global/userpids',
        fetch: function () {
            this.url = Settings.url('rest/global/userpid/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            firstName: '',
            lastName: '',
            logonId: '',
            nickName: undefined,
            email: undefined,
            creatDate: null
        },
        idAttribute: 'secId',
        save: function () {
            this.url = Settings.url('rest/global/userpid/');
            return Backbone.Model.prototype.save.call(this);
        }
    });

    app.UserPidGlobal.role = RolesMixin.SUPER_USER;

    app.UserPidGlobalCollection = Backbone.Collection.extend({
        model: app.UserPidGlobal
    });

});

