/**
 * Created by OLD on 30/10/2015.
 */
define('superAdminMainRouter',[
    'underscore',
    'jquery',
    'require',
    'exports',
    'module',
    'logger',
    'marionetteAppRouter',
    'app',
    'wellUtils',
    'cssUtils',
    'itemPagesNameMixin',
    'sidebarShowController'
], function (
    _,
    $,
    requireJS,
    exports,
    module,
    Logger,
    AppRouter,
    App,
    WellUtils,
    CssUtils,
    ItemPagesNameMixin,
    SidebarShowController
) {
    'use strict';

    var Controller = {
        checkContent: function () {
            var defer = $.Deferred();
            requireJS([
                'contentShowController'
            ], function (ContentShowController) {
                ContentShowController.showContent().done(function () {
                    defer.resolve();
                });
            });
            return defer.promise();
        },

        showCaccountsSuperadmin: function () {
            requireJS(['caccountsController'], _.bind(function (Controller) {
                this.dynamicShowList('global/caccounts', ItemPagesNameMixin.SUPERADMIN_CACCOUNTS, Controller.showDetails);
            }, this));
        },
        showUsersSuperadmin: function () {
            this.dynamicShowList('global/userpids', ItemPagesNameMixin.SUPERADMIN_USERS);
        },
        showDynamicDefinition: function () {
            this.dynamicShowList('global/dynamicDefinition', ItemPagesNameMixin.SUPERADMIN_DYNAMICDEFINITIONS);
        },
        showTranslations: function () {
            requireJS(['translationsTableController'], _.bind(function (Controller) {
                Controller.getLanguages().done(_.bind(function (languages) {
                    this.dynamicShowList('global/text', ItemPagesNameMixin.SUPERADMIN_TRANSLATIONS, _.bind(Controller.showDetails, Controller), {collection: languages});
                }, this));
            }, this));
        },
        showModules: function () {
            this.checkContent().done(function () {
                requireJS(['systemModuleController'], function (Controller) {
                    Controller.showDetails({region: App.getView().getRegion('content')});
                    SidebarShowController.setActiveNavItem(ItemPagesNameMixin.SUPERADMIN_MODULES);
                });
            });
        },
        showLogs: function () {
            this.dynamicShowList('global/logs', ItemPagesNameMixin.SUPERADMIN_LOGS);
        },
        showDataConnections: function () {
            var service = require('services/global/dataConnectionAll');
            service.findAll().done(_.bind(function () {
                App.services.push(service);
                this.dynamicShowList('global/dataConnectionAll', ItemPagesNameMixin.SUPERADMIN_DATACONNECTIONS);
            }, this));
        },
        showRoutes: function () {
            this.dynamicShowList('global/routes', ItemPagesNameMixin.SUPERADMIN_ROUTES);
        },
        showCyclersSuperadminList: function () {
            this.dynamicShowList('admin/cyclers', ItemPagesNameMixin.SUPERCYCLERS);
        },
        showDetectionFormatsSuperadminList: function () {
            this.dynamicShowList('admin/detectionFormat', ItemPagesNameMixin.SUPERADMIN_DETECTIONFORMATS);
        },

        showCodeListsSuperadminList: function () {
            this.dynamicShowList('admin/codelist', ItemPagesNameMixin.SUPERADMIN_CODELISTS);
        },

        showArraysSuperadminList: function () {
            this.dynamicShowList('admin/array', ItemPagesNameMixin.SUPERADMIN_ARRAYS);
        },

        showGodModeSuperAdmin: function () {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(_.bind(function () {
                requireJS([
                    'superAdminGodModeController'
                ], function (Controller) {
                    Controller.show(App.getView().getRegion('content'));
                });
            }, this));
        },

        dynamicShowList: function (entityNamePath, itemPagesNameMixin, callBackCreateEditView, options) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(function () {
                requireJS([
                    'dynamicController'
                ], function (DynamicController) {
                    DynamicController.showList(
                        App.getView().getRegion('content'), {
                            entityNamePath: entityNamePath,
                            callBackCreateEditView: callBackCreateEditView,
                            options: options,
                            itemPagesNameMixin: itemPagesNameMixin
                        });
                    SidebarShowController.setActiveNavItem(itemPagesNameMixin);
                });
            });
        }
    };

    var SuperAdminRouter = AppRouter.extend({
        appRoutes: {
            'superAdmin/caccounts': 'showCaccountsSuperadmin',
            'superAdmin/users': 'showUsersSuperadmin',
            'superAdmin/cyclers': 'showCyclersSuperadminList',
            'superAdmin/detectionFormats': 'showDetectionFormatsSuperadminList',
            'superAdmin/codelists': 'showCodeListsSuperadminList',
            'superAdmin/arrays': 'showArraysSuperadminList',
            'superAdmin/dynamicDefinition': 'showDynamicDefinition',
            'superAdmin/logs': 'showLogs',
            'superAdmin/modules': 'showModules',
            'superAdmin/dataConnections': 'showDataConnections',
            'superAdmin/godMode': 'showGodModeSuperAdmin',
            'superAdmin/translations': 'showTranslations',
            'superAdmin/routes': 'showRoutes'
        },
        controller: Controller
    });

    module.exports = new SuperAdminRouter();
});

