define('runPrepContentPlateView',[
    'module',
    'backbone.marionette',
    'runContentGridCollectionView',
    'underscore'
], function (
    module,
    Marionette,
    GridCollectionView,
    _
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="grid-region"></div>'),
        regions: {
            'grid': '.grid-region'
        },
        className: 'content',
        serializeData: function () {
            return {};
        },
        onRender: function () {
            this.showChildView('grid', new GridCollectionView({
                model: this.model,
                collection: this.model.get('wells'),
                view: this.options.configuration.childView,
                events: {
                    click: _.bind(function (param) {
                        require(['prepWellController'], function (PrepWellController) {
                            PrepWellController.showDetails({
                                model: param.model,
                                cSelection: param.wells
                            });
                        });
                    }, this)
                },
                catchEventSync: this.options.catchEventSync
            }));
        }
    });
});

