define('services/caccounts/pcrwellresults',[
    'entities/ns',
    'module',
    'jquery',
    'underscore',
    'backbone',
    'dynamic',
    'entities/caccounts/pcrwellresults',
    'entities/caccounts/pcrwells'
], function (
    app,
    module,
    $,
    _,
    Backbone,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getName: function () {
            return 'pcrWellResult';
        },
        getNamespace: function () {
            return {
                model: app.PcrWellResult,
                collection: app.PcrWellResultCollection
            };
        },
        getModel: function (item) {
            return app.PcrWellResult.findOrCreate(item);
        },
        getCollection: function (items) {
            return new app.PcrWellResultCollection(items);
        },
        getUrl: function () {
            return 'rest/v2/results/wells/pcr/';
        }
    }, Dynamic);
});

