define('entities/caccounts/lisSpecies',[
    'entities/ns',
    'backbone',
    'settings',
    'rolesMixin',
    'backboneRelational',
    'entities/caccounts/userCodelist',
    'entities/caccounts/lis',
    'entities/caccounts/species'
], function (
    app,
    Backbone,
    Settings,
    RolesMixin
) {
    'use strict';

    app.LisSpecie = Backbone.RelationalModel.extend({
        service: 'services/caccounts/lisSpecies',
        fetch: function () {
            this.url = Settings.url('rest/v2/lisSpecies/' + this.get('secId'));
            return Backbone.Model.prototype.fetch.call(this);
        },
        defaults: {
            'lis': null,
            'specie': null,
            'code': '',
            'name': '',
            'description': '',
            'tags': [],
            'criterias': [],
            'jsonobjectdocumentation': 'TB_LisSpecieJson.pdf'
        },
        idAttribute: 'secId',

        relations: [{
            type: Backbone.HasOne,
            key: 'lis',
            relatedModel: 'Lis',
            includeInJSON: ['secId', 'code', 'name']
        }, {
            type: Backbone.HasOne,
            key: 'specie',
            relatedModel: 'Specie',
            includeInJSON: ['secId', 'code', 'name']
        }],

        postUrl: function () {
            return Settings.url('rest/v2/lisSpecies/');
        },

        save: function () {
            this.url = this.postUrl();
            return Backbone.Model.prototype.save.call(this);
        },
        getPDF: function () {
            return Settings.getDocUrl('LisSpecieJson.pdf');
        }
    });

    app.LisSpecie.role = RolesMixin.LISSPECIE;

    app.LisSpecieCollection = Backbone.Collection.extend({
        url: function () {
            return Settings.url('rest/v2/lisSpecies/');
        },
        model: app.LisSpecie
    });
});

