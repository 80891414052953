
define('template!exportResultByConfigurationType', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '', __j = Array.prototype.join;
function print() { __p += __j.call(arguments, '') }
with (obj) {
__p += '<label class="col-xs-6 p-r-10 text-right" for="' +
((__t = ( uniqueId )) == null ? '' : __t) +
'">' +
((__t = ( action )) == null ? '' : __t) +
'</label>\n<select class="col-xs-6 p-0 js-info-input-child" id="' +
((__t = ( uniqueId )) == null ? '' : __t) +
'">\n	<option value=""></option>\n	';
 _.each(configurations, function(configuration) { ;
__p += '\n	<option value="' +
((__t = ( configuration.secId )) == null ? '' : __t) +
'"\n	' +
((__t = ( currentConfiguration === configuration.secId ? 'selected="selected"': '' )) == null ? '' : __t) +
'>' +
((__t = ( (configuration.default ? '(D) '
	: '') + configuration.dataConnection.name
	)) == null ? '' : __t) +
'</option>\n	';
 }) ;
__p += '\n</select>';

}
return __p
};});

