/**
 * Created by bfr on 30/01/2017.
 */
define('runsMainRouter',[
    'module',
    'marionetteAppRouter',
    'app',
    'wellUtils',
    'cssUtils',
    'underscore',
    'contentShowController',
    'itemPagesNameMixin',
    'jquery',
    'sidebarShowController',
    'emptyView',
    'runPcrController'
], function (
    module,
    AppRouter,
    App,
    WellUtils,
    CssUtils,
    _,
    ContentShowController,
    ItemPagesNameMixin,
    $,
    SidebarShowController,
    EmptyView,
    RunPcrController
) {
    'use strict';

    var Controller = {
        findModel: function (runSecId, callback) {
            var defer = $.Deferred();
            var PcrRunService = require('services/caccounts/pcrruns');
            var model = PcrRunService.getModel({secId: runSecId});
            var defers = [];
            defers.push(model.fetch());
            defers.push(model.get('wells').fetch({pcrRunSecId: runSecId}));
            $.when.apply($, defers).done(_.bind(function () {
                this.model = model;
                if (callback) {
                    callback();
                }
                defer.resolve();
            }, this));

            return defer.promise();
        },


        getModel: function (callback) {
            var defer = $.Deferred();
            defer.resolve();
            if (callback) {
                callback(this.model);
            }
            return defer.promise();
        },

        showEmpty: function () {
            CssUtils.applyPcrRunTheme();
        },

        showView: function () {
            this.dynamicShowList({
                entityNamePath: 'caccounts/pcrruns',
                itemPagesNameMixin: ItemPagesNameMixin.RUNS,
                callBackCreateEditView: RunPcrController.showDetails,
                callBackItemClick: _.bind(function (model, gridView) {
                    if(gridView) {
                        var ids = gridView.jqGrid('getDataIDs');
                        // convert all to int
                        ids = ids.map(function (id) {
                            return parseInt(id, 10);
                        });
                        this.ids = ids;
                    }
                    App.navigate('runs/pcr/' + model.get('secId') + '?fromSelect=true', {trigger: true});
                }, this)
            });
        },

        show: function (runSecId) {
            $('.js-global-loader').show();
            // check parameter url with parameter name "select" is setted
            var url = window.location.href;
            var select = url.match(/fromSelect=/);
            if(!select) {
                delete this.ids;
            }
            
            ContentShowController.showContent().done(_.bind(function () {
                var region = App.getView().getRegion('content');
                region.show(new EmptyView());
                WellUtils.dismissRemainingPopovers();
                CssUtils.applyPcrRunTheme();
                this.findModel(runSecId).done(_.bind(function () {
                    require(['runController'], _.bind(function (RunController) {
                        RunController.show(region, this.model, this.ids);
                        SidebarShowController.setActiveNavItem(ItemPagesNameMixin.RUNS);
                        $('.js-global-loader').hide();
                    }, this));
                }, this));
            }, this));
        },

        dynamicShowList: function (request) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyDefaultTheme();
            this.checkContent().done(function () {
                require([
                    'dynamicController'
                ], function (DynamicController) {
                    DynamicController.showList(
                        App.getView().getRegion('content'), {
                            entityNamePath: request.entityNamePath,
                            callBackCreateEditView: request.callBackCreateEditView,
                            callBackItemClick: request.callBackItemClick
                        });
                    SidebarShowController.setActiveNavItem(request.itemPagesNameMixin);
                });
            });
        },

        checkContent: function () {
            var defer = $.Deferred();

            require([
                'contentShowController'
            ], function (ContentShowController) {
                ContentShowController.showContent().done(function () {
                    defer.resolve();
                });
            });
            return defer.promise();
        }
    };

    var RunsRouter = AppRouter.extend({
        appRoutes: {
            'runs/pcr': 'showView',
            'runs/pcr/empty': 'showEmpty',
            'runs/pcr/:runSecId': 'show'
        },
        controller: Controller
    });

    module.exports = new RunsRouter();
});

