define('runPcrValidationMainWellView',[
    'module',
    'underscore',
    'jquery',
    'jqGridController',
    'backbone.marionette',
    'jqGridFormatter',
    'wellUtils',
    'colorUtils',
    'pcrWellResultController'
], function (
    module,
    _,
    $,
    JqGridController,
    Marionette,
    JqGridFormatter,
    WellUtils,
    ColorUtils,
    PcrWellResultController
) {
    'use strict';

    module.exports = Marionette.View.extend({
        template: _.template('<div class="js-grid-region" style="flex-grow: 1; width: 100%; height: 100%;"></div>'),
        regions: {
            gridRegion: '.js-grid-region'
        },

        className: 'content d-f',
        attributes: {
            style: 'flex-grow: 1; width: 100%; height: 100%;'
        },

        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: 20,
                currentPage: 1
            };
        },

        onRender: function () {
            var data = this.getListViewData(this.model.get('wellSelected'));

            var view = JqGridController.show({
                data: data,
                pager: true,
                filtersName: 'wellValidationTableListFilters',
                gridOptions: _.bind(this.gridOptions, this),
                paginationObject: this.paginationObject,
                extend: [{'setGroupHeaders': _.bind(this.jqGridViewSetGroupHeaders, this)}]
            }, this.getRegion('gridRegion'));
            this.listenTo(view, 'click', _.bind(this.onClick, this));
            this.listenTo(view, 'loadComplete', _.bind(this.onLoadComplete, this));
        },

        jqGridViewSetGroupHeaders: function () {
            if (_.isEmpty(this.groups)) {
                return {};
            }
            var ret = {groupHeaders: []};
            _.each(this.groups, function (group) {
                ret.groupHeaders.push({
                    startColumnName: group.results[0],
                    numberOfColumns: group.results.length,
                    titleText: group.parent,
                    className: 'extend'
                });
            });
            return ret;
        },

        onLoadComplete: function (jqGridView, datas) {
            this.setWellListPopover(jqGridView, datas);
        },

        modelEvents: {
            'change:assayResultVersion': 'render'
        },

        gridOptions: function () {
            var options = {
                colModel: [
                    {
                        label: '',
                        name: 'secId',
                        key: true,
                        hidden: true
                    }, {
                        label: ' ',
                        name: 'select',
                        sortable: false,
                        search: false,
                        classes: 'select-link-action ignore-row-click',
                        formatter: JqGridFormatter.rowSelectorFormatter,
                        fixed: true,
                        width: 25,
                        checked: true
                    }, {
                        label: JqGridFormatter.getSmpTypeLabelIcon(),
                        name: 'smpType',
                        formatter: _.bind(this.smptypeFormater, this),
                        search: true,
                        sortable: true,
                        index: 'smpType',
                        stype: 'select',
                        searchrules: {select: true},
                        searchoptions: {sopt: ['eq'], value: this.smpTypeList, clearSearch: false},
                        fixed: true,
                        width: 30
                    }, {
                        label: _.i18n('well.pos'),
                        name: 'pos',
                        formatter: JqGridFormatter.wellPosFormatter,
                        classes: 'css-grid-cell-accentuatuion-03',
                        sortable: true,
                        title: false,
                        index: 'pos',
                        fixed: true,
                        width: 50,
                        search: false,
                        searchoptions: {
                            sopt: ['cn'],
                            clearSearch: false
                        }
                    }, {
                        label: _.i18n('well.smpId'),
                        name: 'smpId.name',
                        formatter: _.bind(this.wellSmpidFormatter, this),
                        classes: 'css-grid-cell-accentuatuion-02 displayPopover',
                        search: true,
                        sortable: true,
                        title: false,
                        index: 'smpId.name',
                        searchoptions: {
                            sopt: ['cn'],
                            clearSearch: false
                        },
                        fixed: true,
                        width: 130
                    }, {
                        label: _.i18n('well.validation'),
                        name: 'valWst',
                        formatter: JqGridFormatter.validationFormatter,
                        search: false,
                        width: 40,
                        fixed: true
                    }
                ],
                sortname: this.sortOn ? this.sortOn : 'row'
            };
            var results = this.model.get('assayResultVersion').get('assayVersion').getDisplay('VALIDATION');
            var max = results.length;
            var width = 20;
            var location = this.model.get('assayResultVersion').get('assayVersion').get('assay').getDisplayMode().findParameterForLocation('Val');
            var paramSplitted = location.get('parameter') && location.get('parameter').param ? location.get('parameter').param.split(';') : [];
            var isHorizontal = location.get('parameter').type === 'HISTOGRAM' && paramSplitted.length > 11 && paramSplitted[11] === 'HORIZONTAL';
            if (location.get('parameter').type === 'DOT') {
                width = 40;
            } else if (location.get('parameter').type === 'REFVALUE') {
                width = 100;
            } else {
                if (isHorizontal) {
                    if (max < 8) {
                        width = 60;
                    } else {
                        width = 40;
                    }
                }
            }

            var ovarResult = this.model.get('assayResultVersion').get('assayVersion').getDisplay('OVAR').first();
            if (ovarResult) {
                var target = ovarResult.get('assayConfigurationResult').get('target');
                var refMbAnaResGr = target.get('refMbAnaResGr');
                var mbAnaResValues = refMbAnaResGr.get('mbAnaRes').map(function (mbAnaRes) {
                    return mbAnaRes.get('code') + ':' + mbAnaRes.get('code');
                });

                // Prépare la valeur des options avec une valeur "any" au début
                var mbAnaRes;
                if (!_.isEmpty(mbAnaResValues)) {
                    mbAnaRes = ':' + _.i18n('common.any') + ';' + mbAnaResValues.join(';');
                }
                options.colModel.push({
                    label: _.i18n('well.icon'),
                    name: 'ovar',
                    formatter: JqGridFormatter.ovarResultFormatter,
                    labelClasses: 'rotated-text',
                    index: 'target_' + ovarResult.get('assayConfigurationResult').get('target').get('code'),
                    search: true,
                    sortable: false,
                    width: 50,
                    fixed: true,
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {
                        sopt: ['eq'],
                        value: mbAnaRes, // Valeur spécifique à cette colonne
                        clearSearch: false
                    }
                });
            }

            this.groups = [];

            results.each(_.bind(function (result, j) {
                var displayGroup = {};
                if (!result.get('assayConfigurationResult').get('linkedAssayResultVersions').isEmpty()) {
                    displayGroup = {};
                    displayGroup.name = 'group_' + j + '_' + result.get('assayConfigurationResult').get('linkedAssayResultVersions').first().get('target').get('code');
                    displayGroup.size = result.get('assayConfigurationResult').get('linkedAssayResultVersions').length;
                }
                var target = result.get('assayConfigurationResult').get('target');
                var refMbAnaResGr = target.get('refMbAnaResGr');
                var mbAnaResValues = refMbAnaResGr.get('mbAnaRes').map(function (mbAnaRes) {
                    return mbAnaRes.get('code') + ':' + mbAnaRes.get('code');
                });

                // Prépare la valeur des options avec une valeur "any" au début
                var mbAnaRes;
                if (!_.isEmpty(mbAnaResValues)) {
                    mbAnaRes = ':' + _.i18n('common.any') + ';' + mbAnaResValues.join(';');
                }

                options.colModel.push({
                    label: result.get('assayConfigurationResult').get('target').get('code'),
                    name: 'target_' + result.get('assayConfigurationResult').get('target').get('code'),
                    formatter: _.bind(this.resultFormatter, this),
                    labelClasses: location.get('parameter').type === 'REFVALUE' ? '' : 'rotated-text',
                    search: true,
                    sortable: true,
                    width: width,
                    index: 'target_' + result.get('assayConfigurationResult').get('target').get('code'),
                    fixed: true,
                    frozen: true,
                    resizable: false,
                    targetId: result.get('assayConfigurationResult').get('target').id,
                    displayGroup: displayGroup,
                    stype: 'select',
                    searchrules: {select: true},
                    searchoptions: {
                        sopt: ['eq'],
                        value: mbAnaRes, // Valeur spécifique à cette colonne
                        clearSearch: false
                    }
                });

                if (!result.get('assayConfigurationResult').get('linkedAssayResultVersions').isEmpty()) {
                    var group = {parent: options.colModel[options.colModel.length - 1].name, results: []};
                    result.get('assayConfigurationResult').get('linkedAssayResultVersions').each(_.bind(function (res) {
                        var target = result.get('assayConfigurationResult').get('target');
                        var refMbAnaResGr = target.get('refMbAnaResGr');
                        var mbAnaResValues = refMbAnaResGr.get('mbAnaRes').map(function (mbAnaRes) {
                            return mbAnaRes.get('code') + ':' + mbAnaRes.get('code');
                        });

                        // Prépare la valeur des options avec une valeur "any" au début
                        var mbAnaRes;
                        if (!_.isEmpty(mbAnaResValues)) {
                            mbAnaRes = ':' + _.i18n('common.any') + ';' + mbAnaResValues.join(';');
                        }

                        options.colModel.push({
                            label: res.get('target').get('code'),
                            name: 'group_' + j + '_' + res.get('target').get('code'),
                            formatter: _.bind(this.resultFormatter, this),
                            labelClasses: 'rotated-text extend',
                            search: true,
                            sortable: false,
                            width: width,
                            fixed: true,
                            frozen: true,
                            resizable: false,
                            targetId: res.get('target').id,
                            linkedTo: result.get('assayConfigurationResult').get('target').get('code'),
                            index: 'target_' + res.get('target').get('code'),
                            hidden: true,
                            stype: 'select',
                            searchrules: {select: true},
                            searchoptions: {
                                sopt: ['eq'],
                                value: mbAnaRes, // Valeur spécifique à cette colonne
                                clearSearch: false
                            }
                        });
                        group.results.push('group_' + j + '_' + res.get('target').get('code'));
                    }, this));
                    this.groups.push(group);
                }
            }, this));
            return options;
        },

        onClick: function (obj) {
            require(['pcrWellController'], _.bind(function (PcrWellController) {
                var well = this.model.get('wellSelected').get(parseInt(obj.rowId, 10));
                PcrWellController.showDetails({model: well});
            }, this));
        },
        onGridComplete: function () {
            var $item = this.$('.js-wellRes-color-cell');
            _.each($item, _.bind(function (item) {
                var $item = $(item);
                var color = $item.attr('data-color');
                var rgbObj = ColorUtils.hexToRgba(color);
                var rgbaCol = 'rgba(' + rgbObj.r + ',' + rgbObj.g + ',' + rgbObj.b + ', 0.2) !important';
                $item.parent().parent().parent().attr('style', 'background-color: ' + rgbaCol + ';');
            }, this));
        },

        wellSmpidFormatter: function (cellValue, options, object) {
            if (!cellValue) {
                cellValue = '';
            }
            if (object.pos) {
                return '<span class="cell-default wellSmpidPopover-' + object.pos + '" data-row-id="' +
                    options.rowId + '" style="font-weight: 600;">' + cellValue + '</span>';
            }
            return '<span class="cell-default" data-row-id="' +
                options.rowId + '" style="font-weight: 600;">' + cellValue + '</span>';
        },

        smptypeFormater: function (cellValue) {
            var start, end;
            start = '<div class="row m-b-0 m-t-0">';
            start += '<div class="col-xs-12 p-l-15">';

            if (cellValue === 'NC') {
                start += '<span class="mdi-content-remove-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'PC') {
                start += '<span class="mdi-content-add-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'OC') {
                start += '<span class="mdi mdi-compass" style="font-size: 17px;"></span>';
            } else if (cellValue === 'D') {
                start += '<span class="mdi mdi-alpha-d-circle" style="font-size: 17px;"></span>';
            } else if (cellValue === 'C') {
                start += '<span class="iconify" data-icon="ant-design:copyright-circle-filled" style="font-size: 17px;"></span>';
            } else if (cellValue === 'RC') {
                start += '<span class="iconify" data-icon="bxs:registered" style="font-size: 17px;"></span>';
            } else if (cellValue === 'P') {
                start += '<span class="iconify" data-icon="mdi:google-circles-communities" style="font-size: 17px;"></span>';
            }

            start += '</div>';
            end = '</div>';
            return start + end;
        },

        getListViewData: function (filteredWells) {
            var wellListViewData = this.getWellListViewData(filteredWells);
            var data = {
                datatype: 'local',
                data: wellListViewData.wellsForData
            };
            return data;
        },
        getWellListViewData: function (wells) {
            var wellsForData = wells.map(function (well) {
                var obj = well.toJSON();
                well.getResults().each(function (result) {
                    obj['target_' + result.get('refAssayResult').get('target').get('code')] = result.get('result').get('code');
                });
                return obj;
            });
            return {
                wellsForData: wellsForData
            };
        },
        setWellListPopover: function (jqGridView, datas) {
            _.each(datas, _.bind(function (data) {
                var well = this.model.get('wellSelected').get(data.secId);
                if (!well) {
                    return;
                }
                var popovers = jqGridView.$el.find('tr#' + data.secId + ' .displayPopover');
                if (popovers.length < 1) {
                    return;
                }

                var params = {
                    model: well
                };

                params.targets = popovers;

                // if ((well.get('wellRes') && well.get('wellRes').get('result') && well.get('wellRes').get('result').get('code')) &&
                //     (well.get('wellRes') && well.get('wellRes').get('quantificationFormatted'))) {
                //     params.targets.push($('.ovrerallResultPopover-' + well.get('pos')));
                // }
                var placement = 'right';
                var index = _.indexOf(datas, data);
                if (index < 3) {
                    placement = 'bottom';
                } else if (index > datas.length - 4) {
                    placement = 'top';
                }
                params.placement = placement;
                WellUtils.showPcrWellPopover(params);
            }, this));
        },
        resultFormatter: function (cellValue, call, object) {
            var model = this.model.get('wellSelected').findWhere({secId: object.secId});
            var html = '';
            var result = model.findResultByTargetId(call.colModel.targetId);
            if (result) {
                html = PcrWellResultController.generateContent(result, 'Val');
            }
            if (result.get('refAssayResult') === this.model.get('assayResultVersion')) {
                html = '<div class="selected">' + html + '</div>';
            }
            return html;
        }
    });
});

