define('services/caccounts/lisConfiguration',[
    'entities/ns',
    'app',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/lisConfiguration'
], function (
    app,
    App,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.LisConfiguration,
                collection: app.LisConfigurationCollection
            };
        },
        getName: function () {
            return 'lis';
        },
        getUrl: function () {
            return 'rest/v2/lis/';
        },
        getDynamicJson: function () {
            return 'LISJson';
        },
        getFieldsToDisplay: function () {
            return {'fieldsWidth': 400, 'fields': ['action', 'source']};
        },
        getCreateEditLine: function () {
            return [
                {
                    'field': 'source', 
                    'param': {
                        'type': 'LISCONFIGURATION',
                        'display': {
                            'class': 'col-w-90', 'headers': [{
                                'field': 'ACTION',
                                'param': {'display': {'class': 'col-w-27'}}
                            }, {
                                'field': 'DATACONNECTION',
                                'param': {'display': {'class': 'col-w-30'}}
                            }]
                        }
                    }
                },
                {'field': 'default', 'param': {'type': 'BOOLEAN', 'display': {'class': 'col-w-3'}}}
            ];
        }
    }, Dynamic);
});

