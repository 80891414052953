define('services/caccounts/pcrwelltemplates',[
    'entities/ns',
    'settings',
    'jquery',
    'underscore',
    'module',
    'dynamic',
    'entities/caccounts/pcrwells'
], function (
    app,
    Settings,
    $,
    _,
    module,
    Dynamic
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.PcrWellTemplate,
                collection: app.PcrWellTemplateCollection
            };
        },
        getList: function (fieldName) {
            var defer = $.Deferred();
            var configurations = [];
            var UserCodeListService = require('services/caccounts/userCodelist');
            configurations.push({fieldName: 'tags', values: UserCodeListService.findItemsByCode('TAG_RESULT'), codeListCode: 'TAG_RESULT'});
            var configuration = _.findWhere(configurations, {fieldName: fieldName});
            if (configuration) {
                configuration.values.done(function (items) {
                    defer.resolve({items: items});
                });
            } else {
                defer.reject('No found for ' + fieldName);
            }
            return defer.promise();
        },
        getName: function () {
            return 'PcrWellTemplate';
        }
    }, Dynamic);
});

