
define('template!caccountsEditView', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="form-horizontal">\n    <div class="form-inputs-container">\n        <div class="form-group p-l-30" data-field-name="name">\n            <span class="col-xs-12 card-title h4">' +
((__t = ( _.i18n('account.profile.identification') )) == null ? '' : __t) +
'</span>\n        </div>\n        <div class="row">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('userInfo.logo') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <div class="row profile-logo-container">\n                    <div class="_texture-bg _keep-img-link profile-img-block">\n                        <img class="logo-holder clickable" src="data:image;base64,' +
((__t = ( logo )) == null ? '' : __t) +
'">\n                    </div>\n                    <div class="logo-holder js-logo-holder col-xs-2 clickable default-upload-image hidden"></div>\n                    <div class="logo-editor">\n                        <label for="change-logo-input">\n                            <span class="mdi mdi-file-upload clickable"></span>\n                        </label>\n                        <input type="file" class="hidden js-change-logo" id="change-logo-input" accept="image/*">\n                    </div>\n                </div>\n            </div>\n        </div>\n        <div class="form-group" data-field-name="code">\n            <div class="col-xs-12">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.code') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <input type="text" class="form-control floating-label js-info-input js-code-input"\n                           data-field-name="code" value="' +
((__t = ( code )) == null ? '' : __t) +
'"\n                           placeholder="' +
((__t = ( _.i18n('common.accountId') )) == null ? '' : __t) +
'"\n                           data-toggle="popover" data-content="' +
((__t = ( _.i18n('common.code.duplicate') )) == null ? '' : __t) +
'"\n                           data-placement="top" data-trigger="manual">\n                </div>\n            </div>\n        </div>\n        <div class="form-group" data-field-name="name">\n            <div class="col-xs-12">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('cAccountInfo.name') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <input type="text" class="form-control floating-label js-info-input" data-field-name="name"\n                           placeholder="' +
((__t = ( _.i18n('cAccountInfo.name') )) == null ? '' : __t) +
'" value="' +
((__t = ( name )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n        </div>\n        <div class="form-group" data-field-name="address">\n            <div class="col-xs-12">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.street') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <input type="text" class="form-control floating-label js-info-input" data-field-name="address"\n                           placeholder="' +
((__t = ( _.i18n('common.street') )) == null ? '' : __t) +
'" value="' +
((__t = ( address )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n        </div>\n        <div class="form-group p-l-10">\n            <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
' / ' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'</label>\n            <div class="col-xs-9">\n                <div class="col-xs-4 p-l-0" data-field-name="zip">\n                    <input type="text" class="form-control floating-label js-info-input" data-field-name="zip"\n                           placeholder="' +
((__t = ( _.i18n('common.zip') )) == null ? '' : __t) +
'" value="' +
((__t = ( zip )) == null ? '' : __t) +
'">\n                </div>\n                <div class="col-xs-8" data-field-name="city">\n                    <input type="text" class="form-control floating-label js-info-input" data-field-name="city"\n                           placeholder="' +
((__t = ( _.i18n('common.city') )) == null ? '' : __t) +
'" value="' +
((__t = ( city )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n        </div>\n        <div class="form-group js-countries">\n            <div class="col-xs-12">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.countryCode') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <div class="select-countries"></div>\n                </div>\n            </div>\n        </div>\n        <div class="form-group" data-field-name="phone">\n            <div class="col-xs-12">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.phone') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <input type="text" class="form-control floating-label js-info-input" data-field-name="phone"\n                           placeholder="' +
((__t = ( _.i18n('common.phone') )) == null ? '' : __t) +
'" value="' +
((__t = ( phone )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n        </div>\n        <div class="form-group" data-field-name="contactEmail">\n            <div class="col-xs-12">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9">\n                    <input type="text"\n                           id="contactEmailInput"\n                           class="form-control floating-label js-info-input" data-field-name="contactEmail"\n                           value="' +
((__t = ( contactEmail )) == null ? '' : __t) +
'" placeholder="' +
((__t = ( _.i18n('common.email') )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n        </div>\n        <div class="form-group js-langs">\n            <div class="col-xs-12">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.languages') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9 languages-region"></div>\n            </div>\n        </div>\n        <div class="form-group js-modules">\n            <div class="col-xs-12">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('common.modules') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-9 modules-region"></div>\n            </div>\n        </div>\n        <div class="form-group" data-field-name="hl7port">\n            <div class="col-xs-12">\n                <label class="col-xs-3 control-label">' +
((__t = ( _.i18n('caccount.hl7port') )) == null ? '' : __t) +
'</label>\n                <div class="col-xs-2">\n                    <input type="number" class="form-control floating-label js-info-input" data-field-name="hl7port"\n                           placeholder="' +
((__t = ( _.i18n('caccount.hl7port') )) == null ? '' : __t) +
'" value="' +
((__t = ( hl7port )) == null ? '' : __t) +
'">\n                </div>\n            </div>\n        </div>\n        <div class="row">\n            <div class="pull-right p-r-15 ">\n                <button class="btn btn-default btn-flat cancel-b js-cancel js-cancel-popup">' +
((__t = (
                    _.i18n('common.cancel') )) == null ? '' : __t) +
'\n                </button>\n                <button disabled class="btn btn-primary disabled-grey js-confirm">' +
((__t = ( _.i18n('common.confirm') )) == null ? '' : __t) +
'\n                </button>\n            </div>\n        </div>\n    </div>\n</div>\n';

}
return __p
};});

