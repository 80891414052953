define('pcrWellController',[
    'module',
    'underscore',
    'jquery',
    'entities/ns',
    'pcrWellDetailsView',
    'pcrWellToolTipView'
], function (
    module,
    _,
    $,
    app,
    PcrWellDetailsView,
    PcrWellToolTipView
) {
    'use strict';

    module.exports = {
        _loadDetails: function (param) {
            var defer = $.Deferred();
            var defers = [];
            defers.push(param.model.fetch());
            defers.push(require('services/caccounts/prepwells').findByPcrWell(param.model));
            $.when.apply($, defers).done(function () {
                defer.resolve();
            });
            return defer.promise();
        },
        /**
         * Show the details of the model.
         * @param param - The parameter object.
         * @param {Object} param.model - The model whose details are to be shown.
         * @param {Object} param.cSelection - The cSelection object.
         * @param {Object} param.icRefKitsToDiplay - The icRefKitsToDiplay object.
         * @param {Function} param.callBackOnClose - The callback function to be called after the PcrWellDetailsView is cl
         */
        showDetails: function (param) {
            $('.js-global-loader').show();
            this._loadDetails(param).done(_.bind(function () {
                var view = new PcrWellDetailsView({
                    model: param.model,
                    cSelection: param.cSelection,
                    icRefKitsToDiplay: param.icRefKitsToDiplay,
                    loadDetails: _.bind(this._loadDetails, this)
                });
                view.show({
                    title: param.model.get('run').get('name'),
                    className: 'popupWellView'
                }, _.bind(function () {
                    if (param.callBackOnClose) {
                        param.callBackOnClose();
                    }
                }, this));
            }, this))
                .always(function () {
                    $('.js-global-loader').hide();
                });
        },
        generateToolTip: function (request) {
            if (!(request.model instanceof app.PcrWell)) {
                throw new Error('model must be instance of app.PcrWell');
            }
            var view = new PcrWellToolTipView({
                model: request.model
            });
            view.render();
            var content = view.$el.html();
            view.destroy();
            return content;
        }
    };
});

