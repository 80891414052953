
define('template!assayCreateEditLayoutTpl', ['lodash'], function(_) {return function(obj) {
obj || (obj = {});
var __t, __p = '';
with (obj) {
__p += '<div class="js-codeName-region"></div>\n\n<div class="header">\n    <div class="col-xs-2" data-field-name="refAssayGroup">\n        <label class="control-label noWrapHidden" data-field-name="refAssayGroup">' +
((__t = ( _.i18n('assay.groupName') )) == null ? '' : __t) +
'</label><br/>\n        <div class="js-select-group-name-region" data-field-name="refAssayGroup"></div>\n    </div>\n    <div class="col-xs-2" data-field-name="refKitProt">\n        <label class="control-label noWrapHidden" data-field-name="refKitProt">' +
((__t = ( _.i18n('menu.admin.kitprots') )) == null ? '' : __t) +
'</label><br/>\n        <div class="js-select-kitProt-region"></div>\n    </div>\n    <div class="col-xs-2" data-field-name="displayMode">\n        <label class="control-label noWrapHidden" data-field-name="displayMode">' +
((__t = ( _.i18n('assay.displayMode') )) == null ? '' : __t) +
'</label><br/>\n        <div class="js-select-displayMode-region" data-field-name="displayMode"></div>\n    </div>\n    <div class="col-xs-1" data-field-name="protocolVariant">\n        <label class="control-label noWrapHidden" data-field-name="protocolVariant">' +
((__t = ( _.i18n('assay.protocolVariant') )) == null ? '' : __t) +
'</label><br/>\n        <div class="js-select-protocol-variant-region" data-field-name="protocolVariant"></div>\n    </div>\n    <div class="col-xs-1" data-field-name="volumeBySample">\n        <label class="control-label noWrapHidden" data-field-name="refKitProt">' +
((__t = ( _.i18n('assay.volumeBySample') )) == null ? '' : __t) +
'</label><br/>\n        <input type="number" step="0.01" min="0"\n               class="js-info-input form-control inputBackground"\n               data-field-name="volumeBySample"\n               placeholder="' +
((__t = ( _.i18n('common.empty.placeholder') )) == null ? '' : __t) +
'"\n               value="' +
((__t = ( volumeBySample?volumeBySample:'' )) == null ? '' : __t) +
'"/>\n    </div>\n</div>\n\n<div class="js-assayConfiguration-region"></div>\n<!-- liaison lots -->\n<div class="col-xs-12 m-t-10 m-b-10 p-0"\n     style="display: flex; column-gap: 10px; align-items: flex-start;">\n    <div class="col-xs-4 js-reagent-qc rounded"></div>\n    <div class="col-xs-3 js-reagent-ic rounded"></div>\n    <div class="col-xs-5 js-reagent-mmx rounded"></div>\n</div>\n<!-- liaison lots -->\n<div class="col-xs-12 m-b-10 p-0"\n     style="display: flex; column-gap: 10px; align-items: flex-start;">\n    <div class="col-xs-4"></div>\n    <div class="col-xs-3"></div>\n    <div class="col-xs-5" style="display: flex; align-items: baseline;">\n        <label class="col-xs-4 control-label p-r-2 p-l-2">' +
((__t = ( _.i18n('assay.deathVolumeMMX')
            )) == null ? '' : __t) +
'</label>\n        <div class="col-xs-8 p-r-2 p-l-2 js-select-algo-deathVolume-region"></div>\n    </div>\n</div>\n<!-- comments row -->\n\n<div class="js-description"></div>\n<!-- cancel/confirm row -->\n<div class="cancelConfirmRow">\n    <button class="mdi-navigation-close btn popupBtn-cancel cancel-b js-cancel-popup"></button>\n    <button disabled class="mdi-navigation-check btn popupBtn-confirm disabled-grey js-confirm"></button>\n</div>\n\n';

}
return __p
};});

