define('pcrWellDetailsView',[
    'module',
    'entities/ns',
    'backbone.marionette',
    'dialogFormView',
    'settings',
    'template!pcrWellDetailsView',
    'underscore',
    'jquery',
    'app',
    'renderer',
    'bootbox',
    'pcrWellResultView',
    'sampleCommentView',
    'linkNewTabView',
    'dynamicCreateEditCustomListToList',
    'pcrWellGraphCurveView',
    'template!wellPooledTooltipTpl',
    'template!wellPoolTooltipTpl',
    'rolesMixin',
    'privileges',
    'entities/menuGroup',
    'backbone',
    'menuGroup',
    'wellDisplaySampleView',
    'emptyView',
    'jqGridController'
], function (
    module,
    app,
    Marionette,
    DialogFormView,
    Settings,
    tpl,
    _,
    $,
    App,
    Renderer,
    bootbox,
    PcrWellResultView,
    EditSampleCommentView,
    LinkNewTabView,
    DynamicCreateEditCustomListToList,
    PcrWellGraphCurveView,
    WellPooledTooltipTpl,
    WellPoolTooltipTpl,
    RolesMixin,
    Privileges,
    MenuGroup,
    Backbone,
    MenuGroupView,
    WellDisplaySampleView,
    EmptyView,
    JqGridController
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: tpl,

        ui: {
            btnEditWellResult: '.btn-edit-well-result',
            btnEditAssay: '.btn-edit-assay',
            btnEditAssayVersion: '.btn-edit-assay-version',
            poolLabel: '.pool-label',
            pooledLabel: '.pooled-label',
            btnNextWell: '.btn-next-well',
            btnPreviousWell: '.btn-previous-well',
            copyParameters: '.js-copyParameters',
            showProperties: '.js-showProperties'
        },

        events: {
            'click .btn-show-prepWell': 'onShowPrepWell',
            'click @ui.btnEditWellResult': 'onEditWellResult',
            'click @ui.btnEditAssay': 'onAssayEdit',
            'click @ui.btnEditAssayVersion': 'onAssayEditVersion',
            'click .btn-next-well.clickable': 'onNextWell',
            'click .btn-previous-well.clickable': 'onPreviousWell',
            'click .js-btn-refresh-well': 'onRefreshWell',
            'click .sampleid': 'onSampleIdClick',
            'click .js-edit-sampleComment': 'onEditSampleComment',
            'click .js-pcrWell-tracs-view': 'onShowTracsView',
            'click .js-run-valView-action-nav-drop-btn': 'onRunActionNavDropBtnClick',
            'click .js-run-valView-action-nav-drop-cell-btn': 'onRunActionNavDropCellBtnClick',
            'mouseleave .run-valView-action-nav-container': 'onHideRunActionContainer',
            'click .valView-dilution-dropdown': 'onDilution',
            'click @ui.showProperties': 'onShowProperties'
        },

        regions: {
            wellDisplaySample: '.js-well-sample',
            runDisplay: '.js-preprun',
            tags: '.js-tags',
            graphRegion: '.js-graph-region',
            resultRegion: '.js-result-region',
            validationMenu: '.js-validation-menu',
            repeatMenu: '.js-repeat-menu',
            tracingMenu: '.js-tracing-menu',
            testMenu: '.js-test-menu',
            expertMenu: '.js-expert-menu'
        },

        modelEvents: {
            'change': 'render',
            'change:tags': 'saveTags',
            'tempRefresh': 'render',
            'sync': 'render'
        },
        className: 'container-fluid',

        onDilution: function (e) {
            e.preventDefault();
            e.stopPropagation();
        },

        onRunActionNavDropBtnClick: function (e) {
            var targets = [];
            targets.push($(e.currentTarget).parent().find('.run-valView-action-nav-list'));
            targets.push($(e.currentTarget).parent().find('.run-valView-action-nav-list-empty'));

            _.each(targets, function (target) {
                if (target.hasClass('hidden')) {
                    target.removeClass('hidden');
                    $(e.currentTarget).addClass('active');
                } else {
                    target.addClass('hidden');
                    $(e.currentTarget).removeClass('active');
                }
            });
        },

        onRunActionNavDropCellBtnClick: function (e) {
            var targets = [];
            targets.push($(e.currentTarget).parent().parent().parent().find('.run-valView-action-nav-list'));
            targets.push($(e.currentTarget).parent().parent().parent().find('.run-valView-action-nav-list-empty'));
            _.each(targets, function (target) {
                if (target.hasClass('hidden')) {
                    target.removeClass('hidden');
                    $(e.currentTarget).addClass('active');
                } else {
                    target.addClass('hidden');
                    $(e.currentTarget).removeClass('active');
                }
            });
        },

        onHideRunActionContainer: function (e) {
            var targets = [];
            targets.push($(e.currentTarget).find('.run-valView-action-nav-list'));
            targets.push($(e.currentTarget).find('.run-valView-action-nav-list-empty'));
            _.each(targets, function (target) {
                target.addClass('hidden');
                $(e.currentTarget).find('.run-valView-action-nav-drop-btn').removeClass('active');
            });
        },

        onShowTracsView: function () {
            var service = require('services/caccounts/pcrruntracs');
            var data = {
                url: service.getCollectionUrl({wellSecId: this.model.id}),
                rowCountUrl: service.getCollectionCountUrl({wellSecId: this.model.id}),
                getJqGridColumnConfig: service.getJqGridColumnConfig()
            };

            var filterName = 'tracTableListFilters_PCRWELL_' + this.model.id;
            var storedFilters = Settings.getFromMemory(filterName);
            if (!storedFilters || !storedFilters.sidx) {
                storedFilters = {
                    runSecId: this.model.get('run').id,
                    position: this.model.get('pos')
                };
                Settings.setToMemory(filterName, storedFilters);
            }

            var jqGridView = JqGridController.showPopup({
                pager: true,
                filtersName: filterName,
                gridOptions: _.bind(function () {
                    return {
                        colModel: service.getJqGridColumn(jqGridView, {disablePosition: true}),
                        sortname: service.getJqGridSidx(this),
                        sortorder: service.getJqGridSord(this)
                    };
                }, this),
                data: data,
                selectable: false,
                service: service
            }, {
                title: _.i18n('run.tracs'),
                className: 'runShowTracsPopup'
            });
        },                   

        onShowTestLotView: function () {
            require(['wellController'], _.bind(function (Controller) {
                Controller.showPcrKitLotForValidation({model: this.model});
            }, this));
        },
        onShowTestOtherView: function () {
            require(['wellController'], _.bind(function (Controller) {
                Controller.showDetails({model: this.model, test: 'OTHER'});
            }, this));
        },
        onShowTestAssayView: function () {
            require(['wellController'], _.bind(function (Controller) {
                Controller.showDetails({model: this.model, test: 'ASSAY'});
            }, this));
        },
        onShowUntestView: function () {
            this.model.setTest(null);
        },

        onEditSampleComment: function () {
            this.model.get('smpId').fetch().done(_.bind(function () {
                this.editSampleCommentView = new EditSampleCommentView({
                    wellModel: this.model,
                    model: this.model.get('smpId')
                });
                this.editSampleCommentView.show({
                    title: _.i18n('edit.sampleComment'),
                    className: ''
                });
            }, this));
        },

        initialize: function (options) {
            this.models = options.cSelection && options.cSelection.length > 0 ? options.cSelection : this.model.get('run').get('wells').models;

            this.val1User = !!(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL1));
            this.val2User = !!(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL2));
            this.val12User = !!(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL12));
            this.valEUser = !!(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL_EXPERT));
        },

        renderMenu: function () {
            this.validationMenu = new MenuGroup({
                nameCode: 'well.popup.btn.valid',
                type: 'wellPopup',
                items: new Backbone.Collection([
                    {
                        nameCode: 'well.edit.validation.1',
                        click: _.bind(this.onBtnValidation1, this)
                    }, {
                        nameCode: 'well.edit.validation.2',
                        click: _.bind(this.onBtnValidation2, this)
                    }, {
                        nameCode: 'well.edit.validation.expert',
                        click: _.bind(this.onBtnValidationExpert, this)
                    }, {
                        nameCode: 'well.edit.validation.1.2',
                        click: _.bind(this.onBtnValidation12, this)
                    }, {
                        nameCode: 'well.edit.validation.cancel',
                        click: _.bind(this.onBtnCancelValidation, this)
                    }]),
                quickActions: new Backbone.Collection([{
                    nameCode: 'well.edit.validation.1',
                    click: _.bind(this.onBtnValidation1, this),
                    mdi: 'mdi-check',
                    mdiAttributes: {
                        style: 'color: red'
                    }
                }, {
                    nameCode: 'well.edit.validation.2',
                    click: _.bind(this.onBtnValidation2, this),
                    custom: {
                        code: 'val2',
                        className: 'validation'
                    }
                }, {
                    nameCode: 'well.edit.validation.expert',
                    click: _.bind(this.onBtnValidationExpert, this),
                    custom: {
                        code: 'valExpert',
                        className: 'validation'
                    }
                }, {
                    nameCode: 'well.edit.validation.1.2',
                    click: _.bind(this.onBtnValidation12, this),
                    custom: {
                        code: 'val12',
                        className: 'validation'
                    }
                }, {
                    nameCode: 'well.edit.validation.cancel',
                    click: _.bind(this.onBtnCancelValidation, this),
                    mdi: 'mdi-cancel',
                    mdiAttributes: {
                        style: 'color: red'
                    }
                }])
            });
            this.repeatMenu = new MenuGroup({
                nameCode: 'repeat',
                type: 'wellPopup',
                disabled: this.model.get('exported'),
                items: new Backbone.Collection([
                    {
                        nameCode: 'well.edit.repeat.PT',
                        click: _.bind(this.onBtnRepeatPcr, this)
                    }, {
                        nameCode: 'well.edit.repeat.PC',
                        click: _.bind(this.onBtnRepeatPcrControl, this)
                    }, {
                        nameCode: 'well.edit.repeat.XT',
                        click: _.bind(this.onBtnRepeatExtraction, this),
                        select: [{
                            name: 'No dil.',
                            value: 'No dil.'
                        }, {
                            name: '1:2',
                            value: '1:2'
                        }, {
                            name: '1:5',
                            value: '1:5'
                        }]
                    }, {
                        nameCode: 'well.edit.repeat.XC',
                        click: _.bind(this.onBtnRepeatExtractionControl, this),
                        select: [{
                            name: 'No dil.',
                            value: 'No dil.'
                        }, {
                            name: '1:2',
                            value: '1:2'
                        }, {
                            name: '1:5',
                            value: '1:5'
                        }]
                    }]),
                quickActions: new Backbone.Collection([{
                    nameCode: 'well.edit.repeat.XT',
                    click: _.bind(this.onBtnRepeatExtractionDefault, this),
                    custom: {
                        code: 'repeatExtraction'
                    }
                }, {
                    nameCode: 'well.edit.repeat.XC',
                    click: _.bind(this.onBtnRepeatExtractionControlDefault, this),
                    custom: {
                        code: 'repeatExtractionControl'
                    }
                }])
            });

            if (this.model.get('smpType') === 'P') {
                this.repeatMenu.get('items').add({
                    nameCode: 'well.edit.repeat.DP',
                    click: _.bind(this.onBtnRepeatPoolToDissolve, this)
                });
            }

            this.tracingMenu = new MenuGroup({
                nameCode: 'common.tracing',
                type: 'wellPopup',
                items: new Backbone.Collection([
                    {
                        nameCode: 'common.tracing',
                        click: _.bind(this.onShowTracsView, this)
                    }]),
                quickActions: new Backbone.Collection([{
                    nameCode: 'common.tracing',
                    click: _.bind(this.onShowTracsView, this),
                    mdi: 'mdi-gnome'
                }])
            });
            this.testMenu = new MenuGroup({
                nameCode: 'common.test',
                type: 'wellPopup',
                items: new Backbone.Collection([{
                    nameCode: 'well.test.lot',
                    click: _.bind(this.onShowTestLotView, this)
                }, {
                    nameCode: 'well.test.other',
                    click: _.bind(this.onShowTestOtherView, this)
                }, {
                    nameCode: 'well.test.assay',
                    click: _.bind(this.onShowTestAssayView, this)
                }, {
                    nameCode: 'well.untest',
                    click: _.bind(this.onShowUntestView, this)
                }]),
                quickActions: new Backbone.Collection([{
                    items: new Backbone.Collection([{
                        nameCode: 'well.test.lot',
                        click: _.bind(this.onShowTestLotView, this),
                        custom: {
                            code: 'testLot',
                            className: 'test'
                        },
                        selected: this.model.get('test') === 'LOT'
                    }, {
                        nameCode: 'well.test.other',
                        click: _.bind(this.onShowTestOtherView, this),
                        custom: {
                            code: 'testOther',
                            className: 'test'
                        },
                        selected: this.model.get('test') === 'OTHER'
                    }, {
                        nameCode: 'well.test.assay',
                        click: _.bind(this.onShowTestAssayView, this),
                        custom: {
                            code: 'testAssay',
                            className: 'test'
                        },
                        selected: this.model.get('test') === 'ASSAY'
                    }, {
                        nameCode: 'well.untest',
                        click: _.bind(this.onShowUntestView, this),
                        mdi: 'mdi-alpha-x',
                        className: 'untest',
                        selected: !this.model.get('test')
                    }])
                }])
            });
            this.expertMenu = new MenuGroup({
                nameCode: 'well.popup.btn.expert',
                type: 'wellPopup',
                items: new Backbone.Collection([
                    {
                        nameCode: 'well.refresh',
                        click: _.bind(this.onRefreshWell, this)
                    }, {
                        nameCode: 'well.edit.expert',
                        click: _.bind(this.onWellBtnExpertClick, this)
                    }]),
                quickActions: new Backbone.Collection([{
                    nameCode: 'well.refresh',
                    click: _.bind(this.onRefreshWell, this),
                    mdi: 'mdi-recycle',
                    mdiAttributes: {
                        style: 'color: #00b700'
                    }
                }, {
                    nameCode: 'well.edit.expert',
                    click: _.bind(this.onWellBtnExpertClick, this),
                    mdi: 'mdi-school',
                    mdiAttributes: {
                        style: 'color: red'
                    }
                }])
            });
        },

        onAssayEdit: function () {
            require(['adminAssayController'], _.bind(function (Controller) {
                var AssayService = require('services/caccounts/assay');
                AssayService.showDetails({controller: Controller, service: AssayService, model: this.model.get('refAssay')});
            }, this));
        },

        onAssayEditVersion: function () {
            require(['assayVersionController'], _.bind(function (AssayVersionController) {
                this.model.getPcrKitLots().done(_.bind(function (response) {
                    AssayVersionController.showDetails({
                        model: this.model.get('assayVersion'), options: {
                            lots: response,
                            cyclerPublicSecId: this.model.get('run').get('refCyclerPublic').secId
                        }
                    });
                }, this))
                    .always(_.bind(function () {
                        this.$el.trigger('mouseleave');
                    }, this));
            }, this));
        },

        onPreviousWell: function () {
            var index = _.indexOf(this.models, this.model);
            this._changeModel(this.models[index - 1]);
        },

        _changeModel: function (model) {
            this.options.loadDetails({model: model}).done(_.bind(function () {
                this.model = model;
                this.render();
            }, this));
        },

        onNextWell: function () {
            var index = _.indexOf(this.models, this.model);
            this._changeModel(this.models[index + 1]);
        },

        onSampleIdClick: function () {
            var assaySecId = this.model.get('refAssay') ? this.model.get('refAssay').get('secId') : null;
            var smpSecId = this.model.get('smpId').get('secId');

            if (assaySecId && smpSecId) {
                if (this.model.get('smpType') === 'U') {
                    require(['samplesController'], _.bind(function (Controller) {
                        Controller.showDetailsBySampleSecId(this.model.get('smpId').get('secId'));
                    }, this));
                }
            }
        },

        serializeData: function () {
            var templateData = {
                pos: this.model.get('pos'),
                smpType: this.model.get('smpType'),
                pooled: this.model.get('pooled'),
                assay: this.model.get('refAssay') ? this.model.get('refAssay').get('code') : '',
                assayVersion: this.model.get('assayVersion') ? this.model.get('assayVersion').get('code') : '',
                existOtherWellSameAssay: this.model.get('existOtherWellSameAssay'),
                existOtherWellOtherAssay: this.model.get('existOtherWellOtherAssay'),
                smpId: this.model.get('smpId').get('code'),
                valWst: this.model.get('valWst'),
                repeatStatus: this.model.get('repeatStatus'),
                dilution: this.model.get('dilution')
            };
            templateData.readOnly = this.model.isReadOnly();
            templateData.canChangeSmpId = this.model.canChangeSmpId();
            templateData.repeatAllowed = this.repeatAllowed = true;
            templateData.concatComment = (this.model.get('smpId').get('lisComment') ? this.model.get('smpId').get('lisComment') : '') + ' - ' + (this.model.get('smpId').get('comment') ? this.model.get('smpId').get('comment') : '');
            if (templateData.concatComment === ' - ') {
                templateData.concatComment = '';
            }

            var resultsCounter = 0,
                toVal1Counter = 0,
                toVal2Counter = 0,
                toValExpertCounter = 0,
                validatedCounter = 0,
                targetsCodeErr = false;

            this.model.getDisplay('WELL').each(_.bind(function (r) {
                if (r.get('codeErr') && r.get('codeErr') !== null && r.get('codeErr') !== '') {
                    targetsCodeErr = true;
                }

                if (!r.get('needValidation')) {
                    return;
                }
                resultsCounter++;
                if (r.get('valSt') < 3) {
                    toVal1Counter++;
                } else if (r.get('valSt') === 3.1) {
                    toValExpertCounter++;
                } else if (r.get('valSt') === 3.2) {
                    toVal2Counter++;
                } else if (r.get('valSt') >= 4) {
                    validatedCounter++;
                }
                if (r.get('valSt') === 5) {
                    templateData.repeatAllowed = this.repeatAllowed = false;
                    // Avoid repeat (when well's exported status are inconsistant)
                }
            }, this));

            this.wellToValExpert = toValExpertCounter > 0;
            this.wellToVal1 = toVal1Counter > 0;
            this.wellToVal2 = toVal2Counter > 0;
            this.wellValidated = !(this.wellToVal1 || this.wellToVal2 || this.wellToValExpert);

            templateData.colorOtherWellSameAssay = '#ff0000ff';
            templateData.colorOtherWellOtherAssay = '#09f9e5ff';

            templateData = _.extend(templateData, this.model.getErrorObject());

            templateData.pooled = !this.model.get('wellsPool').isEmpty();
            templateData.pool = !this.model.get('wellsPooled').isEmpty();

            var $tags = this.model.get('tags');
            if ($tags.length === 1 && $tags[0] !== '' || $tags.length > 1) {
                templateData.tags = $tags.join(';');
            }

            templateData.errorWarning = targetsCodeErr;
            templateData.refPrepWellPosition = this.model.get('refPrepWell') ? this.model.get('refPrepWell').get('pos') : '';
            templateData.displayResult = !this.model.getDisplay('WELL').isEmpty();
            templateData.displayResultGroup = !this.model.getDisplay('OVAR').isEmpty();
            return templateData;
        },

        isResolved: function () {
            var retVal = true;
            this.model.get('results').each(function (target) {
                if (target.get('needValidation') && target.get('codeErr')) {
                    retVal = false;
                }
            });
            if (!retVal) {
                bootbox.alert(_.i18n('well.remaining.unResolved'));
            }
            return retVal;
        },

        onBtnValidation1: function () {
            if (this.isResolved()) {
                $('.js-global-loader').show();
                this.model.validation1()
                    .always(_.bind(function () {
                        $('.js-global-loader').hide();
                    }, this));
            }
        },

        onBtnValidationExpert: function () {
            if (this.isResolved()) {
                $('.js-global-loader').show();
                this.model.validationExpert()
                    .always(_.bind(function () {
                        $('.js-global-loader').hide();
                    }, this));
            }
        },

        onBtnValidation2: function () {
            if (this.isResolved()) {
                $('.js-global-loader').show();
                this.model.validation2()
                    .always(_.bind(function () {
                        $('.js-global-loader').hide();
                    }, this));
            }
        },

        onBtnValidation12: function () {
            if (this.isResolved()) {
                $('.js-global-loader').show();
                this.model.validation12()
                    .always(_.bind(function () {
                        $('.js-global-loader').hide();
                    }, this));
            }
        },

        onBtnCancelValidation: function () {
            if (this.isResolved()) {
                $('.js-global-loader').show();
                this.model.cancelValidation()
                    .always(_.bind(function () {
                        $('.js-global-loader').hide();
                    }, this));
            }
        },

        onBtnRepeatPcr: function () {
            $('.js-global-loader').show();
            this.model.repeatPCR()
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onBtnRepeatPcrControl: function () {
            $('.js-global-loader').show();
            this.model.repeatPCRControl()
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onBtnRepeatExtraction: function (dilution) {
            this.repeatExtraction(dilution);
        },

        onBtnRepeatExtractionDefault: function () {
            this.repeatExtraction('No dil.');
        },

        repeatExtraction: function (dilution) {
            $('.js-global-loader').show();
            this.model.repeatExtraction(dilution)
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onBtnRepeatExtractionControl: function (dilution) {
            this.repeatExtractionControl(dilution);
        },

        onBtnRepeatExtractionControlDefault: function () {
            this.repeatExtractionControl('No dil.');
        },

        onBtnRepeatPoolToDissolve: function () {
            this.repeatPoolToDissolve();
        },

        repeatPoolToDissolve: function () {
            $('.js-global-loader').show();
            this.model.repeatPoolToDissolve()
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        repeatExtractionControl: function (dilution) {
            $('.js-global-loader').show();
            this.model.repeatExtractionControl(dilution)
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onWellBtnExpertClick: function () {
            $('.js-global-loader').show();
            this.model.requireExpertValidation()
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                }, this));
        },

        onRefreshWell: function () {
            var valStatus = parseInt(this.model.get('valWst'), 10);
            if ([3, 4, 5].includes(valStatus)) {
                var txt = '';
                if (valStatus === 5) {
                    txt = '<h2><b>' + _.i18n('warning.recalc.result') + '</b></h2><br/><br/>';
                }
                txt += _.i18n('recalc.result');
                bootbox.confirm(txt, _.bind(function (result) {
                    if (result) {
                        this._refreshWell();
                    }
                }, this));
            } else {
                this._refreshWell();
            }
        },
        _refreshWell: function () {
            $('.js-global-loader').show();
            this.model.refreshWell()
                .fail(_.bind(function (response) {
                    if (response.status === 409) {
                        bootbox.alert(_.i18n(response.responseText));
                    }
                }, this))
                .always(_.bind(function () {
                    $('.js-global-loader').hide();
                    // TODO: replace by a trigger change event when Backbone & relationnal not generate changeId and the current well was correctly populated (properties relationnal are not loaded, still in raw data)
                    this.render();
                }, this));
        },

        onEditWellResult: function () {
            require(['wellController'], _.bind(function (Controller) {
                Controller.showDetails({model: this.model, test: this.model.get('test')});
            }, this));
        },

        onRender: function () {
            this.renderMenu();
            this.showChildView('validationMenu', new MenuGroupView({
                model: this.validationMenu
            }));
            this.showChildView('repeatMenu', new MenuGroupView({
                model: this.repeatMenu
            }));
            this.showChildView('tracingMenu', new MenuGroupView({
                model: this.tracingMenu
            }));
            this.showChildView('testMenu', new MenuGroupView({
                model: this.testMenu
            }));
            this.showChildView('expertMenu', new MenuGroupView({
                model: this.expertMenu
            }));

            this.allowActionButtons();

            $('#right-graph').css('border-left-color', this.wellResColor);
            this.$el.find('[data-toggle="tooltip"]').tooltip();
            this.showPoolPopover(this.model);
            this.showPooledPopover(this.model);

            var region = this.getRegion('resultRegion');
            if (this.model.getDisplay('WELL').isEmpty()) {
                var missing = [];
                if (!this.model.get('run').get('refCyclerPublic')) {
                    missing.push('<li>' + _.i18n('pcrRun.refCyclerPublic.missing') + '</li>');
                }
                if (!this.model.get('run').get('refCycler')) {
                    missing.push('<li>' + _.i18n('pcrRun.refCycler.missing') + '</li>');
                }
                if (!this.model.get('refAssay')) {
                    missing.push('<li>' + _.i18n('pcrRun.refAssay.missing') + '</li>');
                }
                region.show(new Marionette.View({
                    template: _.template(missing.join('')),
                    tagName: 'ul'
                }));
            } else {
                region.show(new PcrWellResultView({
                    collection: this.model.getDisplay('WELL')
                }));
            }
            this.getRegion('graphRegion').empty();
            if (!this.model.get('curves').isEmpty()) {
                this.getRegion('graphRegion').show(new EmptyView());
                this.model.get('curves').fetch().done(_.bind(function () {
                    this.getRegion('graphRegion').show(new PcrWellGraphCurveView({
                        model: this.model
                    }));
                }, this));
            }

            var options, view;
            this.showChildView('wellDisplaySample', new WellDisplaySampleView({
                model: this.model
            }));
            if (this.model.get('refPrepWell')) {
                var PrepRunService = require('services/caccounts/prepruns');
                options = PrepRunService.parameterForLink(this.model.get('refPrepWell').get('run'), PrepRunService);
                view = new LinkNewTabView(options);
                this.getRegion('runDisplay').show(view);
            }
            App.trigger('forClipboard', this.ui.copyParameters);
            var PcrWellService = require('services/caccounts/pcrwells');
            this.getRegion('tags').show(new DynamicCreateEditCustomListToList({
                'field': 'tags',
                'model': this.model,
                service: PcrWellService
            }));

            // check if the current model is the first or the last in the selection
            this.checkForAllowedPreviousNextButtons();
        },
        allowActionButtons: function () {
            var wellRepeated = !!this.model.get('repeatStatus');
            this.readOnly = this.model.isReadOnly();
            var isQC = this.model.get('smpType') === 'PC' || this.model.get('smpType') === 'NC' || this.model.get('smpType') === 'RC' || this.model.get('smpType') === 'OC';
            var items = [];

            if (wellRepeated || isQC || this.wellValidated) {
                items = items.concat(this.repeatMenu.findItemsBy('well.edit.repeat.PT'));
                items = items.concat(this.repeatMenu.findItemsBy('well.edit.repeat.PC'));
                items = items.concat(this.repeatMenu.findItemsBy('well.edit.repeat.XT'));
                items = items.concat(this.repeatMenu.findItemsBy('well.edit.repeat.XC'));
                items = items.concat(this.repeatMenu.findItemsBy('well.edit.repeat.DP'));
            }
            if (!this.val1User && !this.val2User && !this.val12User) {
                items = items.concat(this.expertMenu.findItemsBy('well.edit.validation.expert'));
            }
            if (!(this.val1User && !wellRepeated) || this.wellValidated) {
                items = items.concat(this.expertMenu.findItemsBy('well.edit.expert'));
            }
            if ((!this.val1User && !this.val2User && !this.val12User) || this.wellValidated || this.wellToValExpert) {
                items = items.concat(this.expertMenu.findItemsBy('well.edit.expert'));
            }

            if (this.model.get('exported') || parseInt(this.model.get('valWst'), 10) !== 2 || !(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL1))) {
                items = items.concat(this.validationMenu.findItemsBy('well.edit.validation.1'));
            }

            if (this.model.get('exported') || this.model.getDisplay('WELL').all(function (r) {
                return r.get('valSt') !== 3.2;
            }) || !(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL2))) {
                items = items.concat(this.validationMenu.findItemsBy('well.edit.validation.2'));
            }

            if (this.model.get('exported') || (this.model.getDisplay('WELL').all(function (r) {
                return r.get('valSt') !== 3.1;
            }) || parseInt(this.model.get('valWst'), 10) >= 4) || !(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL_EXPERT))) {
                items = items.concat(this.validationMenu.findItemsBy('well.edit.validation.expert'));
            }

            if (this.model.get('exported') || this.model.getDisplay('WELL').all(function (r) {
                return r.get('valSt') < 2.0 || r.get('valSt') >= 4;
            }) || !(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL12))) {
                items = items.concat(this.validationMenu.findItemsBy('well.edit.validation.1.2'));
            }

            if ((!this.model.get('exported') && !(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL1)) &&
                !(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL2)) &&
                !(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL12)) &&
                !(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL_EXPERT))) || (this.model.get('exported') && !(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.EDIT_RESULT_EXPORTED)))) {
                items = items.concat(this.validationMenu.findItemsBy('well.edit.validation.cancel'));
            }

            if (!(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.REPEAT))) {
                items = items.concat(this.repeatMenu.findItemsBy('well.edit.repeat.PC'));
                items = items.concat(this.repeatMenu.findItemsBy('well.edit.repeat.PT'));
                items = items.concat(this.repeatMenu.findItemsBy('well.edit.repeat.XC'));
                items = items.concat(this.repeatMenu.findItemsBy('well.edit.repeat.XT'));
                items = items.concat(this.repeatMenu.findItemsBy('well.edit.repeat.DP'));
            }

            if (!(Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.SET_TEST)) || this.model.get('exported')) {
                items = items.concat(this.testMenu.findItemsBy('common.test'));
            }

            if (this.model.get('refPrepWell') && this.model.get('refPrepWell').get('test') && this.model.get('refPrepWell').get('test') !== 'LOT') {
                items = items.concat(this.testMenu.findItemsBy('well.test.lot'));
            }

            if (this.model.get('refPrepWell') && this.model.get('refPrepWell').get('test') && this.model.get('refPrepWell').get('test') !== 'OTHER') {
                items = items.concat(this.testMenu.findItemsBy('well.test.other'));
            }

            if (this.model.get('refPrepWell') && this.model.get('refPrepWell').get('test') && this.model.get('refPrepWell').get('test') !== 'ASSAY') {
                items = items.concat(this.testMenu.findItemsBy('well.test.assay'));
            }

            if (this.model.get('refPrepWell') && this.model.get('refPrepWell').get('test')) {
                items = items.concat(this.testMenu.findItemsBy('well.untest'));
            }

            var canRefresh = false;
            if (!this.model.get('exported')) {
                if (parseInt(this.model.get('valWst'), 10) === 0) {
                    canRefresh = true;
                }
                if (parseInt(this.model.get('valWst'), 10) === 2 && (Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL1))) {
                    canRefresh = true;
                }
                if (parseInt(this.model.get('valWst'), 10) === 3 && (Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL1))) {
                    canRefresh = true;
                }
                if (parseInt(this.model.get('valWst'), 10) === 4 && (Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL2))) {
                    canRefresh = true;
                }
                if ([3, 4].includes(parseInt(this.model.get('valWst'), 10)) && (Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL12) || Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.VAL_EXPERT))) {
                    canRefresh = true;
                }
            } else {
                if (Settings.get('currentUserModel').hasType(RolesMixin.WELL_PCR, Privileges.EDIT_RESULT_EXPORTED)) {
                    canRefresh = true;
                }
            }
            if (!canRefresh) {
                items = items.concat(this.expertMenu.findItemsBy('well.refresh'));
            }

            _.each(items, function (item) {
                item.trigger('enable', false);
            });
        },

        checkForAllowedPreviousNextButtons: function () {
            var allowPreviousBtn = true;
            var allowNextBtn = true;
            var index = 0;
            if (this.models.length === 1) {
                allowPreviousBtn = false;
                allowNextBtn = false;
            } else {
                index = _.indexOf(this.models, this.model);
                if (index === 0) {
                    allowPreviousBtn = false;
                }
                if (index === this.models.length - 1) {
                    allowNextBtn = false;
                }
            }
            if (allowPreviousBtn) {
                this.ui.btnPreviousWell.removeClass('disabled');
                this.ui.btnPreviousWell.addClass('clickable');
                this.ui.btnPreviousWell.removeAttr('disabled');
            } else {
                this.ui.btnPreviousWell.addClass('disabled');
                this.ui.btnPreviousWell.removeClass('clickable');
                this.ui.btnPreviousWell.attr('disabled', 'disabled');
            }
            if (allowNextBtn) {
                this.ui.btnNextWell.removeClass('disabled');
                this.ui.btnNextWell.addClass('clickable');
                this.ui.btnNextWell.removeAttr('disabled');
            } else {
                this.ui.btnNextWell.addClass('disabled');
                this.ui.btnNextWell.removeClass('clickable');
                this.ui.btnNextWell.attr('disabled', 'disabled');
            }
        },

        showPooledPopover: function (well) {
            if (this.ui.pooledLabel && this.ui.pooledLabel.length > 0) {
                var templateData = well.toJSON();
                var renderer = new Renderer({template: WellPooledTooltipTpl, templateData: templateData});
                renderer.render();
                var content = renderer.$el.html();
                this.ui.pooledLabel.attr('data-content', content);
                this.ui.pooledLabel.popover({
                    trigger: 'hover',
                    placement: 'bottom',
                    html: 'true',
                    container: 'body'
                }).data('bs.popover').tip().addClass('pool-popover');
            }
        },

        showPoolPopover: function (well) {
            if (this.ui.poolLabel && this.ui.poolLabel.length > 0 &&
                (well.get('smpId').get('name') || well.get('smpType')) && well.get('refAssay')) {
                var templateData = well.toJSON();
                var renderer = new Renderer({template: WellPoolTooltipTpl, templateData: templateData});
                renderer.render();
                var content = renderer.$el.html();
                var t = $(this.ui.poolLabel);
                t.attr('data-content', content);
                t.popover({
                    trigger: 'hover',
                    placement: 'bottom',
                    html: 'true',
                    container: 'body'
                }).data('bs.popover').tip().addClass('pool-popover');
            }
        },
        saveTags: function () {
            $('.js-global-loader').show();
            this.model.saveDetail().always(function () {
                $('.js-global-loader').hide();
            });
        },
        onShowProperties: function () {
            require(['propertiesController'], _.bind(function (Controller) {
                Controller.showProperties(this.model);
            }, this));
        }
    });
});

