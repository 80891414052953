/**
 * Created by GSP on 4/08/2015.
 */
define('contentShowController',[
    'module',
    'jquery',
    'underscore',
    'app',
    'settings',
    'contentShowView'
], function (
    module,
    $,
    _,
    App,
    Settings,
    View
) {
    'use strict';

    module.exports = {
        showContent: function () {
            var defer = $.Deferred(),
                view;

            if (App.regions &&
                _.isUndefined(App.getView()) ||
                (App.getView() &&
                    _.isUndefined(App.getView().getRegion)) ||
                _.isUndefined(App.getView().getRegion('content')) ||
                this.switchCaccount || App.getCurrentRoute() === '') {

                this.switchCaccount = false;
                var cAccount = Settings.get('currentCAccount');
                if (!cAccount || !cAccount.secId) {
                    App.navigate('select/caccount', {trigger: true});
                    return defer.promise();
                }
                require('services/caccounts/setting').findAll().done(_.bind(function (settings) {
                    view = new View();
                    App.showView(view);
                    require(['headerShowController', 'sidebarShowController'], function (HeaderShowController, SidebarShowController) {
                        HeaderShowController.showHeader();
                        SidebarShowController.showSidebar(settings);
                        defer.resolve();
                    });
                    App.off('switchCaccount');
                    App.on('switchCaccount', _.bind(this.onSwitchCAccount, this));
                }, this));
            } else {
                App.getView().triggerMethod('before:render');
                defer.resolve();
            }
            return defer.promise();
        },

        onSwitchCAccount: function (caccount) {
            var CAccountService = require('services/global/caccounts');
            var postCaccount = CAccountService.postCAccount(caccount.secId);

            postCaccount.done(_.bind(function () {
                Settings.clearMemory();
                Settings.set('currentCAccount', caccount);
                require('services/global/token').findBy().done(_.bind(function (model) {
                    Settings.set('currentCAccount', model.caccount);
                    Settings.set('currentUserModel', require('services/caccounts/userpids').getModel(model.userpid));
                    Settings.set('loginTo', model.loginTo);
                    // App.navigate('dashboard', {trigger: true});
                    this.switchCaccount = true;
                    App.navigate('switchCAccount', {trigger: true});
                }, this));
            }, this));
        }
    };
});

