define('wellEditSmpidView',[
    'module',
    'backbone.marionette',
    'template!wellEditSmpidTpl',
    'underscore',
    'jquery',
    'bootbox',
    'wellDisplaySampleView'
], function (
    module,
    Marionette,
    wellTpl,
    _,
    $,
    Bootbox,
    WellDisplaySampleView
) {
    'use strict';

    module.exports = Marionette.View.extend({

        ui: {
            elementRight: '.elementRight',
            inputSmpid: '.js-input-smpId'
        },

        regions: {
            wellDisplaySample: '.js-well-sample'
        },

        events: {
            'change @ui.inputSmpid': 'onInputSmpIdChange',
            'dblclick .clickable': 'onEditWellsSmpId',
            'keydown @ui.inputSmpid': 'onInputSmpIdKeyDown'
        },
        modelEvents: {
            'change': 'render'
        },

        template: wellTpl,

        className: function () {
            var canChangeSmpId = this.model.canChangeSmpId ? this.model.canChangeSmpId() : true;
            return 'ui-show-result-cell wellSelectableItem ' + (this.model.get('refPrepWell') || !canChangeSmpId ? 'not-allowed' : 'clickable');
        },

        attributes: function () {
            var backgroundColor = null;
            if (this.model.get('smpId').get('name') && this.options.settings && this.options.settings['P-LAYOUT-COL']) {
                var col = Number(this.model.get('pos').substring(1, 3)) - 1;
                backgroundColor = this.options.settings['P-LAYOUT-COL'].split(';')[col];
            }
            return {
                style: 'height:53px; position: relative;' + (backgroundColor ? 'background-color:' + backgroundColor + '; ' : '')
            };
        },

        serializeData: function () {
            var templateData = {
                well: {
                    index: this.model.get('run').get('wells').indexOf(this.model) + 1,
                    smpType: this.model.get('smpType'),
                    refPrepWell: !!this.model.get('refPrepWell'),
                    pos: this.model.get('pos'),
                    assay: this.model.get('refAssay') ? this.model.get('refAssay').get('code') : ''
                }
            };
            templateData.editMode = this.options.editMode;
            templateData.control = this.options.control;
            templateData.canChangeSmpid = this.model.canChangeSmpId ? this.model.canChangeSmpId() : true;
            templateData.test = this.model.get('test');
            templateData.wellSmpIdToDisplay = !this.options.editMode && this.model.get('smpId') && this.model.get('smpId').get('shortId') ? this.model.get('smpId').get('shortId') : this.model.get('smpId').get('name');

            return templateData;
        },

        onEditWellsSmpId: function () {
            require(['wellController'], _.bind(function (Controller) {
                Controller.showDetails({model: this.model});
            }, this));
        },

        onRender: function () {
            var canChangeSmpId = this.model.canChangeSmpId ? this.model.canChangeSmpId() : true;
            var editMode = this.options.editMode;
            if (!(editMode && !this.model.get('refPrepWell') && canChangeSmpId)) {
                var view = new WellDisplaySampleView({
                    model: this.model,
                    plateView: true,
                    shortted: !!(!this.options.editMode && this.model.get('smpId') && ((this.model.get('smpId').get && this.model.get('smpId').get('name')) || this.model.get('smpId').name))
                });
                this.getRegion('wellDisplaySample').show(view);
            }
        },

        onInputSmpIdChange: function (e) {
            if (!this.model.get('refPrepWell')) {
                if (this.$el.find(e.target).val()) {
                    this.$el.find(e.target).val(this.$el.find(e.target).val().trim());
                }
                this.model.get('smpId').set('name', this.$el.find(e.target).val());
                $(e.target).next('.js-input-smpId').focus();

                var sampleId = this.model.get('smpId').get('name');
                this.model.saveSmpid()
                    .fail(
                        _.bind(function (error) {
                            Bootbox.alert({
                                message: error.responseText,
                                title: _.i18n('sample.errorDefined') + ' : "' + sampleId + '"'
                            });
                        }, this));
            } else {
                this.$el.find(e.target).val(this.model.get('smpId').get('name'));
            }
        },

        onInputSmpIdKeyDown: function (e) {
            var code = e.keyCode || e.which;
            var tabIndex = parseInt($(e.target).attr('tabindex'), 10);
            if (code === 9) {
                var length = $('.js-input-smpId:not(.js-info-input-control)').length;
                if (tabIndex === length) {
                    e.preventDefault();
                    if (e.shiftKey) {
                        $('.js-input-smpId[tabindex=' + (length - 1) + ']').focus();
                    }
                }
                if (tabIndex === 1 && length !== 1) {
                    e.preventDefault();
                    if (!e.shiftKey) {
                        $('.js-input-smpId[tabindex=2]').focus();
                    }
                }
            }
        }
    });
});

