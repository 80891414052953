/**
 * Created by OLD on 30/10/2015.
 */
define('routingRouter',[
    'underscore',
    'jquery',
    'require',
    'module',
    'marionetteAppRouter',
    'app',
    'wellUtils',
    'cssUtils',
    'itemPagesNameMixin',
    'sidebarShowController'
], function (
    _,
    $,
    require,
    module,
    AppRouter,
    App,
    WellUtils,
    CssUtils,
    ItemPagesNameMixin,
    SidebarShowController
) {
    'use strict';

    var Controller = {
        checkContent: function () {
            var defer = $.Deferred();
            require('services/caccounts/setting').findAll().done(function (settings) {
                require([
                    'contentShowController'
                ], function (ContentShowController) {
                    ContentShowController.showContent(settings);
                    defer.resolve();
                });
            });
            return defer.promise();
        },

        showRoutingChoose: function () {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPrimarySampleTheme();
            this.checkContent().done(function () {
                require([
                    'routingController'
                ], function (RoutingController) {
                    RoutingController.menu(
                        App.getView().getRegion('content'));
                    SidebarShowController.setActiveNavItem(ItemPagesNameMixin.ROUTING);
                });
            });
        },
        showRegistration: function (secId) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPrimarySampleTheme();
            this.checkContent().done(function () {
                require([
                    'routingController'
                ], function (RoutingController) {
                    RoutingController.showRouting(
                        App.getView().getRegion('content'), 'sampleRegistration', secId);
                    SidebarShowController.setActiveNavItem(ItemPagesNameMixin.ROUTING);
                });
            });
        },
        showRouting: function (secId) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPrimarySampleTheme();
            this.checkContent().done(function () {
                require([
                    'routingController'
                ], function (RoutingController) {
                    RoutingController.showRouting(
                        App.getView().getRegion('content'), 'sampleRouting', secId);
                    SidebarShowController.setActiveNavItem(ItemPagesNameMixin.ROUTING);
                });
            });
        },
        showOrp: function (secId) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPrimarySampleTheme();
            this.checkContent().done(function () {
                require([
                    'routingController'
                ], function (RoutingController) {
                    RoutingController.showRouting(
                        App.getView().getRegion('content'), 'orp', secId);
                    SidebarShowController.setActiveNavItem(ItemPagesNameMixin.ROUTING);
                });
            });
        },

        showPreProcess: function (secId) {
            WellUtils.dismissRemainingPopovers();
            CssUtils.applyPrimarySampleTheme();
            this.checkContent().done(function () {
                require([
                    'routingController'
                ], function (RoutingController) {
                    RoutingController.showPreProcess(
                        App.getView().getRegion('content'), secId);
                    SidebarShowController.setActiveNavItem(ItemPagesNameMixin.ROUTING);
                });
            });
        }
    };

    var RoutingRouter = AppRouter.extend({
        appRoutes: {
            'routing': 'showRoutingChoose',
            'routing/registration/:secId': 'showRegistration',
            'routing/routing/:secId': 'showRouting',
            'routing/preProcessing/:secId': 'showPreProcess',
            'routing/orp/:secId': 'showOrp'
        },
        controller: Controller
    });

    module.exports = new RoutingRouter();
});
