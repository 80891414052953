define('services/caccounts/userpidNotification',[
    'entities/ns',
    'jquery',
    'settings',
    'module',
    'dynamic',
    'underscore'
], function (
    app,
    $,
    Settings,
    module,
    Dynamic,
    _
) {
    'use strict';

    module.exports = _.defaults({
        getNamespace: function () {
            return {
                model: app.UserpidNotification,
                collection: app.UserpidNotificationCollection
            };
        },

        getName: function () {
            return 'userpidnotification';
        },

        getDynamicJson: function () {
            return 'UserpidNotificationJson';
        },
        getTypeDynamicJson: function () {
            return 'CACCOUNT';
        },
        getUrl: function () {
            return 'rest/v2/userpid/notification';
        },
        getJqGridColumnConfig: function () {
            var config = Dynamic.getJqGridColumnConfig.call(this);
            config.selectable = false;
            config.exportable = false;
            config.deletable = false;
            config.attachmentable = false;
            config.archivable = false;
            config.importable = false;
            config.creatable = false;
            config.selectArchivable = false;
            config.duplicable = false;
            return config;
        },
        getJqGridColumn: function (viewDynamic) {
            var columns = Dynamic.getJqGridColumn.call(this, viewDynamic, this.getJqGridColumnConfig());
            columns.push({
                label: _.i18n('notification.context'),
                name: 'context',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 100
            }, {
                label: _.i18n('notification.message'),
                name: 'message',
                classes: 'dynamic-link',
                formatter: viewDynamic.defaultFormatter,
                search: false,
                width: 500
            }, {
                label: _.i18n('notification.action'),
                name: 'action',
                classes: 'dynamic-link',
                formatter: this.actionFormatter,
                search: false,
                width: 30,
                onClick: _.bind(function (rowId, iCol, cellContent, e, jqGridView) {
                    // get the data from jqGridView and rowId
                    var localRow = jqGridView.getGrid().jqGrid('getLocalRow', rowId);
                    var notification = Settings.get('currentUserModel').get('notifications').get(localRow.id);
                    if (notification.get('action')) {
                        notification.get('action')(jqGridView);
                    } else {
                        alert('No action defined');
                    }
                }, this)
            });
            return columns;
        },

        actionFormatter: function (cellValue, options) {
            if (options.rowId === Settings.get('currentUserModel').get('secId')) {
                return '';
            }
            return '<span class="mdi mdi-cog-play-outline js-icon" ' +
                'title="' + _.i18n('notification.action.execute') + '"></span>';
        }
    }, Dynamic);

});

