define('runWellSelectionView',[
    'module',
    'backbone',
    'underscore',
    'jquery',
    'dialogFormView',
    'template!runWellSelectionView',
    'commonWellSelectionView',
    'commonCancelActionView'
], function (
    module,
    Backbone,
    _,
    $,
    DialogFormView,
    Tpl,
    CommonWellSelectionView,
    CommonCancelActionView
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: Tpl,

        ui: {
            input: '.js-info-input'
        },

        events: {
            'focus @ui.input': 'onFocus',
            'change @ui.input': 'onInputChange'
        },

        regions: {
            wellSelectionRegion: '.js-well-selection-region',
            cancelActionRegion: '.js-cancel-action-region'
        },

        fieldsToValidate: function () {
            return [
                {name: 'wellSelection', type: 'required', subView: this.wellSelectionView}
            ];
        },

        modelEvents: {
            'change:wellSelection': 'onValidateChange'
        },

        initialize: function () {
            this.model = new Backbone.Model();
            this.model.set('wellSelection', null);
        },

        onRender: function () {
            this.wellSelectionView = new CommonWellSelectionView({
                direction: this.options.direction,
                start: 'A01',
                to: this.options.model.get('plateSize'),
                model: this.model,
                fieldName: 'wellSelection'
            });
            this.getRegion('wellSelectionRegion').show(this.wellSelectionView);

            this.commonCancelActionView = new CommonCancelActionView();
            this.listenTo(this.commonCancelActionView, 'action:click', _.bind(this.onActionClick, this));
            this.listenTo(this.commonCancelActionView, 'cancel:click', _.bind(this.onCancel, this));
            this.getRegion('cancelActionRegion').show(this.commonCancelActionView);
            this.onValidateChange();
        },

        onValidateChange: function () {
            this.commonCancelActionView.enableButtons(this.validate(this.fieldsToValidate()));
        },

        onActionClick: function () {
            var loading = $('.js-global-loader');
            loading.show();
            this.options.model.removeTest(this.model)
                .done(_.bind(function () {
                    this.hide();
                }, this))
                .always(_.bind(function () {
                    loading.hide();
                }, this));
        }
    });
});
