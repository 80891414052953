/**
 * Created by RKL on 26/08/2015.
 */
define('dynamicList',[
    'module',
    'underscore',
    'jquery',
    'dialogFormView',
    'jqGridController',
    'template!dynamicList',
    'customBootboxMessage',
    'bootbox',
    'settings',
    'fileExplorerController',
    'errorController',
    'flash',
    'app'
], function (
    module,
    _,
    $,
    DialogFormView,
    JqGridController,
    viewTpl,
    CustomBootboxMessage,
    Bootbox,
    Settings,
    FileExplorerController,
    ErrorController,
    Flash,
    App
) {
    'use strict';

    module.exports = DialogFormView.extend({
        template: viewTpl,
        filtersName: function () {
            return this.options.service.getJqGridFilterName();
        },
        triggers: {},

        ui: function () {
            this.uniqueId = _.extend({
                'menuAddListButton': _.uniqueId('menuAddListButton_'),
                'menuAddList': _.uniqueId('menuAddList_')
            }, this.uniqueId);
            return {
                exportDynamic: '.export-link-action',
                gridUpperMenu: '.js-grid-upper-menu',
                tableHeader: 'table.ui-jqgrid-htable',
                menuButton: '#' + this.uniqueId.menuAddListButton,
                menuAddList: '#' + this.uniqueId.menuAddList,
                menuEntry: '.menuEntry',
                showViewNewDynamic: '.js-showViewNew',
                exportByList: '.js-export-by-list',
                deleteByList: '.js-delete-by-list',
                archiveByList: '.js-archive-by-list',
                unArchiveByList: '.js-unArchive-by-list',
                importFile: '.js-import'
            };
        },

        events: {
            'mouseenter @ui.tableHeader': 'showGridUpperMenu',
            'mouseleave @ui.tableHeader': 'HideGridUpperMenu',
            'mouseenter @ui.gridUpperMenu': 'showGridUpperMenu',
            'mouseleave @ui.gridUpperMenu': 'HideGridUpperMenu',
            'click @ui.exportDynamic': 'onExportElement',
            'click @ui.showViewNewDynamic': 'showViewNewDynamic',
            'click @ui.menuButton': 'showMenu',
            'mouseleave @ui.menuAddList': 'hideMenu',
            'click @ui.menuEntry': 'hideMenu',
            'change .js-import': 'onImport',
            'click @ui.exportByList': 'onExportByList',
            'click @ui.deleteByList': 'onDeleteByList',
            'click @ui.archiveByList': 'onArchiveByList',
            'click @ui.unArchiveByList': 'onUnArchiveByList'
        },

        className: function () {
            return 'dynamic jq-grid-table-page ' + this.options.service.getName() + '-listView';
        },

        regions: {
            'custom-left': '.js-custom-left',
            'custom-right': '.js-custom-right',
            'gridRegion': '.js-grid-region'
        },

        showViewNewDynamic: function () {
            this.trigger('dynamic:show', {
                model: this.options.service.getModel(this.options.data.defaultModel),
                useCreateEditView: this.options.data.callBackCreateEditView,
                callBackOnClose: _.bind(function () {
                    this.getChildView('gridRegion').trigger('reloadGrid');
                }, this),
                callBackItemClick: this.options.data.callBackItemClick
            });
        },

        showMenu: function () {
            if (this.ui.menuAddList.hasClass('hiddedMenuAddList')) {
                this.ui.menuAddList.addClass('visibleMenuAddList');
                this.ui.menuAddList.removeClass('hiddedMenuAddList');
            } else {
                this.hideMenu(this.ui.menuAddList);
            }
        },

        hideMenu: function () {
            this.ui.menuAddList.addClass('hiddedMenuAddList');
            this.ui.menuAddList.removeClass('visibleMenuAddList');
        },

        initialize: function () {
            var storedFilters = Settings.getFromMemory(this.filtersName());
            if (!storedFilters) {
                storedFilters = {filters: '{"groupOp":"AND","rules":[]}'};
            }
            var displayArchived = this.options.displayArchived || false;
            // get the filter value for "archived" from this item in rules "{"field":"archived","op":"eq","data":"false"}"
            var filters = JSON.parse(storedFilters.filters);
            if (!filters.rules) {
                filters.rules = [];
            }
            // remove item filter for "archived" and add new one item "archived"
            filters.rules = _.reject(filters.rules, function (rule) {
                return rule.field === 'archived';
            });
            if (displayArchived !== null) {
                filters.rules.push({field: 'archived', op: 'eq', data: displayArchived + ''});
                storedFilters.archived = displayArchived;
            }
            storedFilters.filters = JSON.stringify(filters);
            storedFilters.archived = displayArchived;
            Settings.setToMemory(this.filtersName(), storedFilters);
        },

        serializeData: function () {
            var templateData = {
                entityName: this.options.service.getName(),
                data: this.options.data,
                accept: '.json,.csv'
            };
            templateData.config = this.options.service.getJqGridColumnConfig(this.options.data.options);
            templateData.config.creatable = templateData.config.creatable && this.options.service.canAddOrEdit();
            templateData.config.deletable = templateData.config.deletable && this.options.service.canAddOrEdit();
            templateData.config.archivable = templateData.config.archivable && this.options.service.isArchivable();
            templateData.uniqueId = this.uniqueId;
            templateData.className = this.options.region && !this.options.data.removeClassName ? 'well' : 'dynamic';
            return templateData;
        },

        onRender: function () {
            var data;
            if (this.options.service && App.services.includes(this.options.service)) {
                data = {
                    datatype: 'local',
                    data: this.options.service.findAll,
                    context: this.options.service
                };
            } else if (_.isArray(this.options.data)) {
                data = {
                    datatype: 'local',
                    data: this.options.data,
                    context: this.options.service
                };
            } else if (_.isFunction(this.options.data)) {
                data = {
                    datatype: 'local',
                    data: this.options.data,
                    context: this.options.service
                };
            } else {
                data = {
                    url: this.options.service.getCollectionUrl(this.options.data.params),
                    rowCountUrl: this.options.service.getCollectionCountUrl(this.options.data.params),
                    getJqGridColumnConfig: this.options.service.getJqGridColumnConfig()
                };
            }
            var options = this.options && this.options.data && this.options.data.options || {};

            this.jqGridView = JqGridController.show(_.extend({
                pager: true,
                filtersName: this.filtersName(),
                gridOptions: _.bind(this.gridOptions, this),
                data: data,
                selectable: true,
                paginationObject: this.paginationObject,
                service: this.options.service
            }, options), this.getRegion('gridRegion'));
            this.listenTo(this.jqGridView, 'click', _.bind(this.onClick, this));

            this.hideMenu();
            if (this.options.service.getJqGridColumnConfig(options).customLeft) {
                // Obtenir le constructeur (prototype) de l'instance existante
                var ConstructLeft = Object.getPrototypeOf(this.options.service.getJqGridColumnConfig(options).customLeft).constructor;
                // Créer une nouvelle instance de la vue avec les options combinées
                var viewLeft = new ConstructLeft(Object.assign({}, this.options.service.getJqGridColumnConfig(options).customLeft.options, data.options, {jqGrid: this.jqGridView}));
                this.getRegion('custom-left').show(viewLeft);
            }
            if (this.options.service.getJqGridColumnConfig(options).customRight) {
                // Obtenir le constructeur (prototype) de l'instance existante
                var ConstructRight = Object.getPrototypeOf(this.options.service.getJqGridColumnConfig(options).customRight).constructor;
                // Créer une nouvelle instance de la vue avec les options combinées
                var viewRight = new ConstructRight(Object.assign({}, this.options.service.getJqGridColumnConfig(options).customRight.options, data.options, {jqGrid: this.jqGridView}));
                this.getRegion('custom-right').show(viewRight);
            }
        },

        onClick: function (obj) {
            var $target = $(obj.cellContent);
            if ($target.hasClass('cell-delete')) {
                this.onDeleteDynamic(obj);
                return;
            }
            if ($target.hasClass('cell-archive')) {
                this.onArchive(obj);
                return;
            }
            if ($target.hasClass('cell-unArchive')) {
                this.onUnArchive(obj);
                return;
            }
            if ($target.hasClass('cell-attachment')) {
                this.onAttachment(obj);
                return;
            }
            if ($target.hasClass('cell-alert')) {
                this.onAlert(obj);
                return;
            }
            if ($target.hasClass('cell-duplicate')) {
                this.onDuplicate(obj);
                return;
            }
            if ($target.hasClass('cell-export')) {
                this.onExport(obj);
                return;
            }

            var data = {};
            data[this.options.service.getNamespace().model.prototype.idAttribute] = obj.rowId;
            var model = this.options.service.getModel(data);
            if (this.options.data && this.options.data.callBackItemClick) {
                this.options.data.callBackItemClick(model, obj ? obj.gridView.getGrid() : null);
            } else {
                this.options.service.showDetails({
                    model: model,
                    service: this.options.service,
                    callBackOnClose: _.bind(function () {
                        obj.gridView.trigger('reloadGrid');
                    }, this)
                });
            }
        },

        onDeleteDynamic: function (obj) {
            var target = $(obj.cellContent);
            var codeName = target.attr('data-row-codeName');
            var callback = _.bind(function (result) {
                if (result) {
                    this.options.service.deleteEntity(obj.rowId)
                        .done(_.bind(function () {
                            obj.gridView.trigger('reloadGrid');
                            Flash.success(_.i18n('delete.success'));
                        }, this))
                        .fail(function () {
                            Flash.error(_.i18n('delete.error'));
                        });
                } else {
                    Flash.error(_.i18n('delete.error'));
                }
            }, this);
            var confirmParams = {
                message: _.i18n('confirmDelete') + ' ' + this.options.service.getName() + ' \"' + '' + codeName + '\" ?',
                notaBene: _.i18n('confirmDelete.notaBene'),
                type: 'warning'
            };
            CustomBootboxMessage.customConfirm(confirmParams, callback);
        },

        onAttachment: function (obj) {
            var model = this.options.service.getModel({secId: parseInt(obj.rowId, 10)});
            var global = $('.js-global-loader');
            global.show();
            model.fetch()
                .done(_.bind(function () {
                    var AttachmentService = require('services/caccounts/attachment');
                    AttachmentService.openAttachment(this.options.service, model);
                }, this))
                .always(function () {
                    global.hide();
                });
        },

        onAlert: function (obj) {
            var model = this.options.service.getModel({secId: parseInt(obj.rowId, 10)});
            model.fetch().done(_.bind(function () {
                ErrorController.showE(model, model.get('entityError'));
            }, this));
        },

        onArchive: function (obj) {
            var target = $(obj.cellContent);
            var codeName = target.attr('data-row-codeName');
            var callback = _.bind(function (result) {
                if (result) {
                    this.options.service.archiveEntity(obj.rowId)
                        .done(_.bind(function () {
                            obj.gridView.trigger('reloadGrid');
                            Flash.success(_.i18n('archive.success'));
                        }, this))
                        .fail(function () {
                            Flash.error(_.i18n('archive.error'));
                        });
                } else {
                    Flash.error(_.i18n('archive.error'));
                }
            }, this);
            var confirmParams = {
                message: _.i18n('confirmArchive') + ' ' + this.options.service.getName() + ' \"' + '' + codeName + '\" ?',
                notaBene: _.i18n('confirmArchive.notaBene'),
                type: 'warning'
            };
            CustomBootboxMessage.customConfirm(confirmParams, callback);
        },

        onDuplicate: function (obj) {
            var target = $(obj.cellContent);
            var codeName = target.attr('data-row-codeName');
            var callback = _.bind(function (result) {
                if (result) {
                    this.options.service.duplicateEntity(obj.rowId)
                        .done(_.bind(function (importResponse) {
                            if (importResponse && importResponse.error) {
                                Flash.error(importResponse.error);
                                return;
                            }
                            if (importResponse && importResponse.imported) {
                                var model = this.options.service.getModel(importResponse.imported);
                                this.options.service.showDetails({model: model, service: this.options.service});
                                Flash.success(_.i18n('duplicate.success'));
                            }
                        }, this))
                        .fail(function () {
                            Flash.error(_.i18n('duplicate.error'));
                        });
                } else {
                    Flash.error(_.i18n('duplicate.error'));
                }
            }, this);
            var confirmParams = {
                message: _.i18n('confirmDuplicate') + ' ' + this.options.service.getName() + ' \"' + '' + codeName + '\" ?',
                notaBene: _.i18n('confirmDuplicate.notaBene'),
                type: 'warning'
            };
            CustomBootboxMessage.customConfirm(confirmParams, callback);
        },

        onUnArchive: function (obj) {
            var target = $(obj.cellContent);
            var codeName = target.attr('data-row-codeName');
            var callback = _.bind(function (result) {
                if (result) {
                    this.options.service.unarchiveEntity(obj.rowId)
                        .done(_.bind(function () {
                            obj.gridView.trigger('reloadGrid');
                            Flash.success(_.i18n('unarchive.success'));
                        }, this))
                        .fail(function () {
                            Flash.error(_.i18n('unarchive.error'));
                        });
                } else {
                    Flash.error(_.i18n('unarchive.error'));
                }
            }, this);
            var confirmParams = {
                message: _.i18n('confirmUnarchive') + ' ' + this.options.service.getName() + ' \"' + '' + codeName + '\" ?',
                notaBene: _.i18n('confirmUnarchive.notaBene'),
                type: 'warning'
            };
            CustomBootboxMessage.customConfirm(confirmParams, callback);
        },

        onExport: function (obj) {
            this.options.service.exportEntity(obj.rowId)
                .done(_.bind(function (data) {
                    FileExplorerController.downloadFile(data.fileName, data.data);
                }, this))
                .fail(function () {
                    Flash.error(_.i18n('unarchive.error'));
                });
        },

        showGridUpperMenu: function () {
            this.ui.gridUpperMenu.removeClass('hidden');
        },

        HideGridUpperMenu: function () {
            this.ui.gridUpperMenu.addClass('hidden');
        },

        gridOptions: function (data) {
            var options = this.options && this.options.data && this.options.data.options || {};

            options = {
                colModel: data.getJqGridColumn ? data.getJqGridColumn(this.jqGridView, options) : this.options.service.getJqGridColumn(this.jqGridView, options),
                sortname: this.options.service.getJqGridSidx(this),
                sortorder: this.options.service.getJqGridSord(this)
            };

            return options;
        },

        onImport: function (e) {
            if (!e || !e.target || !e.target.files || !e.target.files[0]) {
                return;
            }
            var file = e.target.files[0];
            Bootbox.dialog({
                title: _.i18n('import.ask.title'),
                message: _.i18n('import.ask.message'),
                size: 'large',
                backdrop: true,
                buttons: {
                    cancel: {
                        label: _.i18n('cancel'),
                        className: 'btn-danger'
                    },
                    CREATE: {
                        label: _.i18n('import.ask.create'),
                        className: 'btn-info',
                        callback: _.bind(function () {
                            this._onImport(file, 'CREATE');
                        }, this)
                    },
                    UPDATE: {
                        label: _.i18n('import.ask.update'),
                        className: 'btn-info',
                        callback: _.bind(function () {
                            this._onImport(file, 'UPDATE');
                        }, this)
                    }
                }
            });
        },

        _onImport: function (file, type) {
            this.options.service.importEntity(file, type)
                .done(_.bind(function () {
                    this.jqGridView.trigger('reloadGrid');
                    this.ui.importFile.val(null);
                }, this));
        },

        onLoadComplete: function () {
            var events = this.options.service.getJqGridEvents();
            _.each(events, _.bind(function (event) {
                this.$el.find(event.filter).on('click', function (e) {
                    e.stopPropagation();
                    event.func(e.target.attributes['data-secId'].value);
                });
            }, this));
        },
        paginationObject: function () {
            return {
                first: 0,
                rowCount: 0,
                rows: Settings.get('perPage'),
                currentPage: 1
            };
        },

        _askType: function (callBack) {
            Bootbox.dialog({
                title: _.i18n('export.multi.title'),
                message: _.i18n('export.multi.message'),
                size: 'large',
                backdrop: true,
                buttons: {
                    cancel: {
                        label: _.i18n('cancel'),
                        className: 'btn-danger'
                    },
                    CSV: {
                        label: _.i18n('export.CSV'),
                        className: 'btn-info',
                        callback: function () {
                            callBack('csv', true);
                        }
                    },
                    JSON: {
                        label: _.i18n('export.JSON'),
                        className: 'btn-info',
                        callback: function () {
                            callBack('json', false);
                        }
                    },
                    JSON_BUNDLED: {
                        label: _.i18n('export.JSON.bundled'),
                        className: 'btn-info',
                        callback: function () {
                            callBack('json', true);
                        }
                    }
                }
            });
        },

        onExportByList: function () {
            var rowsList = this.jqGridView.getCheckedRowsList();
            if (_.isEmpty(rowsList)) {
                return;
            }
            var cb = _.bind(function (type, bundled) {
                if (bundled) {
                    this.options.service.exportEntities(_.pluck(rowsList, 'secId'), type)
                        .done(_.bind(function (data) {
                            FileExplorerController.downloadFile(data.fileName, data.data);
                        }, this));
                } else {
                    _.each(rowsList, _.bind(function (row) {
                        this.options.service.exportEntity(row.secId, type)
                            .done(_.bind(function (data) {
                                FileExplorerController.downloadFile(data.fileName, data.data);
                            }, this));
                    }, this));
                }
            }, this);
            this._askType(cb);
        },

        onDeleteByList: function () {
            var rowsList = this.jqGridView.getCheckedRowsList();
            if (_.isEmpty(rowsList)) {
                return;
            }
            this.options.service.deleteEntities(_.pluck(rowsList, 'secId'))
                .done(_.bind(function (data) {
                    _.each(data.lines, function (line) {
                        if (line.error) {
                            Flash.error(_.i18n('delete.error'), line.code + ' : ' + line.errorMessage);
                        } else {
                            Flash.success(_.i18n('delete.success'), line.code);
                        }
                    });
                    this.jqGridView.trigger('reloadGrid');
                }, this));
        },

        onArchiveByList: function () {
            var rowsList = this.jqGridView.getCheckedRowsList();
            if (_.isEmpty(rowsList)) {
                return;
            }
            this.options.service.archiveEntities(_.pluck(rowsList, 'secId'))
                .done(_.bind(function (actionResponse) {
                    _.each(actionResponse.failed, function (line) {
                        if (line.error) {
                            Flash.error(_.i18n('archive.error'), line.code);
                        }
                    });
                    _.each(actionResponse.success, function (line) {
                        Flash.success(_.i18n('archive.success'), line.code);
                    });
                    this.jqGridView.trigger('reloadGrid');
                }, this));
        },

        onUnArchiveByList: function () {
            var rowsList = this.jqGridView.getCheckedRowsList();
            if (_.isEmpty(rowsList)) {
                return;
            }
            this.options.service.unarchiveEntities(_.pluck(rowsList, 'secId'))
                .done(_.bind(function (actionResponse) {
                    _.each(actionResponse.failed, function (line) {
                        if (line.error) {
                            Flash.error(_.i18n('unarchive.error'), line.code);
                        }
                    });
                    _.each(actionResponse.success, function (line) {
                        Flash.success(_.i18n('unarchive.success'), line.code);
                    });
                    this.jqGridView.trigger('reloadGrid');
                }, this));
        }
    });
});
